import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectSmallProps {
  onSelect: (value: string) => void; // Define a callback prop
}

export default function PrivateEveryoneSelect({ onSelect }: SelectSmallProps) {
  const [Everyone, setAge] = React.useState('EVERYONE');

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setAge(event.target.value);
    onSelect(selectedValue);
  };

  return (
    <FormControl sx={{ minWidth: 140 }} size="small">
      {/* <InputLabel id="demo-select-small-label">Select</InputLabel> */}
      <Select
        labelId="demo-select-small-label"
        id="demo-select-small"
        value={Everyone}
        label="Select"
        onChange={handleChange}>
        <MenuItem value="EVERYONE">Everyone</MenuItem>
        <MenuItem value="ONLY_ME">Private</MenuItem>
      </Select>
    </FormControl>
  );
}