import React, { useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';

import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from '../.././pages/Home/Tabs/LikeOptionMenu';
import UserDropdown from '../.././pages/StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from '../.././pages/Home/Tabs/Filters';
import PrivateEveryoneBlueSelect from '../.././pages/Home/Tabs/PrivateEveryoneBlueSelect';
import Tooltip from '@mui/material/Tooltip';
import './FeedBackTabPrivew.css';
import { mdiDotsVertical } from '@mdi/js';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFeedback } from '../../store/slices/MeetingSlice';
import FeedbackBar from './FeedbackBar';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Icon className="accordion-icon" path={mdiPlayBox} size={1} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function FeedBackTabPrivew() {
    const location = useLocation();
    const dispatch = useDispatch();
    // const [expanded, setExpanded] = React.useState<string | true>('panel1');
    // const [inEditMode, setInEditMode] = useState<boolean>(false);
    // const { filterBy } = useSelector((s: any) => s.meeting);

    const topicId = location?.state?.topicId;
    const sectionId = location?.state?.sectionId;

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchFeedback({ agendaId: agenda?.id, topicId: topicId, sectionId: sectionId }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (agenda?.id && topicId) {
            fetchData();
        }
        // Call the async function
    }, [agenda?.id, topicId]); // Empty dependency array means this effect runs only once


    //USE MEMO
    const { filteredSections, topicData, sectionData } = useMemo(() => {
        const sectionFound: any = agenda?.section?.find((sec: any) => sec.section_id == sectionId);
        const topicFound: any = sectionFound?.topics?.find((tp: any) => tp?.topic_id == topicId);
        const filteredSections: any = topicFound ? [...((topicFound?.feedback as any[]) ?? [])] : [];
        return { filteredSections: filteredSections, topicData: topicFound, sectionData: sectionFound };
    }, [agenda?.section, topicId])

    return (
        <div>
          

            {filteredSections.length === 0 ? (
               <div className="addFeedback">
                    <div><img src="../../../../images/addFeedback.svg" alt=""/></div>

                    <p className="f-16"><center>Be the first to add feedback and engage with your colleagues.</center></p>
                </div>


            ) : (
                <>
                    <div className="row align-items-center my-3">
                        <div className="col-auto">
                           
                        </div>
                    </div>
                    {
                        filteredSections.map((fd: any) => {
                            let userDetails = meeting?.participants?.find((pt: any) => pt.user_id == fd.created_by_user_id);
                            return (
                                <FeedbackBar topic={topicData} section={sectionData} userDetails={userDetails} fd={fd} />
                            );
                        })
                    }
                </>
            )}
        </div>
    );
}
