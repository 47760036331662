import { mdiCheckCircleOutline, mdiCircleSmall, mdiDotsHorizontal, mdiPlus } from '@mdi/js'
import Icon from '@mdi/react'
import { Button, Modal, TextField, Typography,Fade } from '@mui/material'
import React, { useEffect, useState } from 'react'
import OptionsMenu from "./OptionsMenu";
import ModalDialog, { ModalDialogProps } from '@mui/joy/ModalDialog';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import UserDropdown from './UserDropdown';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { CompleteSectionAndAllTopics, Section, updateAgenda, updateSection, updateSectionTime } from '../../../store/slices/MeetingSlice';
import { useDispatch, useSelector } from 'react-redux';

const SectionSummary = (props: any) => {
    const dispatch = useDispatch();
    // PROPS
    const { section, mdiClockTimeFourOutline, isTimeEditable, isShowCheckBox } = props;

    // USE STATE
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [isEditingTime, setIsEditingTime] = useState<boolean>(false);
    const [newSectionName, setNewSectionName] = useState<string>(section.name);
    const [layout, setLayout] = useState<ModalDialogProps['layout'] | undefined>(
        undefined
    );
    const [layoutModal, setLayoutModal] = useState<ModalDialogProps['layout'] | undefined>(
        undefined
    );
    const [summaryInputErr, setSummaryInputErr] = React.useState<boolean>(false);

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const user = useSelector((s: any) => s.meeting.user);
    const { meeting } = useSelector((s: any) => (s.meeting));


    // USE EFFECT
    useEffect(() => {
        setNewSectionName(section.name)
    }, [section, inEditMode]);

    useEffect(() => {
        setLayout(inEditMode ? 'center' : undefined)
    }, [inEditMode])


    // FUNCTION
    const handleUpdateSectionClick = async () => {
        console.log(newSectionName);
        if (!newSectionName || !(newSectionName.trim())) {
            setSummaryInputErr(true);
            setTimeout(() => {
                setSummaryInputErr(false);
            }, 3000)
            return;
        }
        try {
            await dispatch(updateSection({ agendaId: agenda.id, sectionId: section.section_id, userId: user.id, meetingId: meeting.id, value: newSectionName }));
            setInEditMode(false)
        } catch (err) {
            console.log(err, 'error')
            setInEditMode(false)
        }
    }

    const handleDurationUpdate = async (e: any) => {
        await dispatch(updateSectionTime({
            agendaId: agenda?.id,
            sectionId: section?.section_id,
            meetingId: meeting?.id,
            userId: user,
            value: e?.$m ?? 0
        }));
        setIsEditingTime(false)
    }
    const handleTimeClick = (event: React.MouseEvent<HTMLElement>) => {
        if (section?.status != 'Completed') {
            event.stopPropagation();
            setIsEditingTime(true)
        }
    };

    const handleCheckGreenClick = async (event: React.MouseEvent<HTMLElement>) => {
        console.log(event);
        await dispatch(CompleteSectionAndAllTopics({ agendaId: agenda.id, sectionId: section.section_id, userId: user.id, meetingId: meeting.id,}));
        setLayoutModal(undefined);
    };


    return (
        <Fade in={true} timeout={1500}>
        <div className="row align-items-center" >
            <div className="col d-inline-flex align-items-center">
                <div className="d-inline-flex align-items-center">
                    <span className="drag-move-icon">
                        <Icon
                            path={mdiDotsHorizontal}
                            size={1}
                            className="c-light-gray icon-first"
                        />
                        <Icon
                            path={mdiDotsHorizontal}
                            size={1}
                            className="c-light-gray icon-second"
                        />
                    </span>
                    <UserDropdown showName={false} type={'section'} section={section} />
                    <Typography className="ml-2 f-14 text-medium text-ellipsis-single-line">
                        {section.name}
                    </Typography>

                    <Modal open={!!layout} onClose={() => { setInEditMode(false); setLayout(undefined); }}>
                        <ModalDialog
                            aria-labelledby="layout-modal-title"
                            aria-describedby="layout-modal-description"
                            layout={layout}
                        >
                            <Typography id="layout-modal-title" component="h2">
                                Edit Section
                            </Typography>
                            <div className="row align-items-center">
                                <div className="col-12 mt-2">
                                    <div>
                                        <TextField
                                            error={summaryInputErr}
                                            id="outlined-size-small"
                                            size="small"
                                            required={true}
                                            value={newSectionName}
                                            onChange={(e) => setNewSectionName(e.target.value)}
                                            fullWidth
                                            className="input-bg-white"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleUpdateSectionClick();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 text-right my-2">
                                    <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button>
                                    <Button variant="contained" size="small" onClick={handleUpdateSectionClick}>Save</Button>
                                </div>
                            </div>
                        </ModalDialog>
                    </Modal>
                    {/* <Icon
                        path={mdiCircleSmall}
                        size={1}
                        className="c-light-gray"
                    /> */}
                    {section?.showNewTag && <div className="badge badge-green ml-2">New</div>}
                </div>
            </div>
            <div className="col-auto d-inline-flex align-items-center" onClick={(e) => { e.stopPropagation() }}>
                {
                    section?.duration && !isEditingTime ?
                        <div className="badge badge-blue pl-1 mr-2" onClick={(e) => handleTimeClick(e)}>
                            <Icon
                                path={mdiClockTimeFourOutline}
                                size={0.9}
                                className="mr-1"
                            />
                            {section?.duration} min.
                        </div>
                        :
                        <>
                            {
                                !isEditingTime ? <div className="badge badge-blue px-2 mr-2" onClick={(e) => handleTimeClick(e)} >
                                    <Icon path={mdiClockTimeFourOutline} size={0.9} className="mr-1" />
                                    <Icon path={mdiPlus} size={1} className="add-time-icon" />
                                </div> :
                                    <div className="badge badge-blue px-2 mr-2 time-picker">
                                        <LocalizationProvider dateAdapter={AdapterDayjs} >
                                            <TimePicker disabled={section?.status == 'Completed'} autoFocus={true} onChange={(e) => { handleDurationUpdate(e); }} views={['minutes']} format="mm" ampm={false} />
                                        </LocalizationProvider> </div>}

                        </>
                }

                {
                    isShowCheckBox && <div className={section?.status == 'Completed' ? 'check-mark-main disabled' : 'check-mark-main'} onClick={(e) => { setLayoutModal('center') }}><Icon path={mdiCheckCircleOutline} size={1} className="check-green" /></div>
                }

                <Modal open={!!layoutModal} >
                    <ModalDialog
                        aria-labelledby="layout-modal-title"
                        aria-describedby="layout-modal-description"
                        layout={layoutModal}>

                        <div className="row align-items-center">
                            <div className="col-12 mt-2">
                                <p className="f-14">Are you sure you want to complete this section?</p>
                            </div>
                            <div className="col-12 text-right my-2">
                                <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setLayoutModal(undefined)}>Cancel</Button>
                                <Button variant="contained" size="small" color='primary' onClick={(e) => handleCheckGreenClick(e)}>yes</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </Modal>




                {
                    !inEditMode &&
                    <OptionsMenu
                        setInEditMode={setInEditMode}
                        section={section}
                        type={'SECTION'} />
                }

            </div>

        </div ></Fade>
    )
}

export default SectionSummary