import { mdiPlus } from '@mdi/js';
import { Button, TextField} from '@mui/material';
import Icon from "@mdi/react";
import React, { useState } from 'react'
import { Section, addTopic } from '../../../store/slices/MeetingSlice';
import { useDispatch, useSelector } from 'react-redux';


const NewTopicForm = (props: any) => {
    const dispatch = useDispatch()
    // PROPS
    const { section } = props;

    // USE STATE 
    const [topic, setTopic] = useState<string>('');
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [topicInputErr, setTopicInputErr] = React.useState<boolean>(false);

    // STORE
    const {agenda, id} = useSelector((s: any) => s.meeting.meeting);
    const user = useSelector((s: any) => s.meeting.user);

    // FUNCTION
    const createNewTopic = () => {
        return {
            topic_id: topic,
            name: topic,
            speaker:  user.id,
            status: "Created",
            display_order: "1",
            display_order_last_updated_by_user_id: user.id ?? '1',
            duration: 0,
            duration_updated_by_user_id: user.id ?? "1",
            date_created: new Date().toISOString()
        }
    }

    const handleAddTopicClick = async () => {
        if (!inProgress)
            setInProgress(true)
        if (topic?.trim()) {
            const newTopic: any = createNewTopic();
            const sections = agenda.section.map((s: Section) => {
                if (s.section_id == section.section_id) return { ...s, topics: [...s.topics, newTopic] }
                else return s;
            })

            const updatedAgenda = { ...agenda, section: sections };
            try {
                // call add new topic here
                await dispatch(addTopic(
                    {
                        agendaId: agenda.id,
                        sectionId: section.section_id,
                        meetingId: id,
                        topic: newTopic
                    }
                ))
                //    await dispatch(updateAgenda(updatedAgenda));
            } catch (err) {
                console.log(err, 'error')
            }
            setTopic('');
            setInProgress(false)
        } else {
            setTopicInputErr(true);
            setTimeout(() => {
                setTopicInputErr(false);
            }, 3000)
            setInProgress(false)
        }
    }

    return (
        <div className="row align-items-center accrdion-bottom-form">
            <div className="col">
                <div>
                    <TextField
                        error={topicInputErr}
                        label="Add Discussion Topic"
                        id="outlined-size-small"
                        size="small"
                        fullWidth
                        value={topic}
                        disabled={section?.status == 'Completed'}
                        onChange={(e) => setTopic(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                                handleAddTopicClick();
                            }
                        }}
                    />
                </div>
            </div>
            <div className="col-auto">
                <Button
                    className="add-btn"
                    variant="outlined"
                    sx={{ mt: 2, boxShadow: 2 }}
                    onClick={handleAddTopicClick}
                    disabled={section?.status == 'Completed'}
                >
                    <Icon path={mdiPlus} size={1} />
                </Button>
            </div>
        </div>
        
    )
}

export default NewTopicForm;