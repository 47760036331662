import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './StartPageCreate.css'
import Icon from '@mdi/react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { mdiPlaylistEdit } from '@mdi/js';
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createAgenda } from '../../../store/slices/MeetingSlice';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '../../SampleSnackBar';
import Alert from '@mui/material/Alert';
import zoomSdk from '@zoom/appssdk';
import { Modal } from '@mui/material';
import { ModalDialog, ModalDialogProps } from '@mui/joy';
import mixpanel from 'mixpanel-browser';


export default function StartPageCreate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>("");
  const [layout, setLayout] = useState<ModalDialogProps['layout'] | undefined>(
    undefined
  );
  const { socketData, meeting, user } = useSelector((s: any) => (s.meeting));
  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const handleClick = async () => {
    mixpanel.identify(user?.id);
    mixpanel.track('Agenda Create', {
      'Created By': `${user?.first_name}`,
    })

    dispatch(createAgenda({ meetingId: meeting.id, agenda: value, userId: user.id }));
  };

  const handleInviteClick = async () => {
    const participants = await zoomSdk.getMeetingParticipants();
    const participantUUIDs = participants?.participants.map((participant) => participant.participantUUID);
    console.log(participantUUIDs);
    await zoomSdk.sendAppInvitation({ participantUUIDs: participantUUIDs })
      .then(async function (result) {
        setLayout('center');
      })
      .catch(function (error) {
        console.log(error, '<< error sendAppInvitation')
      })
    // await zoomSdk.addEventListener("onSendAppInvitation", {payload: ‘invitation UUID’});
  };

  // const showDialog = (message: any) => {
  //   // Implement your dialog logic here (e.g., using a modal, alert, etc.)
  //   alert(message);
  // };

  const blockAgendaInput = useMemo(() => {
    return meeting?.agenda?.id ? meeting?.agenda?.status === 'Initiated' : false;
  }, [meeting?.agenda?.status])

  const creator = useMemo(() => {
    if (meeting?.agenda?.created_by_user_id && meeting?.participants) {
      return meeting?.participants.find((pt: any) => pt?.user_id?.toString() === meeting?.agenda?.created_by_user_id?.toString())
    }
  }, [meeting?.agenda?.created_by_user_id, meeting?.participants])


  useEffect(() => {
    if (meeting?.agenda?.status === 'Initiated' && meeting?.agenda?.created_by_user_id === user.id)
      navigate("/preview");
    else if (meeting?.agenda?.status === 'Inprogress')
      navigate("/home");
  }, [meeting?.agenda])
  

  return (
    <Box sx={{ flexGrow: 1 }} className="main-bg">
      <Container maxWidth="lg">
        <br />
        <h4>Step 1: Invite All Meeting Participants to Open App</h4>
        <Paper>
          <Grid rowSpacing={1} direction="row" justifyContent="center" alignItems="center" >
            <Grid item xs={12} >
              <div className="row py-3">
                <div className="col-12 text-center">
                  <div className="form-main editor-wrapper px-4">
                    <p className='f-14'><strong>Invite all meeting participants to open the application.</strong></p>
                  </div>
                  <Button onClick={() => handleInviteClick()} variant="contained" sx={{ mt: 2 }}>Invite Participants</Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Modal open={!!layout} onClose={() => { setLayout(undefined); }}>
          <ModalDialog
            aria-labelledby="layout-modal-title"
            aria-describedby="layout-modal-description"
            layout={layout}
          >
            {/* <Typography id="layout-modal-title" component="h2">
              Edit Action
            </Typography> */}
            <div className="col-12 mt-2">
              Invitation sent to all participants
            </div>

            <div className="row align-items-center">
              <div className="col-12 text-right my-2">
                {/* <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button> */}
                {/* <Button variant="contained" size="small" onClick={handleUpdateActionTitleClick}>Save</Button> */}
              </div>
            </div>
          </ModalDialog>
        </Modal>

        <br />
        <h4>Step 2: Build Your Agenda</h4>
        <Paper>
          <Grid rowSpacing={1} direction="row" justifyContent="center" alignItems="center" >
            <Grid item xs={12} >
              <div className="row py-3">
                <div className="col-12 text-center">
                  {typeof meeting.id === 'undefined' && meeting?.isAuthorised ? null : (
                    !meeting.id && !meeting?.isAuthorised ? (
                      <Alert severity="error">
                        <strong className='f-14'>You must be in an active zoom meeting to submit an agenda</strong>
                      </Alert>
                    ) : (
                      <div className="form-main editor-wrapper px-4">
                        <p className='f-14'>
                          <strong>Paste in an agenda or type in a few bullets of what you want to discuss in the meeting.</strong>
                        </p>
                      </div>
                    )
                  )}
                  <br />
                  <div className="form-main editor-wrapper px-4">
                    <ReactQuill
                      className="form-main"
                      value={value}
                      modules={modules}
                      readOnly={blockAgendaInput ? true : false}
                      placeholder={"Example Agenda:\n- Company Updates\n- Metrics Review\n- Discussion Topics"}
                      onChange={setValue}
                    />
                  </div>
                  <br></br>
                  {blockAgendaInput && <Alert severity="info"><strong className='f-14'>{creator ? creator.first_name + ' ' + creator.last_name : 'CREATOR'} is adding the agenda for the meeting...</strong></Alert>}



                  <div>
                    <Button disabled={blockAgendaInput || !meeting.id} onClick={() => handleClick()} variant="contained" sx={{ mt: 2 }}>start Meeting</Button>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </Container>
    </Box>
  )
}


