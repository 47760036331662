import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import zoomSdk from '@zoom/appssdk';
import { authorizeOnZoom, getAgenda, getParticipants } from '../service/ApiService';
import { endMeetingAndSendEmail, fetchAgenda, fetchMeeting, initialize, isAuthorised } from "../store/slices/MeetingSlice";
import { LOCAL_USERS } from "../config";

const useZoom = () => {
  // 
  const dispatch = useDispatch();
  
  // configure ZOOM
  async function configureApp() {
    const configResponse = await zoomSdk.config({
      popoutSize: { height: 360, width: 450 },
      capabilities: [
        'getMeetingContext',
        'shareApp',
        'getMeetingParticipants',
        'sendAppInvitation',
        'getUserContext',
        'getMeetingUUID',
        'expandApp',
        'authorize',
        'promptAuthorize',
        'onShareApp',
        'onSendAppInvitation',
        'onAuthorized',
        'onExpandApp'
      ]
    }).then(async function () {
      // setStatus('authorize');
      //b045e3311452b245834b4049a1a2ae4d883b50cc7209cf21da959ce313f7a07a
      const authorizeOptions = {
        codeChallenge: "zoomtest1"
      };
      const zoomAuthResponse = await zoomSdk.authorize(authorizeOptions);
      const expandApp = await zoomSdk.expandApp({ action: 'expand' });
      console.log(expandApp);
    }).catch(function (er) {
      console.log(er, '< error');
      const queryParams = new URLSearchParams(window.location.search);
      const currentUrl = window.location.href;
      if (queryParams.has('code')) {
        // "code" parameter is present in the URL, handle the error and redirect the user
        console.error('Error in configureApp function');
        // Redirect the user to another page, e.g., an error page
        window.location.href = '/success'; // Replace with the actual error page URL
        return;
      } else if(currentUrl.indexOf('success') === -1) {
        window.location.href = 'https://www.meetiq.io/';
      }

      // setStatus('authorize');
    })
  }

  async function loadAgenda() {
    if (!JSON.parse(process.env.REACT_APP_IS_LOCAL as string)) {
      let agendaId = sessionStorage.getItem('agendaId');
      await dispatch(fetchAgenda({ agendaId: agendaId }));
    }
    else {
      await dispatch(fetchAgenda({ agendaId: process.env.REACT_APP_AGENDA_ID }));
    }
  }

  // USE EFFECT
  useEffect(() => {
    zoomSdk.onAuthorized((event) => {
      (async () => {
        try {
          let meetingID = '';

          await zoomSdk.getMeetingContext()
            .then(async function (result) {
              meetingID = result.meetingID;
            })
            .catch(async function (error) {
              dispatch(isAuthorised(true));
              console.log(error, '<< error getMeetingContext')
            })

          await zoomSdk.getMeetingUUID()
            .then(async function (result) {
              console.log('event>>', event, '++ result>>', result, '++ meetingID', meetingID)
              let responsedata = await authorizeOnZoom(event.redirectUri, event.code, result.meetingUUID, meetingID);
              console.log(responsedata, 'responsedata***');
              const resParticipants = await getParticipants(responsedata.meeting.id);
              // get all participants >-> store them in state for now 
              await dispatch(initialize({ meeting: { ...responsedata.meeting, participants: resParticipants.participants }, user: responsedata.user }))
            })
            .catch(function (error) {
              console.log(error, '< error');
            })
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      })()
    });

    zoomSdk.onExpandApp((event) => {
      console.log(event, 'event expand')
    });


    if (!JSON.parse(process.env.REACT_APP_IS_LOCAL as string)) {
      console.log('app configured***')
      configureApp();

    } else {
      if (JSON.parse(process.env.REACT_APP_IS_LOCAL as string)) {
        (async () => {
          // load agenda here    
          await loadAgenda();
          const resUsers = await getParticipants(process.env.REACT_APP_MEETING_ID)
          await dispatch(fetchMeeting({ meetingId: process.env.REACT_APP_MEETING_ID }));
          const randomUser: any = LOCAL_USERS[Math.floor(Math.random() * LOCAL_USERS.length)];
          await dispatch(initialize({ meeting: { participants: resUsers.participants }, user: { id: randomUser } }))
        })()
      }
    }
  }, []);

  return [];
};

export default useZoom;