import React, { useEffect, useMemo } from 'react';
import Icon from '@mdi/react';
import './Header.css'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { mdiPlusBoxMultipleOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import { mdiFormatListNumbered } from '@mdi/js';
import { mdiFormatListBulletedSquare } from '@mdi/js';
import { mdiChevronLeftBox } from '@mdi/js';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { mdiTimerOutline } from '@mdi/js';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export default function Header(props: {
    headerText?: string;
    stickyHeader?: boolean;
    showTimer?: boolean;
}) {

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));

    // get topic here and start timer here according to the topic status
    const location = useLocation();
    const navigate = useNavigate();
    const topicId = location?.state?.topicId;
    const sectionId = location?.state?.sectionId;
    // const topic = useMemo(() => {
    //     return {
    //         ...location.state?.topic
    //     }
    // }, [location]);



    //USE MEMO
    const topicdata: any = useMemo(() => {
        const sectionFound: any = agenda?.section?.find((sec: any) => sec.section_id == sectionId);
        const topicFound: any = sectionFound?.topics?.find((tp: any) => tp?.topic_id == topicId);
        return topicFound;
    }, [agenda?.section, topicId])

    const [timeCount, setTimeCount] = React.useState<string>('00:00');


    function timeDifference(date: any) {
        const date1: any = new Date();
        const date2: any = new Date(date);
        let difference = date1.getTime() - date2.getTime();
        let minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        let secondsDifference = Math.floor(difference / 1000);

        // Use padStart to ensure two digits for minutes and seconds
        const formattedMinutes = String(minutesDifference).padStart(2, '0');
        const formattedSeconds = String(secondsDifference).padStart(2, '0');

        setTimeCount(formattedMinutes + ":" + formattedSeconds);
    }

    useEffect(() => {
        let i: any;
        if (topicdata?.status === 'Started') {
            i = setInterval(() => { timeDifference(topicdata?.start_time) }, 1000)
        }
        if (topicdata?.status === 'Completed') {
            clearInterval(i);
        }
        return () => {
            clearInterval(i);
        }
    }, [topicdata?.status])


    return (
        <AppBar position="relative" color="secondary" className={"header-main" + (props.stickyHeader ? " header-main-fixed" : "")} sx={{ boxShadow: 2 }}>
            <Container maxWidth="lg">
                <Toolbar className="p-lr-0">
                    <div className="row align-items-center w-100">
                        <div className="col d-inline-flex align-items-center">
                            {location.pathname === '/feedback' && topicdata?.status != 'Started' && <a className="back-btn" style={{ cursor: 'pointer' }} onClick={() => {
                                if (location.pathname === '/home') {
                                    // navigate('/')
                                } else if (location.pathname === '/feedback') {
                                    navigate('/home')
                                }
                            }}>
                                <Icon path={mdiChevronLeftBox} size={1.4} />
                            </a>}
                                <Tooltip title={props.headerText} placement="bottom-start">
                                    <h3 className={"d-inline-flex ml-2 text-ellipsis" + (!props.headerText ? " d-none" : "")}>{props.headerText ?? ''}</h3>
                                </Tooltip>
                        </div>
                        <div className={"col-auto text-end d-inline-flex align-items-center" + (props.showTimer ? " d-none" : "")}>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} align="right">
                                <img src="/logo.png" width="70px" />
                            </Typography>
                        </div>
                        <div className={"col-auto timer-main justify-content-end " + (!props.showTimer ? " d-none" : "")}>
                            <div className={topicdata?.status == "Started" ? 'timer timer-on' : 'timer'}> <Icon path={mdiTimerOutline} size={1} className="mr-2 my-0" /> {topicdata?.status == "Started" ? timeCount : "00:00"}</div>
                        </div>
                    </div>

                </Toolbar>
            </Container>
        </AppBar>
    )
}
