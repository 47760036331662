import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export type News = {
   title: string;
   media: string;
   excerpt: string;
   summary: string;
   link: string;
};

export type FetchNewsRequestData = {
   searchText: string;
}

export const fetchNews: any = createAsyncThunk("news/fetchNews", async (data: FetchNewsRequestData) => {
   var options = {
      method: 'GET',
      url: 'https://api.newscatcherapi.com/v2/search',
      params: { q: data.searchText, lang: 'en', sort_by: 'relevancy', page: '1' },
      headers: {
         'x-api-key': 'KRgjBNi_QwUEdCdK1veUoC_Ek_9dL3Chk2nRgwm5_IY'
      }
   };

   const response = await axios(options);
   const news = await response.data;
   return news;
});

const newsSlice = createSlice({
   name: 'news',
   initialState: {
      searchText: '',
      articles: [],
      loading: false
   },
   reducers: {
      removeNews(state, action) {
         return { ...state, articles: [] };
      },
      searchTextInput(state, action) {
         return { ...state, searchText: action.payload }
      }
   },
   extraReducers: {
      [fetchNews.pending as any]: (state: any, action: any) => {
         state.loading = true;
      },
      [fetchNews.fulfilled as any]: (state: any, action: any) => {
         state.loading = false;
         state.articles = [...(action.payload.articles??[])];
      },
      [fetchNews.rejected as any]: (state: any, action: any) => {
         state.loading = false;
      },
   },
});

export default newsSlice.reducer;
export const { removeNews, searchTextInput } = newsSlice.actions;