import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './LikeOptionMenu.css';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';

export default function LikeOptionMenu(props: any) {
    const { topic } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { meeting } = useSelector((s: any) => (s.meeting));
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        console.log(topic)
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    return (
        <div>
            <a id="basic-button" className="like-counts" onClick={handleClick}>{topic?.vote?.reduce((a: any, cv: any) => a + cv.count, 0) ?? 0}</a>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleClose} className="like-menu-main">
                    <div className="row like-dislike-menu-header c">
                        <div className="col d-inline-flex align-items-center like-main"><Icon path={mdiThumbUpOutline} size={0.8} className="like-icon mr-2 mr-md-3" /><strong>{topic?.vote?.reduce((a: any, cv: any) => a + cv.count, 0) ?? 0}</strong></div>
                        {/* <div className="col d-inline-flex align-items-center dislike-main justify-content-end"><Icon path={mdiThumbDownOutline} size={0.8} className="dislike-icon mr-3" /><strong>13</strong></div> */}
                    </div>
                    <div className="row like-dislike-menu-body">
                        <div className="col-12">

                            {topic?.vote?.map((vt: any, i: number) => {
                                let userDetails = meeting?.participants?.find((pt: any) => pt.user_id === vt.user_id)

                                return (<div className="row align-items-center mb-3" key={'menu_option_' + i}>
                                    <div className="col d-inline-flex align-items-center">
                                        <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 25, height: 25, fontSize: 12, bgcolor: userDetails?.backgroundColor }} /> <span className="d-inline-flex ml-2 f-14">{userDetails?.first_name + ' ' + userDetails?.last_name}</span>
                                    </div>
                                    <div className="col d-inline-flex align-items-center justify-content-end">
                                        <div className="badge-like d-inline-flex align-items-center"><Icon path={mdiThumbUpOutline} size={0.7} className="like-icon mr-2" /><strong>{vt.count ?? 0}</strong></div>
                                    </div>
                                </div>);
                            })}
                            {/* <div className="row align-items-center mb-3">
                                <div className="col d-inline-flex align-items-center">
                                    <Avatar alt="Virendra Parmar" {...stringAvatar('Virendra Parmar')} src="https://mui.com/static/images/avatar/1.jpg" sx={{ width: 25, height: 25, fontSize: 12 }} /> <span className="d-inline-flex ml-2 f-14">Full Name</span>
                                </div>
                                <div className="col d-inline-flex align-items-center justify-content-end">
                                    <div className="badge-like d-inline-flex align-items-center"><Icon path={mdiThumbUpOutline} size={0.7} className="like-icon mr-2" /><strong>42</strong></div>
                                </div>
                            </div> */}
                            {/* <div className="row align-items-center mb-3">
                                <div className="col d-inline-flex align-items-center">
                                    <Avatar alt="Virendra Parmar" {...stringAvatar('Virendra Parmar')} src="" sx={{ width: 25, height: 25, fontSize: 12 }} /> <span className="d-inline-flex ml-2 f-14">Full Name</span>
                                </div>
                                <div className="col d-inline-flex align-items-center justify-content-end">
                                    <div className="badge-dislike d-inline-flex align-items-center"><Icon path={mdiThumbDownOutline} size={0.7} className="dislike-icon mr-2" /><strong>15</strong></div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col d-inline-flex align-items-center">
                                    <Avatar alt="Virendra Parmar" {...stringAvatar('Virendra Parmar')} src="https://mui.com/static/images/avatar/3.jpg" sx={{ width: 25, height: 25, fontSize: 12 }} /> <span className="d-inline-flex ml-2 f-14">Full Name</span>
                                </div>
                                <div className="col d-inline-flex align-items-center justify-content-end">
                                    <div className="badge-like d-inline-flex align-items-center"><Icon path={mdiThumbUpOutline} size={0.7} className="like-icon mr-2" /><strong>30</strong></div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col d-inline-flex align-items-center">
                                    <Avatar alt="Virendra Parmar" {...stringAvatar('Virendra Parmar')} src="" sx={{ width: 25, height: 25, fontSize: 12 }} /> <span className="d-inline-flex ml-2 f-14">Full Name</span>
                                </div>
                                <div className="col d-inline-flex align-items-center justify-content-end">
                                    <div className="badge-dislike d-inline-flex align-items-center"><Icon path={mdiThumbDownOutline} size={0.7} className="dislike-icon mr-2" /><strong>12</strong></div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col d-inline-flex align-items-center">
                                    <Avatar alt="Virendra Parmar" {...stringAvatar('Virendra Parmar')} src="" sx={{ width: 25, height: 25, fontSize: 12 }} /> <span className="d-inline-flex ml-2 f-14">Full Name</span>
                                </div>
                                <div className="col d-inline-flex align-items-center justify-content-end">
                                    <div className="badge-dislike d-inline-flex align-items-center"><Icon path={mdiThumbDownOutline} size={0.7} className="dislike-icon mr-2" /><strong>10</strong></div>
                                </div>
                            </div>                             */}
                        </div>
                    </div>
                </MenuItem>
            </Menu>
        </div>
    );
}