import React, { useEffect, useMemo, useRef } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';

import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from '../.././pages/Home/Tabs/LikeOptionMenu';
import UserDropdown from '../.././pages/StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from '../.././pages/Home/Tabs/Filters';
import Tooltip from '@mui/material/Tooltip';
import './DecisionsTabPrivew.css';
import { mdiDotsVertical } from '@mdi/js';
import DecisionsDropdown from './DecisionsDropdown';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDecision, updateDecisionsOrder, updateDecisionTypeFilter } from '../../store/slices/MeetingSlice';
import DecisionBar from './DecisionBar';
import Sortable from 'sortablejs';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Icon className="accordion-icon" path={mdiPlayBox} size={1} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// function stringToColor(string: string) {
//     let hash = 0;
//     let i;

//     /* eslint-disable no-bitwise */
//     for (i = 0; i < string.length; i += 1) {
//         hash = string.charCodeAt(i) + ((hash << 5) - hash);
//     }

//     let color = '#';

//     for (i = 0; i < 3; i += 1) {
//         const value = (hash >> (i * 8)) & 0xff;
//         color += `00${value.toString(16)}`.slice(-2);
//     }
//     /* eslint-enable no-bitwise */

//     return color;
// }

// function stringAvatar(name: string) {
//     return {
//         sx: {
//             bgcolor: stringToColor(name),
//         },
//         children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
//     };
// }


export default function DecisionsTabPrivew() {
    const [expanded, setExpanded] = React.useState<string | true>('panel1');
    const location = useLocation();
    const dispatch = useDispatch();

    const topicId = location?.state?.topicId;
    const sectionId = location?.state?.sectionId;

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));
    const { filterByDecisionType } = useSelector((s: any) => s.meeting);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : true);
        };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const { topicData, sectionData } = React.useMemo(() => {
        const sec = agenda?.section?.find((sc: any) => sc.section_id == sectionId);
        const result = sec?.topics?.find((tp: any) => tp.topic_id == topicId);
        return { topicData: result, sectionData: sec };
    }, [agenda?.section])

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchDecision({ agendaId: agenda?.id, topicId: topicId, sectionId: sectionId }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (agenda?.id && topicId) {
            fetchData();
        }
        // Call the async function
    }, [agenda?.id, topicId]); // Empty dependency array means this effect runs only once

    const filteredSections: any = useMemo(() => {
        const sectionFound: any = agenda?.section?.find((sec: any) => sec.section_id == sectionId);
        const topicFound: any = sectionFound?.topics?.find((tp: any) => tp?.topic_id == topicId);

        // Check if there are decisions for the topic
        if (topicFound && topicFound.decision) {
            if (filterByDecisionType) {
                // Filter decisions by status
                const filteredDecisionData = topicFound.decision.filter((decision: any) => decision.status == filterByDecisionType);
                return filteredDecisionData ?? [];
            } else {
                // Return all decisions and sort by display_order if available
                const decisions = [...topicFound.decision];

                // Check if at least one decision has a display_order
                const hasDisplayOrder = decisions.some((decision: any) => decision.display_order !== undefined);

                if (hasDisplayOrder) {
                    // Sort decisions by display_order
                    decisions.sort((a: any, b: any) => a.display_order - b.display_order);
                }

                return decisions;
            }
        }

        return [];
    }, [agenda?.section, topicId, filterByDecisionType]);

    const handleSectionFilter = async (value: String | Boolean) => {
        dispatch(updateDecisionTypeFilter(value));
    }


    const listRef = useRef(null);
    const agendaSectionRef = useRef(filteredSections); // Use a ref to store agenda.section
    const meetingIdRef = useRef(meeting?.id);
    useEffect(() => {
        console.log('agenda.section:', agenda?.section);
        console.log('listRef.current:', listRef.current);

        if (listRef.current && agendaSectionRef.current) {
            new Sortable(listRef.current, {
                animation: 350,
                // filter: '.no-sort',
                // handle: '.handle',
                // sort: 
                // Add your sortable configuration options here
                // For example, to handle sorting events:
                onEnd: async (evt: any) => {
                    console.log('Item moved from index', evt.oldIndex, 'to index', evt.newIndex);

                    const oldIndex = evt.oldIndex;
                    const newIndex = evt.newIndex;
                    console.log(agendaSectionRef.current, 'newIndex');
                    console.log(oldIndex, 'oldIndex');

                    // Access agenda.section through the ref
                    const currentAgendaDecision = agendaSectionRef.current;
                    const copyListItems = [...currentAgendaDecision];
                    const dragItemContent = copyListItems[oldIndex];
                    copyListItems.splice(oldIndex, 1);
                    copyListItems.splice(newIndex, 0, dragItemContent);
                    console.log(copyListItems, 'copyListItems');

                    const sortedDecisionIds = [...copyListItems.map((ac) => ac.id)];
                    await dispatch(updateDecisionsOrder({ decisionsArray: sortedDecisionIds, meetingId: meetingIdRef.current, agendaId: agenda?.id, topicId: topicId, sectionId: sectionId }));

                },
            });
        }
    }, [agendaSectionRef.current, meetingIdRef.current]);

    useEffect(() => {
        agendaSectionRef.current = filteredSections;
    }, [filteredSections]);

    useEffect(() => {
        meetingIdRef.current = meeting?.id;
    }, [meeting?.id]);

    return (
        <div>
            <div ref={listRef}>

                {   topicData?.decision?.length > 0 && 
                    <div className="row align-items-center my-3">
                        <div className="col-auto">
                            <Chip onClick={() => { handleSectionFilter(false) }} className="mr-2" label={<span className={"filter-label"}><small className="filter-count">{topicData?.decision?.length ?? 0}</small>All</span>} size="small" color={!filterByDecisionType ? "primary" : undefined} />
                            <Chip onClick={() => { handleSectionFilter('OPEN') }} className="mr-2" label={<span className={"filter-label"}><small className="filter-count">{topicData?.decision?.filter((et: any) => et?.status == 'OPEN').length ?? 0}</small>Open</span>} size="small" color={filterByDecisionType === 'OPEN' ? "primary" : undefined} />
                            <Chip onClick={() => { handleSectionFilter('COMPLETED') }} label={<span className={"filter-label"}><small className="filter-count">{topicData?.decision?.filter((et: any) => et?.status == 'COMPLETED').length ?? 0}</small>Completed</span>} size="small" color={filterByDecisionType === 'COMPLETED' ? "primary" : undefined} />
                        </div>
                    </div>
                }

                {filteredSections.length === 0 ? (
                    <div className="no_dataDecisions">
                        <div><img src="../../../../images/addDecisions.svg" alt=""/></div>

                        <p className="f-16"><center>Make your first decision in a collaborative way.</center></p>
                    </div>
                ) : (
                    <>
                        {filteredSections.map((decision: any, index: number) => {
                            let userDetails = meeting?.participants?.find((pt: any) => pt.user_id == decision.created_by_user_id);
                            return (
                                <DecisionBar topic={topicData} section={sectionData} decision={decision} index={index} />
                            );
                        })}
                    </>
                )}
            </div>
        </div>
    );
}

