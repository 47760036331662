import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import '../Feedback/FeedbackTabs.css'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import FeedBackTabPrivew from './FeedBackTabPrivew';
import DecisionsTabPrivew from './DecisionsTabPrivew';
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import { mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createAction, createDecision, createFeedback, updateSnackBarState } from '../../store/slices/MeetingSlice';
import mixpanel from 'mixpanel-browser';
import ActionsTabPrivew from './ActionsTabPrivew';
import TakeawaysTabPrivew from './TakeawaysTabPrivew';
import PrivateEveryoneSelect from '../Home/Tabs/PrivateEveryoneSelect';


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function FeedbackTabs(props: any) {
  const { topicData, hasActiveTopic } = props;
  const [value, setValue] = React.useState(0);
  const [inputLabel, setInputLabel] = React.useState<string>('EVERYONE');
  const [privacy, setPrivacy] = React.useState<string>('EVERYONE');
  const [feedbackInputError, setFeedbackInputError] = React.useState<boolean>(false);
  const [title, setTitle] = React.useState<string>('');
  const [inProgress, setInprogress] = React.useState<boolean>(false);
  const dispatch = useDispatch();

  const location = useLocation();
  const topicId = location?.state?.topicId;
  const sectionId = location?.state?.sectionId;

  // STORE
  const meeting = useSelector((s: any) => s.meeting.meeting);
  const agenda = useSelector((s: any) => s.meeting.meeting.agenda);
  const { filterBy, user } = useSelector((s: any) => s.meeting);

  const handleAddClick = async () => {
    if (!inProgress)
      setInprogress(true)
    if (title?.trim()) {
      if (value == 0) {
        const newFeedback = createNewFeedback(title);
        try {
          mixpanel.identify(user?.id);
          mixpanel.track('Create Feedback', {
            'Created By': `${user?.first_name}`,
            'Feedback': `${title}`
          })
          await dispatch(createFeedback({ meetingId: meeting.id, agendaId: agenda.id, feedback: newFeedback, topicId: topicId, sectionId: sectionId }));
        } catch (err) {
          console.log(err, 'error')
        }
      } else if (value == 2) {
        const newAction = createNewAction(title);
        try {
          console.log(user)
          mixpanel.identify(user?.id);
          mixpanel.track('Create Action', {
            'Created By': `${user?.first_name}`,
            'Action': `${title}`
          });
          await dispatch(createAction({ meetingId: meeting.id, agendaId: agenda.id, action: newAction, topicId: topicId, sectionId: sectionId }));
        } catch (err) {
          console.log(err, 'error')
        }
      } else if (value == 1) {
        const newDecision = createNewDecision(title);
        try {
          mixpanel.identify(user?.id);
          mixpanel.track('Create Decision', {
            'Created By': `${user?.first_name}`,
            'Decision': `${title}`
          });
          await dispatch(createDecision({ meetingId: meeting.id, agendaId: agenda.id, decision: newDecision, topicId: topicId, sectionId: sectionId }));
        } catch (err) {
          console.log(err, 'error')
        }
      }

      setTitle('');
      setInprogress(false)
    } else {
      setFeedbackInputError(true);
      setTimeout(() => {
        setFeedbackInputError(false);
      }, 3000)
      setInprogress(false)
    }
  }


  React.useEffect(() => {
    if (value == 0) {
      setInputLabel("Share a speaker’s point, your own point, or ask a question");
    } else if (value == 2) {
      setInputLabel("Add an action item");
    } else if (value == 1) {
      setInputLabel("Add a decision");
    }
    // Call the async function
  }, [value]); // Empty dependency array means this effect runs only once





  const createNewFeedback = (title: string) => {
    return {
      agenda_id: agenda.id,
      section_id: sectionId,
      topic_id: topicId,
      meeting_id: meeting.id,
      feedback: [{
        title: title,
        created_by_user_id: user.id,
        date_created: new Date().toISOString(),
      }]
    }
  }

  const createNewAction = (title: string) => {
    return {
      agenda_id: agenda.id,
      section_id: sectionId,
      topic_id: topicId,
      meeting_id: meeting.id,
      action: [{
        title: title,
        created_by_user_id: user.id,
        assigned_to_user_id: user.id,
        type: privacy,
        date_created: new Date().toISOString(),
      }],
    }
  }

  const createNewDecision = (title: string) => {
    return {
      agenda_id: agenda.id,
      section_id: sectionId,
      topic_id: topicId,
      meeting_id: meeting.id,
      decision: [{
        title: title,
        created_by_user_id: user.id,
        assigned_to_user_id: user.id,
        status: 'OPEN',
        date_created: new Date().toISOString()
      }],
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    dispatch(updateSnackBarState(newValue));
    setValue(newValue);
    console.log(newValue, 'value');
  };


  const handleSelectPrivacy = React.useCallback((value: string) => {
    setPrivacy(value);
    console.log(value)
  }, [setPrivacy]);


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="feedback-tabs-main">
        <Tabs
          value={value}
          onChange={handleChange}
          // aria-label="basic tabs example"
          variant="fullWidth"
          aria-label="full width tabs example">
          <Tab label={<Badge className="tab-count" badgeContent={topicData?.feedback?.length ?? '0'}>Feedback</Badge>} {...a11yProps(0)} />
          <Tab label={<Badge className="tab-count" badgeContent={topicData?.decision?.length ?? '0'}>Decisions</Badge>} {...a11yProps(1)} />
          <Tab label={<Badge className="tab-count" badgeContent={topicData?.action?.length ?? '0'}>Actions</Badge>} {...a11yProps(2)} />
          {/* <Tab label={<Badge className="tab-count" badgeContent={0}>Takeaways</Badge>} {...a11yProps(3)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <FeedBackTabPrivew />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DecisionsTabPrivew />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ActionsTabPrivew />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <TakeawaysTabPrivew />
      </CustomTabPanel>

      <div
        className={`${props.open ? "add-form-fixed-bottom feedback-min" : "add-form-fixed-bottom-feedback"} ${!hasActiveTopic ? "left-next-up-hide" : ""}`}
      >
        <Container maxWidth="lg">
          <div className="row align-items-center">
            <div className="col">
              <div>
                <TextField
                  label={inputLabel}
                  error={feedbackInputError}
                  id="outlined-size-small"
                  size="small"
                  fullWidth
                  disabled={topicData?.status == 'Completed' ? true : false}
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddClick();
                    }
                  }}
                />
              </div>
            </div>
            {
              value == 2 &&
              <div className="col-auto px-1">
                <PrivateEveryoneSelect onSelect={handleSelectPrivacy} />
              </div>
            }

            <div className="col-auto">
              <Button disabled={topicData?.status == 'Completed' ? true : false} className="add-btn" onClick={handleAddClick} variant="contained" sx={{ mt: 2, boxShadow: 1 }} ><Icon path={mdiPlus} size={1} className="mr-1" /> Add</Button>
            </div>
          </div>
        </Container>
      </div>
    </Box>
  );
}