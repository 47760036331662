import React from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';

import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from '../.././pages/Home/Tabs/LikeOptionMenu';
import UserDropdown from '../.././pages/StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from '../.././pages/Home/Tabs/Filters';
import PrivateEveryoneBlueSelect from '../.././pages/Home/Tabs/PrivateEveryoneBlueSelect';
import Tooltip from '@mui/material/Tooltip';
import './TakeawaysTabPrivew.css';
import { mdiDotsVertical } from '@mdi/js';
import DecisionsDropdown from './DecisionsDropdown';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Icon className="accordion-icon" path={mdiPlayBox} size={1} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


export default function HomeTab() {
    const [expanded, setExpanded] = React.useState<string | true>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : true);
        };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }


    return (
        <div>
            <div className="row align-items-center my-3">
                <div className="col-auto">
                    <Chip className="mr-2" label={<span className={"filter-label"}><small className="filter-count">4</small>All</span>} size="small" color="primary" />
                    <Chip className="mr-2" label={<span className={"filter-label"}><small className="filter-count">3</small>Active</span>} size="small" />
                    <Chip label={<span className={"filter-label"}><small className="filter-count">1</small>Completed</span>} size="small" />
                </div>
                {/* <div className="col text-right">
                    <Filters />
                </div> */}
            </div>
            <div className="row cust-feedback-main">
                <div className="col-12 feedback-header">
                    <div className="row align-items-center">
                        <div className="col-auto px-0 display-inherit">
                            <span className="drag-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></span>
                        </div>
                        <div className="col avtar-with-name">
                            <Avatar
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 24, height: 24 }}
                                {...stringAvatar('Remy Sharp')}
                            /><span>Remy Sharp</span>
                        </div>
                        <div className="col-auto  d-inline-flex align-items-center">
                            <div className="pl-1 pr-2 border-right">
                                {/* <PrivateEveryoneBlueSelect /> */}
                            </div>
                        </div>
                        <div className="col-auto d-inline-flex align-items-center">
                            <IconButton size="small" className="p-0">
                                <Icon path={mdiDotsVertical} size={0.9} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className="col-12 feedback-body">
                    <div className="row">
                        <div className="col-12 mt-2">

                            <TextField
                                id="outlined-size-small"
                                defaultValue="Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?"
                                size="small"
                                fullWidth
                                className="input-bg-white"
                            />
                        </div>
                    </div>
                    <div className="col-12 text-right my-2 px-0">
                        <Button variant="outlined" size="small" className="mr-2 btn-outline-white">Cancel</Button>
                        <Button variant="contained" size="small">Save</Button>
                    </div>
                </div>
            </div>

            <div className="row cust-feedback-main">
                <div className="col-12 feedback-header">
                    <div className="row align-items-center">
                        <div className="col-auto px-0 display-inherit">
                            <span className="drag-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></span>
                        </div>
                        <div className="col avtar-with-name">
                            <Avatar
                                alt="Remy Sharp"
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 24, height: 24 }}
                                {...stringAvatar('Remy Sharp')}
                            /><span>Remy Sharp</span>
                        </div>
                        <div className="col-auto  d-inline-flex align-items-center">
                            <div className="pl-1 mr-2 pr-2 border-right">
                                {/* <PrivateEveryoneBlueSelect /> */}
                            </div>
                        </div>
                        <div className="col-auto d-inline-flex align-items-center">
                            <div className="border-right mr-2 pr-3 ">
                                <p className="m-0 py-1 f-14">2:59 PM</p>
                            </div>
                            <IconButton size="small" className="p-0">
                                <Icon path={mdiDotsVertical} size={0.9} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div className="col-12 feedback-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="m-0 mb-2 f-14">Lorem ipsum dolor sit amet consectetur. Risus fusce rhoncus pharetra nibh vulputate. Id eget molestie vestibulum vitae gravida. Volutpat diam id viverra quam habitant vitae amet lacus diam.</p>
                            <p className="m-0 mb-2 f-14">Volutpat diam id viverra quam habitant vitae amet lacus diam.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }    