let local = process.env.REACT_APP_IS_LOCAL;
let API_URL = process.env.REACT_APP_API_URL;
let OPENAI_URL = process.env.REACT_APP_OPENAI_URL;
let LOCAL_USERS = [
    "f6a24b18-a38c-43c9-8bb1-cb7d72897efc",
    "0935a80c-2903-465e-aa35-badf10ae7a5b",
    "97a9dac6-5f27-4587-ae5f-75f4468eace6",
    "aa2ee639-38db-44f1-9039-07b0e59e54d1"
];
let PROMPT = `Our goal is to take the example agenda provided by a user and convert that into a relational table that can be easily added to a database. I will walk you through the steps.\nStep 1: Read the Example Agenda. For each parent bullet, consider that a section. If a child sub-bullet is present, consider that a topic within the parent bullet section. \nStep 2: From each section and topic, extract out the speaker and time allotment if it is present. Time allotment usually is a number followed by a unit of time. The remaining text in the section should be the AgendaSection and the remaining text in the topic should be the AgendaTopic. If no AgendaTopic is present, set that field to the AgendaSection. If the speaker or time allotment was extracted from the section, label it as AgendaSectionSpeaker and AgendaSectionTime, respectively. If it was extracted from the topic, label it as AgendaTopicSpeaker and AgendaTopicTime, respectively. Convert all units of times to minutes based on 60 minutes equaling 1 hour. \nStep 3: Return back JSON array with \"AgendaSectionID\", \"AgendaSection\", AgendaTopic, AgendaSectionTime, AgendaSectionSpeaker, AgendaTopicTime, AgendaTopicSpeaker. For every object that has an AgendaTopic, the AgendaSection should be set to the AgendaSection that it is the parent. For all empty information, add null.`;
export { API_URL, OPENAI_URL, PROMPT, LOCAL_USERS };