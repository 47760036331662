import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import { Delete } from '@mui/icons-material';
import Icon from '@mdi/react';
import { mdiDotsHorizontal } from '@mdi/js';
import './OptionMenu.css'
import IconButton from '@mui/material/IconButton';
import ModalDialog, { ModalDialogProps } from '@mui/joy/ModalDialog';
import { Modal } from '@mui/joy';
import { Button, Typography } from '@mui/material';
import { removeTopic, deleteAgendaSection, deleteFeedback, deleteDecision, deleteAction } from '../../../store/slices/MeetingSlice';
import { useDispatch, useSelector } from 'react-redux';

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function CustomizedMenus(props: any) {
  const dispatch = useDispatch()
  //PROPS 
  const { section, topic, type, inEditMode, setInEditMode, feedback, action, decision } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [layout, setLayout] = React.useState<ModalDialogProps['layout'] | undefined>(
    undefined
  );
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // STORE
  const { meeting } = useSelector((s: any) => (s.meeting));
  const { agenda, id } = useSelector((s: any) => s.meeting.meeting);

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    if (type == 'TOPIC') {
      try {
        await dispatch(removeTopic({
          meetingId: id,
          agendaId: agenda.id,
          sectionId: section.section_id,
          topicId: topic.topic_id
        }));
        setAnchorEl(null);
        setLayout(undefined)
      } catch (err) {
        console.log(err, 'error')
      }
    } else if (type == 'SECTION') {
      // const updatedAgenda = { ...agenda, section: [...(agenda.section.filter((sc: Section) => sc.section_id != section.section_id))] };
      try {
        await dispatch(deleteAgendaSection({ agendaId: agenda.id, sectionId: section.section_id, meetingId: meeting.id }));
        setLayout(undefined)
        setAnchorEl(null);
      } catch (err) {
        console.log(err, 'error')
      }
    } else if (type == 'FEEDBACK') {
      try {
        await dispatch(deleteFeedback({ agendaId: agenda.id, sectionId: section.section_id, meetingId: meeting.id, topicId: topic.topic_id, feedback: feedback }));
        setLayout(undefined)
        setAnchorEl(null);
      } catch (err) {
        console.log(err, 'error')
      }
    } else if (type == 'ACTION') {
      try {
        await dispatch(deleteAction({ agendaId: agenda.id, sectionId: section.section_id, meetingId: meeting.id, topicId: topic.topic_id, action: action }));
        setLayout(undefined)
        setAnchorEl(null);
      } catch (err) {
        console.log(err, 'error')
      }
    } else if (type == 'DECISION') {
      try {
        await dispatch(deleteDecision({ agendaId: agenda.id, sectionId: section.section_id, meetingId: meeting.id, topicId: topic.topic_id, decision: decision }));
        setLayout(undefined)
        setAnchorEl(null);
      } catch (err) {
        console.log(err, 'error')
      }
    }
  }

  return (
    <div>
      <IconButton  className="p-0 edit-menu-head" aria-label="add to favorites" onClick={handleClick}>
        <Icon path={mdiDotsHorizontal} size={1} className="c-dark" />
      </IconButton>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => { setInEditMode(true); handleClose(e); }} >
          <EditIcon />
          Edit
        </MenuItem>
        <MenuItem onClick={(e) => { handleClose(e); setLayout('center'); }} className="c-red">
          <Delete className="c-red" />
          Delete
        </MenuItem>

      </StyledMenu>

      <Modal open={!!layout} >
        <ModalDialog
          aria-labelledby="layout-modal-title"
          aria-describedby="layout-modal-description"
          layout={layout}
        >
          {/* <Typography id="layout-modal-title" component="h2">
            Edit Section
          </Typography> */}
          <div className="row align-items-center">
            <div className="col-12 mt-2">
              <p className="f-14">Are you sure you want to delete this item?</p>
            </div>
            <div className="col-12 text-right my-2">
              <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setLayout(undefined)}>Cancel</Button>
              <Button variant="contained" size="small" color='error' onClick={handleDelete}>Delete</Button>
            </div>
          </div>
        </ModalDialog>
      </Modal>
    </div>
  );
}