import { API_URL, OPENAI_URL } from '../config';

export const authorizeOnZoom = async (redirectUrl, code, meetingUUID, meetingId) => {
    try {
        let requestParam = {
            redirect_uri: redirectUrl,
            code: code,
            meetingUUID: meetingUUID,
            meetingId:meetingId
        }
        const response = await fetch(API_URL + 'zoom/onauthorized', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestParam),
        });
        let res = await response.json();
        return res;
    } catch (error) {
        throw error;
    }
};

export async function getAgendaFromOpenAi(value) {
    try {
        const AgendaSection = value;
        let requestParam = {
            "prompt": `Our goal is to take the example agenda provided by a user and convert that into a relational table that can be easily added to a database. I will walk you through the steps.\nStep 1: Read the Example Agenda. For each parent bullet, consider that a section. If a child sub-bullet is present, consider that a topic within the parent bullet section. \nStep 2: From each section and topic, extract out the speaker and time allotment if it is present. Time allotment usually is a number followed by a unit of time. The remaining text in the section should be the AgendaSection and the remaining text in the topic should be the AgendaTopic. If no AgendaTopic is present, set that field to the AgendaSection. If the speaker or time allotment was extracted from the section, label it as AgendaSectionSpeaker and AgendaSectionTime, respectively. If it was extracted from the topic, label it as AgendaTopicSpeaker and AgendaTopicTime, respectively. Convert all units of times to minutes based on 60 minutes equaling 1 hour. \nStep 3: Return back JSON array with \"AgendaSectionID\", \"AgendaSection\", AgendaTopic, AgendaSectionTime, AgendaSectionSpeaker, AgendaTopicTime, AgendaTopicSpeaker. For every object that has an AgendaTopic, the AgendaSection should be set to the AgendaSection that it is the parent. For all empty information, add null and just give the JSON as an output ${AgendaSection}`,
            "max_tokens": 2000,
            "temperature": 0,
            "frequency_penalty": 0,
            "presence_penalty": 0,
            "top_p": 0.5,
            "best_of": 1,
            "stop": null
        }
        const response = await fetch(OPENAI_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-key': '6875c0fa13f24b8fa2efdb153cff6a10'
            },
            body: JSON.stringify(requestParam),
        });
        let res = await response.json();
        const cleanedData = res?.choices[0].text.replace(/\\n|\\|\n/g, '');
        console.log(JSON.parse(cleanedData));
        return JSON.parse(cleanedData);
        // return JSON.parse(data);
    } catch (error) {
        throw error;
    }
}

export async function createAgenda(agenda) {
    try {
        let requestParam = {
            agenda: agenda
        }
        const response = await fetch(`${API_URL}agenda/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestParam),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export async function updateAgenda(agenda) {
    try {
        let requestParam = {
            agendaId: agenda.id,
            data: { ...agenda }
        }
        const response = await fetch(`${API_URL}agenda/update`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestParam),
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export async function getAgenda(agenda) {
    try {
        const response = await fetch(`${API_URL}agenda/get?id=${agenda}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}

export async function getParticipants(meetingId) {
    try {
        const response = await fetch(`${API_URL}meeting/participants/get?id=${meetingId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const data = await response.json();
        return data;
    } catch (error) {
        throw error;
    }
}


