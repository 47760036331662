import React, { useEffect, useState } from 'react'
import { mdiCircleSmall, mdiHandBackRight, mdiMenuDown, mdiMenuUp, mdiThumbUpOutline } from '@mdi/js'
import { Button, IconButton, TextField, Typography, Fade } from '@mui/material';
import Icon from '@mdi/react';
import OptionsMenu from "./OptionsMenu";
import UserDropdown from './UserDropdown';
import { fetchFeedback, Section, Topic, updateAgenda, updateRaiseHand, updateTopic, updateTopicSpecificData, voteUp } from '../../../store/slices/MeetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import LikeOptionMenu from '../../Home/Tabs/LikeOptionMenu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';


export const stringAvatar = (name: string) => {
    return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

const TopicBar = (props: any) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // PROPS
    const { topicIndex, section, topic, showLiveTimeBadge, showRaiseHand, showVoteCount } = props;

    // USE STATE
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [newTopicName, setNewTopicName] = useState<string>(topic.name);
    const [topicInputErr, setTopicInputErr] = React.useState<boolean>(false);
    const [seconds, setSeconds] = React.useState<number>(0);
    const [timeCount, setTimeCount] = React.useState<string>('00:00');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const navigateToFeedback = async () => {
        // await dispatch(fetchFeedback({ agendaId: agenda.id, topicId: topic.topic_id }));
        navigate("/feedback", { state: { sectionId: section?.section_id, topicId: topic?.topic_id } });
    };

    const handleClick = (event: any) => {
        if (event.target.outerText.includes('+')) {
            setAnchorEl(event.currentTarget);
            event.stopPropagation();
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const user = useSelector((s: any) => s.meeting.user);
    const { meeting } = useSelector((s: any) => (s.meeting));

    // USE EFFECT
    useEffect(() => {
        setNewTopicName(topic.name);
    }, [section, inEditMode]);

    useEffect(() => {
        let i: any;
        if (topic?.status === 'Started') {
            i = setInterval(() => { timeDifference(topic?.start_time) }, 1000)
        }
        if (topic?.status === 'Completed') {
            clearInterval(i);
        }
        return () => {
            clearInterval(i);
        }
    }, [topic?.status])

    // FUNCTION
    function timeDifference(date: any) {
        const date1: any = new Date();
        const date2: any = new Date(date);
        let difference = date1.getTime() - date2.getTime();
        let minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60;
        let secondsDifference = Math.floor(difference / 1000);

        // Use padStart to ensure two digits for minutes and seconds
        const formattedMinutes = String(minutesDifference).padStart(2, '0');
        const formattedSeconds = String(secondsDifference).padStart(2, '0');

        setTimeCount(formattedMinutes + ":" + formattedSeconds);
    }

    // function upTimer() {
    //     setSeconds((seconds: number) => seconds++);
    //     let hour = Math.floor(seconds / 3600);
    //     let minute = Math.floor((seconds - hour * 3600) / 60);
    //     let updSecond = seconds - (hour * 3600 + minute * 60);
    //     setTimeCount(minute + ":" + updSecond); //hour + ":" + minute + ":" + updSecond);
    // }

    const handleUpdateTopicClick = async () => {
        if (!newTopicName || !(newTopicName.trim())) {
            setTopicInputErr(true);
            setTimeout(() => {
                setTopicInputErr(false);
            }, 3000);
            return;
        }

        try {
            await dispatch(updateTopic({
                agendaId: agenda?.id,
                sectionId: section?.section_id,
                topicId: topic?.topic_id,
                userId: user?.id,
                value: newTopicName,
                meetingId: meeting?.id
            }));
            setInEditMode(false)
        } catch (err) {
            console.log(err, 'error')
            setInEditMode(false)

        }
    }

    const createNewRaisedHandUser = () => {
        if (topic?.raise_hand?.length > 0) {
            const raisedHand = topic?.raise_hand.find((data: any) => data.user_id === user.id);
            if (raisedHand) {
                return {
                    ...raisedHand,
                    status: raisedHand.status === "Inactive" ? "Active" : "Inactive"
                };
            }
        }
        return {
            "user_id": user.id,
            "first_name": user.first_name,
            "last_name": user.last_name,
            "ParticipantScreenName": user.first_name,
            "pic": user?.zoom_pic_url,
            "date_created": "2018-10-31T04:32:37Z",
            "status": "Active"
        }
    }

    const createNewVoteUser = () => {
        return {
            "user_id": user.id,
            "first_name": user.first_name,
            "last_name": user.last_name,
            "ParticipantScreenName": user.first_name,
            "pic": user?.zoom_pic_url,
            "vote_type": "Up",
            "date_created": new Date().toISOString()
        }
    }

    const handleRaiseHand = async (event:any) => {
        const newUser: any = createNewRaisedHandUser();
        event.stopPropagation();
        await dispatch(updateRaiseHand({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, user: newUser, meetingId: meeting.id }));
    }

    const handleVoteUp = async () => {
        const newUser: any = createNewVoteUser();
        await dispatch(voteUp({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, user: newUser, meetingId: meeting.id }));
    }

    const handlePlayClick = async () => {
        // const hasStartedTopic = section?.topics?.some((topic: any) => topic.status === 'Started');
        let hasStartedTopic = false;
        agenda.section.forEach((sec:any) => {
            sec.topics.forEach((topic:any) => {
                if (topic.status === 'Started') {
                    hasStartedTopic = true;
                    return true;
                }
            });
        });
        if (!hasStartedTopic) {
            await dispatch(updateTopicSpecificData({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, updateData: { key: 'UPDATE_STARTTIME', value: new Date().toISOString() }, meetingId: meeting.id }));
        } else {
            console.log('one topic already running');
        }
    }

    return (
        <div className="col-12"
            style={{ opacity: topic?.status == 'Completed' ? 0.6 : 1 }}
            key={"topic" + topicIndex}><Fade in={true} timeout={1500}>
            <div className="row accrdion-body-main">
                {
                    showVoteCount && <div className={`${topic?.status === 'Started' ? 'main-bg-green col-auto px-0' : topic?.status === 'Completed' ? 'main-bg-blue-disable col-auto px-0' : 'main-bg-blue col-auto px-0'}`}>
                        <div className="col-auto like-left-bg-main">
                            <div className="p-relative">
                                <div onClick={handleVoteUp} style={{ cursor: 'pointer' }}>
                                    <Icon className={topic?.status == 'Completed' ? 'arrow-up-count disabled' : 'arrow-up-count'} path={mdiMenuUp} size={1} />
                                </div>
                                <LikeOptionMenu topic={topic} />
                                {/* <div onClick={handleVoteDown}>
                                    <Icon className="arrow-down-count" path={mdiMenuDown} size={1} />
                                </div> */}
                            </div>
                        </div>
                    </div>
                }

                <div className={topic?.status == 'Started'? "col topic-bar-cursor" : "col"} onClick={() => { if (topic?.status === 'Started') { navigateToFeedback(); } }}>
                    <div className="row align-items-center card-head">
                        <div className="col-auto d-inline-flex pr-0">
                            <UserDropdown showName={false} type={'topic'} topic={topic} section={section} />
                        </div>
                        <div className="col col-xxl-auto d-inline-flex pl-1">
                            <Typography className="time-status">
                                <Icon
                                    path={mdiCircleSmall}
                                    size={1}
                                    className="c-dark"
                                />
                                <span>{new Date(topic?.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ?? ''}</span>{" "}
                                {/* <span>{new Date(topic?.date_created).toLocaleTimeString() ?? ''}</span>{" "} */}
                                <Icon
                                    path={mdiCircleSmall}
                                    size={1}
                                    className="c-dark"
                                />
                            </Typography>
                        </div>
                        {showLiveTimeBadge && <div className="col col-xxl-auto px-0">
                            <div className="badge-inner badge-green"><span className="icon-dot"></span>Live {timeCount}</div>
                        </div>}

                        {topic?.status != 'Completed' && <div className="col avtar-group-sec-main">
                            {showRaiseHand && topic.raise_hand &&

                                <AvatarGroup onClick={handleClick} max={4}>
                                    {topic.raise_hand?.map((rh: any) => {
                                        if (rh.status === 'Active') {
                                            let userDetails = meeting.participants.find((pt: any) => pt.user_id === rh.user_id)
                                            return <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 30, height: 30, fontSize: 14, bgcolor: userDetails?.backgroundColor }} />
                                        }
                                    })}
                                </AvatarGroup>
                            }
                            {showRaiseHand && <a href="#" className={topic?.status == 'Completed' ? 'hand-icon ml-2 disabled' : 'hand-icon ml-2'} onClick={handleRaiseHand}><Icon path={true ? mdiHandBackRightOutline : mdiHandBackRight} size={1} /></a>}
                        </div>}
                        {showRaiseHand && <div className="col-auto join-play-its-over-sec px-1 py-1">
                            {(topic?.status === 'Created' || topic?.status === 'Started') ? <div className={topic?.status == 'Created' ? "badge badge-blue" : "badge badge-green"} style={{ cursor: 'pointer' }} onClick={() => { if (topic?.status === 'Created') { handlePlayClick(); } navigateToFeedback(); }} >
                            {topic?.status == 'Started' ? 'Enter Topic': 'Start Topic'}
                            </div> :
                                <div className="badge badge-blue" style={{ cursor: 'pointer' }} onClick={() => { navigateToFeedback(); }}>View Completed Topic</div>}
                        </div>}
                        {!inEditMode && topic?.status != 'Completed' && <div className="col-auto options-menu-icon pr-0">
                            <OptionsMenu setInEditMode={setInEditMode} inEditMode={inEditMode}
                                section={section} topic={topic} type={'TOPIC'} />
                        </div>}
                    </div>

                    {!inEditMode ?
                        <div className="row align-items-center">
                            <div className="col px-3">
                                    <p className="my-2 text-ellipsis-2">
                                        {topic.name}
                                    </p>
                            </div>
                            <div className="col-auto avtar-group-sec-main">
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={handleClose} className="like-menu-main">
                                        <div className="row like-dislike-menu-header c">
                                            <div className="col d-inline-flex align-items-center like-main"><a href="#" className="hand-icon mr-3"><Icon path={true ? mdiHandBackRightOutline : mdiHandBackRight} size={1} /></a> <strong>{topic?.raise_hand?.length}</strong></div>
                                        </div>
                                        <div className="row like-dislike-menu-body">
                                            <div className="col-12">
                                                {topic?.raise_hand?.map((vt: any, i: number) => {
                                                    if (vt.status === 'Active') {
                                                        let userDetails = meeting?.participants?.find((pt: any) => pt.user_id === vt.user_id)
                                                        return (<div className="row align-items-center mb-3" key={'menu_option_' + i}>
                                                            <div className="col d-inline-flex align-items-center">
                                                                <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 25, height: 25, fontSize: 12, bgcolor: userDetails?.backgroundColor }} /> <span className="d-inline-flex ml-2 f-14">{userDetails?.first_name + ' ' + userDetails?.last_name}</span>
                                                            </div>
                                                        </div>);
                                                    }
                                                })}
                                            </div>
                                        </div>
                                    </MenuItem>
                                </Menu>

                            </div>

                        </div> :
                        <div className="row align-items-center">
                            <div className="col-12 mt-2">
                                    <div>
                                    <TextField
                                        error={topicInputErr}
                                        id="outlined-size-small"
                                        size="small"
                                        value={newTopicName}
                                        onChange={(e) => setNewTopicName(e.target.value)}
                                        fullWidth
                                        className="input-bg-white"
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                                handleUpdateTopicClick();
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 text-right my-2">
                                <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button>
                                <Button variant="contained" size="small" onClick={handleUpdateTopicClick}>Save</Button>
                            </div>
                        </div>
                    }


                </div>
            </div></Fade>
        </div>)
}


export default TopicBar