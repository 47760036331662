import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Icon from '@mdi/react';
import { mdiPlus } from '@mdi/js';
import Container from '@mui/material/Container';
import { mdiHomeOutline } from '@mdi/js';
import { mdiClipboardClockOutline } from '@mdi/js';
import { mdiNoteEditOutline } from '@mdi/js';
import { mdiCallSplit } from '@mdi/js';
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button';
//import HomeTab from './Tabs/HomeTab';
import HomeTab from '../Home/Tabs/HomeTab';
import TextField from '@mui/material/TextField';
import ActionsTab from '../Home/Tabs/ActionsTab';
import PrivateEveryoneSelect from './Tabs/PrivateEveryoneSelect';
import DecisionsTab from '../Home/Tabs/DecisionsTab';
import NoteTab from '../Home/Tabs/NoteTab';
import { useDispatch, useSelector } from 'react-redux';
import { addAgendaSection, endMeetingAndSendEmail, Section, updateFilter } from '../../store/slices/MeetingSlice';
import mixpanel from 'mixpanel-browser';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function TabPreview() {

  const [sectionName, setSectionName] = React.useState<string>('');
  const [inProgress, setInprogress] = React.useState<boolean>(false);
  const [sectionNameInputError, setSectionNameInputError] = React.useState<boolean>(false);

  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch()

  // STORE
  const meeting = useSelector((s: any) => s.meeting.meeting);
  const agenda = useSelector((s: any) => s.meeting.meeting.agenda);
  const { filterBy, user } = useSelector((s: any) => s.meeting);
  const { showEndMeetingButton } = useSelector((s: any) => s.meeting);

  const isShowEndMeetingButton: any = React.useMemo(() => {
    if (!showEndMeetingButton) {
      window.location.href = '/return';
    }
    return showEndMeetingButton;
  }, [showEndMeetingButton]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // FUNCTIONS
  const createSectionId = () => {
    const ids = agenda?.section?.map((et: Section) => et?.section_id).sort();
    if (!ids.length)
      return 1;
    if (ids) {
      let largest = ids.sort((a: number, b: number) => a - b).reverse()[0]
      return largest + 1;
    }
  }

  const createNewSection = (name: string): Section => {
    return {
      section_id: createSectionId(),
      name: name,
      speaker: user.id,
      status: "Active",
      display_order: "1",
      display_order_last_updated_by_user_id: user.id ?? '1',
      duration: 0,
      duration_updated_by_user_id: user.id ?? '1',
      created_by_user_id: user.id ?? '1',
      date_created: new Date().toISOString(),
      topics: []
    }
  }

  const handleAddNewSectionClick = async () => {
    if (!inProgress)
      setInprogress(true)
    if (sectionName?.trim()) {
      const newSection: Section = createNewSection(sectionName);
      try {
        mixpanel.identify(user?.id);
        mixpanel.track('Create Section', {
          'Created By': `${user?.first_name}`,
          'Section': `${sectionName}`
        })

        await dispatch(addAgendaSection({ meetingId: meeting.id, agendaId: agenda.id, section: newSection })).catch((error: any) => {
          // Show a Toastr notification when the action is rejected
          // toast.error('Agenda Section creation failed. Please try again later.');
        });
        // await dispatch(updateAgenda(updatedAgenda));
      } catch (err) {
        console.log(err, 'error')
      }
      setSectionName('');
      setInprogress(false)
    } else {
      setSectionNameInputError(true);
      setTimeout(() => {
        setSectionNameInputError(false);
      }, 3000)
      setInprogress(false)
    }
  }

  const handleSectionFilter = async (value: String | Boolean) => {
    dispatch(updateFilter(value));
  }


  const handleEndMeeting = async () => {
    let obj = {
      "event": "meeting.ended",
      "payload": {
        "object": {
          "uuid": `${meeting.meeting_uuid}`
        }
      }
    }
    dispatch(endMeetingAndSendEmail({ ...obj }));
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div className="tab-bg-main">
        <Container maxWidth="lg" >
          <div className="row justify-space-between">
            <div className="col">
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                  <Tab icon={<Icon path={mdiHomeOutline} size={0.9} />} iconPosition="start" label={<span className={"tabLabel"}>Home</span>} {...a11yProps(0)} />
                  {/* <Tab icon={<Icon path={mdiClipboardClockOutline} size={0.9} />} iconPosition="start" label={<span className={"tabLabel"}>Actions</span>} {...a11yProps(1)} />
                  <Tab icon={<Icon path={mdiCallSplit} size={0.9} />} iconPosition="start" label={<span className={"tabLabel"}>Decisions</span>} {...a11yProps(2)} />
                  <Tab icon={<Icon path={mdiNoteEditOutline} size={0.9} />} iconPosition="start" label={<span className={"tabLabel"}>Note</span>} {...a11yProps(3)} /> */}
                </Tabs>

              </Box>
            </div>
            <div className="col-auto">
              <Button hidden={!isShowEndMeetingButton} onClick={handleEndMeeting} variant="contained">END MEETING</Button>
            </div>
          </div>
        </Container>
      </div>
      <Container maxWidth="lg" className="tab-body">
        <CustomTabPanel value={value} index={0}>
          <div className="row align-items-center mt-3">
            <div className="col-auto">
              <Chip className="mr-2" onClick={() => { handleSectionFilter(false) }} label={<span className={"filter-label"}><small className="filter-count">{agenda?.section?.length ?? 0}</small>All</span>} size="small" color={!filterBy ? "primary" : undefined} />
              <Chip className="mr-2" onClick={() => { handleSectionFilter('Active') }} label={<span className={"filter-label"}><small className="filter-count">{agenda?.section?.filter((et: any) => et?.status == 'Active').length ?? 0}</small>Active</span>} size="small" color={filterBy === 'Active' ? "primary" : undefined} />
              <Chip onClick={() => { handleSectionFilter('Completed') }} label={<span className={"filter-label"}><small className="filter-count">{agenda?.section?.filter((et: any) => et.status === 'Completed').length ?? 0}</small>Completed</span>} size="small" color={filterBy === 'Completed' ? "primary" : undefined} />
            </div>
            {/* <div className="col text-right">
              <Button variant="outlined" className="btn-outline-white">Collapse All</Button>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 mt-3">
              <HomeTab />
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ActionsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <DecisionsTab />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <NoteTab />
        </CustomTabPanel>
      </Container>

      <div className="add-form-fixed-bottom">
        <Container maxWidth="lg">
          <div className="row align-items-center">
            <div className="col">
              <div>
                <TextField
                  label="Add Agenda Section"
                  error={sectionNameInputError}
                  id="outlined-size-small"
                  // defaultValue="Add Discussion Topic"
                  size="small"
                  fullWidth
                  value={sectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                      handleAddNewSectionClick();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-auto">
              <Button type='submit' onClick={handleAddNewSectionClick} className="add-btn" variant="contained" sx={{ mt: 2, boxShadow: 1 }} ><Icon path={mdiPlus} size={1} className="mr-1" /> Add</Button>
            </div>
          </div>
        </Container>

      </div>

    </Box>
  );
}