import React, { useEffect, useMemo, useRef } from 'react'
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Icon from "@mdi/react";
import { mdiClockTimeFourOutline } from "@mdi/js";
import { mdiPlayBox } from "@mdi/js";
import NewTopicForm from "./NewTopicForm";
import SectionSummary from "./SectionSummary";
import TopicBar from "./TopicBar";
import { useDispatch, useSelector } from "react-redux";
import { updateAgenda, updateSectionOrder } from "../../../store/slices/MeetingSlice";
import Sortable from 'sortablejs';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

const AccordionSummary = styled(
    ({ iconClickHandler, ...props }: AccordionSummaryProps & { iconClickHandler?: any }) => (
        <MuiAccordionSummary
            expandIcon={
                <span onClick={iconClickHandler}>
                    <Icon className="accordion-icon" path={mdiPlayBox} size={1} />
                </span>
            }
            {...props}
        />
    )
)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default React.memo(function CustomizedAccordions() {
    const dispatch = useDispatch();

    // USE REF
    const dragItem: any = useRef();
    const dragOverItem: any = useRef();

    // STORE
    const { agenda, id } = useSelector((s: any) => s.meeting.meeting);

    // STATE
    const [expanded, setExpanded] = React.useState<string[]>([]);

    // DRAG N DROP FUNCTIONS START 

    const dragStart: any = (e: any, position: any) => {
        e.currentTarget.classList.add('drag-move');
        dragItem.current = position;
    };
    const dragEnter: any = (e: any, position: any) => {
        dragOverItem.current = position;
    };
    const drop = async (e: any) => {
        e.currentTarget.classList.remove('drag-move');
        const copyListItems: any[] = [...(agenda?.section ?? [])];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        const sortedSectionIds = [...copyListItems.map((sec: any) => sec.section_id)];
        dispatch(updateSectionOrder({ sectionsArray: sortedSectionIds, meetingId: id, agendaId: agenda.id }))
        await dispatch(updateAgenda({ ...agenda, section: [...copyListItems] }));
    };
    // DRAG N DROP FUNCTIONS END 

    const listRef = useRef(null);
    const agendaSectionRef = useRef(agenda); // Use a ref to store agenda.section
    const meetingIdRef = useRef(id);

    useEffect(() => {
        console.log('agenda.section:', agenda?.section);
        console.log('listRef.current:', listRef.current);

        if (listRef.current && agendaSectionRef.current) {
            new Sortable(listRef.current, {
                animation: 350,
                // filter: '.no-sort',
                // handle: '.handle',
                // sort: 
                // Add your sortable configuration options here
                // For example, to handle sorting events:
                onEnd: async (evt: any) => {
                    console.log('Item moved from index', evt.oldIndex, 'to index', evt.newIndex);

                    const oldIndex = evt.oldIndex;
                    const newIndex = evt.newIndex;
                    // Access agenda.section through the ref
                    const currentAgendaSection = agendaSectionRef.current?.section;
                    const copyListItems = [...currentAgendaSection];
                    const dragItemContent = copyListItems[oldIndex];
                    copyListItems.splice(oldIndex, 1);
                    copyListItems.splice(newIndex, 0, dragItemContent);

                    const sortedSectionIds = [...copyListItems.map((sec) => sec.section_id)];
                    await dispatch(updateSectionOrder({ sectionsArray: sortedSectionIds, meetingId: meetingIdRef.current, agendaId: agendaSectionRef.current?.id }));
                    // await dispatch(updateAgenda({ ...agendaSectionRef.current, section: [...copyListItems] }));
                },
            });
        }
    }, [agendaSectionRef.current, meetingIdRef.current]);

    useEffect(() => {
        agendaSectionRef.current = agenda;
    }, [agenda?.section]);

    useEffect(() => {
        meetingIdRef.current = id;
    }, [id]);

    const filteredSections = useMemo(() => {
        // Check if filterBy is defined

        // Return all sections and sort by display_order if available
        const sections = [...agenda?.section ?? []];

        const hasDisplayOrder = sections.some((section: any) => section.display_order !== undefined);

        if (hasDisplayOrder) {
            sections.sort((a: any, b: any) => a.display_order - b.display_order);
        }

        return sections;

    }, [agenda?.section]);

    // FUNCTIONS 
    const handleChange = (panel: string) => {
        if (expanded.includes(panel)) {
            // If the panel is already open, close it
            setExpanded(expanded.filter((p) => p !== panel));
        } else {
            // If the panel is closed, open it
            setExpanded([...expanded, panel]);
        }
    };

    const handleCollapseAll = () => {
        // Close all accordion panels by setting the expanded state to an empty array
        setExpanded([]);
    };

    useEffect(() => {
        // Initialize the `expanded` state with all panel identifiers
        const allPanelIds = filteredSections?.map((et: any, ind: any) => "panel" + ind) || [];
        setExpanded(allPanelIds);
    }, [filteredSections]);

    return (
        <div>
            <div className="cust-accordion-main" ref={listRef}>
                {filteredSections?.map((et: any, ind: number) => {
                    return (
                        <Accordion
                            key={"key" + ind}
                            className="accodion-bg"
                            expanded={expanded.includes("panel" + ind)}
                            onChange={() => handleChange("panel" + ind)}
                        // onDragStart={(e) => dragStart(e, ind)}
                        // onDragEnter={(e) => dragEnter(e, ind)}
                        // onDragEnd={drop}
                        // onDragOver={(e) => e.preventDefault()}
                        // draggable
                        >
                            <AccordionSummary
                                // iconClickHandler={() => handleChange("panel" + ind)}
                                className="accordion-header"
                                aria-controls={"panel" + ind + "-content"}
                                id={"panel" + ind + "-header"}
                            >
                                <SectionSummary isShowCheckBox={false} isTimeEditable={true} mdiClockTimeFourOutline={mdiClockTimeFourOutline} section={et} />
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className="row ">
                                    {
                                        et && et.topics.map((topic: any, topicIndex: number) =>
                                            <TopicBar
                                                key={'topic_bar_' + topicIndex}
                                                topicIndex={topicIndex}
                                                section={et}
                                                topic={topic} showLiveTimeBadge={false} showVoteCount={false} showRaiseHand={false} />
                                        )
                                    }
                                    {/* start add form */}
                                    <div className="col-12">
                                        <NewTopicForm section={et} agenda={agenda} />
                                    </div>
                                    {/* start add form */}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}
            </div>
        </div>
    );
})
