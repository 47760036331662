import React, { useEffect, useRef, useState } from 'react'
import Icon from '@mdi/react';
import { mdiDotsHorizontal, mdiDotsVertical } from '@mdi/js';
import { Avatar, Chip, IconButton, Modal, TextField, Tooltip, Typography, Fade } from '@mui/material';
import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { updateAction, updateFeedback, updateFeedbackReactions } from '../../store/slices/MeetingSlice';
import ReactionButton from './ReactionButton';
import PrivateEveryoneBlueSelect from '../Home/Tabs/PrivateEveryoneBlueSelect';
import UserDropdown from '../StartPage/StartPagePrivew/UserDropdown';
import { ModalDialog, ModalDialogProps } from '@mui/joy';

const ActionBar = (props: any) => {
    const { action, topic, section, index } = props
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [actionTitleInputErr, setActionTitleInputErr] = React.useState<boolean>(false);
    const [privacy, setPrivacy] = React.useState<string>('');
    const [newActionTitle, setNewActionTitle] = useState<string>(action.title);
    const dispatch = useDispatch();
    const [layout, setLayout] = useState<ModalDialogProps['layout'] | undefined>(
        undefined
    );

    // USE REF
    const dragItem: any = useRef();
    const dragOverItem: any = useRef();

    // DRAG N DROP FUNCTIONS START 
    const dragStart: any = (e: any, position: any) => {
        console.log(e);
        e.currentTarget.classList.add('drag-move');
        dragItem.current = position;
    };

    const dragEnter: any = (e: any, position: any) => {
        console.log(e);
        dragOverItem.current = position;
    };

    const drop = async (e: any) => {
        console.log(e, 'drop');
        e.currentTarget.classList.remove('drag-move');
        const copyListItems: any[] = [...(agenda?.section ?? [])];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        // const sortedSectionIds = [...copyListItems.map((sec: any) => sec.section_id)];
        // dispatch(updateSectionOrder({ sectionsArray: sortedSectionIds, meetingId: id, agendaId: agenda.id }))
        // await dispatch(updateAgenda({ ...agenda, section: [...copyListItems] }));
    };
    // DRAG N DROP FUNCTIONS END 

    const user = useSelector((s: any) => s.meeting.user);
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));

    useEffect(() => {
        setLayout(inEditMode ? 'center' : undefined)
    }, [inEditMode])


    // FUNCTION
    const handleUpdateActionTitleClick = async () => {
        if (!newActionTitle || !(newActionTitle.trim())) {
            setActionTitleInputErr(true);
            setTimeout(() => {
                setActionTitleInputErr(false);
            }, 3000);
            return;
        }
        try {
            await dispatch(updateAction({
                agendaId: agenda?.id,
                topicId: topic?.topic_id,
                value: newActionTitle,
                sectionId: section.section_id,
                meetingId: meeting?.id,
                dataId: action.id,
                actionId: action?.actionId
            }));
            setInEditMode(false)
        } catch (err) {
            console.log(err, 'error')
            setInEditMode(false)
        }
    }

    return (
        <Fade in={true} timeout={1500}>
        <div>
            <div>
                <div className="row cust-feedback-main"
                    onDragStart={(e) => dragStart(e, index)}
                    onDragEnter={(e) => dragEnter(e, index)}
                    onDragEnd={drop}
                    onDragOver={(e) => e.preventDefault()}
                >
                    <div className="col-12 feedback-header">
                        <div className="row align-items-center">
                            <div className="col-auto px-0 display-inherit">
                                <span className="drag-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></span>
                            </div>
                            <div className="col avtar-with-name">
                            <div className="col-12 avtar-with-name">
                                <div className="d-inline-flex mr-2 c-light-gray text-medium f-14">Assign to : </div>
                                <UserDropdown showName={false} section={section} topic={topic} type='action' action={action} />
                            </div>
                            </div>
                            <Modal open={!!layout} onClose={() => { setInEditMode(false); setLayout(undefined); }}>
                                <ModalDialog
                                    aria-labelledby="layout-modal-title"
                                    aria-describedby="layout-modal-description"
                                    layout={layout}
                                >
                                    <Typography id="layout-modal-title" component="h2">
                                        Edit Action
                                    </Typography>
                                    <div className="row align-items-center">
                                        <div className="col-12 mt-2">
                                            <div>
                                                <TextField
                                                    error={actionTitleInputErr}
                                                    id="outlined-size-small"
                                                    size="small"
                                                    required={true}
                                                    value={newActionTitle}
                                                    onChange={(e) => setNewActionTitle(e.target.value)}
                                                    fullWidth
                                                    className="input-bg-white"
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleUpdateActionTitleClick();
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 text-right my-2">
                                            <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button>
                                            <Button variant="contained" size="small" onClick={handleUpdateActionTitleClick}>Save</Button>
                                        </div>
                                    </div>
                                </ModalDialog>
                            </Modal>

                            {
                                user.id == action.created_by_user_id &&
                                <div className="col-auto  d-inline-flex align-items-center">
                                    <div className="pl-1 mr-2 pr-2 border-right">
                                        <PrivateEveryoneBlueSelect action={action} topic={topic} section={section} />
                                    </div>
                                    {topic?.status != 'Completed' && <OptionsMenu setInEditMode={setInEditMode}
                                        action={action}
                                        section={section} topic={topic}
                                        type={'ACTION'} />}

                                </div>
                            }
                        </div>
                    </div>
                    <div className="col-12 feedback-body mb-2">
                        <div className="row">
                        <div className="col-12">
                            <p className="m-0 f-14">
                                {action.title}
                            </p>
                        </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div></Fade>
    )
}

export default ActionBar;