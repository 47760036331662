import React from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import './HomeTab.css'
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import OptionsMenu from '../../StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from './LikeOptionMenu';
import { mdiMenuDown } from '@mdi/js';
import UserDropdown from '../../StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from './Filters';
import PrivateEveryoneBlueSelect from './PrivateEveryoneBlueSelect';
import './NoteTab.css'



const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Icon className="accordion-icon" path={mdiPlayBox} size={1} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));


export default function HomeTab() {
    const [expanded, setExpanded] = React.useState<string | true>('panel1');

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : true);
        };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }


    return (
        <div>

            <div className="row align-items-center mt-3">
                <div className="col-auto">
                    <Chip className="mr-2" label={<span className={"filter-label"}><small className="filter-count">4</small>All</span>} size="small" color="primary" />
                    <Chip className="mr-2" label={<span className={"filter-label"}><small className="filter-count">3</small>Active</span>} size="small" />
                    <Chip label={<span className={"filter-label"}><small className="filter-count">1</small>Completed</span>} size="small" />
                </div>
                <div className="col text-right">
                    <Filters />
                </div>
            </div>

            <div className="cust-accordion-main mt-3">
                <Accordion className="accodion-bg" expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary className="accordion-header" aria-controls="panel1d-content" id="panel1d-header">
                        {/* start header */}
                        <div className="row align-items-center">
                            <div className="col d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center">
                                    <a href="#" className="drag-move-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></a>
                                    <Typography className="ml-2 f-14 text-ellipsis-single-line"><span className="c-light-gray">Section:</span> <span className="text-bold">Discuss Full Year Budget</span></Typography>
                                    <Typography className="ml-2 f-14 text-ellipsis-single-line"><span className="c-light-gray">Topic:</span> <span className="text-bold">Discuss Full Year Budget</span></Typography>
                                </div>
                            </div>
                            <div className="col-auto d-inline-flex align-items-center">
                                <div className="pl-1 mr-2 pr-2 border-right">
                                    {/* <PrivateEveryoneBlueSelect /> */}
                                </div>
                                <Icon path={mdiDotsHorizontal} size={1} className="c-dark" />
                            </div>
                        </div>
                        {/* stendart header */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-12 pb-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum arcu quis venenatis venenatis. Maecenas mollis vulputate elit vitae congue. Mauris id diam sed purus sodales faucibus. Curabitur nisi lacus, luctus sit amet aliquet eu,
                                    </div>
                                </div>
                                <div className="row align-items-center d-none">
                                    <div className="col-12 mt-2">
                                        <div>
                                            <TextField
                                                // label="Edit"
                                                id="outlined-size-small"
                                                defaultValue="Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?"
                                                size="small"
                                                fullWidth
                                                className="input-bg-white"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 text-right my-2">
                                        <Button variant="outlined" size="small" className="mr-2 btn-outline-white">Cancel</Button>
                                        <Button variant="contained" size="small">Save</Button>
                                    </div>
                                </div>
                            </div>
                            {/* start add form */}
                            <div className="col-12">
                                <div className="row align-items-center accrdion-bottom-form pb-0">
                                    <div className="d-inline-flex col">
                                        <div className="d-inline-flex mr-2 c-light-gray text-medium f-14">Assign to:</div> <UserDropdown />
                                    </div>
                                    <div className="col-auto">
                                        <p className="m-0 f-14 c-light-gray">2:59 PM</p>
                                    </div>
                                </div>
                            </div>
                            {/* start add form */}

                        </div>
                    </AccordionDetails>
                </Accordion>

                <Accordion className="accodion-bg" expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary className="accordion-header" aria-controls="panel2d-content" id="panel2d-header">
                        {/* start header */}
                        <div className="row align-items-center">
                            <div className="col d-inline-flex align-items-center">
                                <div className="d-inline-flex align-items-center">
                                    <a href="#" className="drag-move-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></a>
                                    <Typography className="ml-2 f-14">
                                        <span className="c-light-gray mr-1">Section:</span>
                                        <IconButton className="add-blue-circle-btn" color="primary" aria-label="Add Section">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </IconButton>
                                    </Typography>
                                    <Typography className="ml-2 f-14">
                                        <span className="c-light-gray mr-1">Topic:</span>
                                        <IconButton className="add-blue-circle-btn" color="primary" aria-label="Add Section">
                                            <Icon path={mdiPlus} size={0.7} />
                                        </IconButton>
                                    </Typography>
                                </div>
                            </div>
                            <div className="col-auto d-inline-flex align-items-center">
                                <div className="pl-1 mr-2 pr-2 border-right">
                                    {/* <PrivateEveryoneBlueSelect /> */}
                                </div>
                                <Icon path={mdiDotsHorizontal} size={1} className="c-dark" />
                            </div>
                        </div>
                        {/* stendart header */}
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="row">
                            <div className="col-12">
                                <div className="row align-items-center">
                                    <div className="col-12 pb-2">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc fermentum arcu quis venenatis venenatis. Maecenas mollis vulputate elit vitae congue. Mauris id diam sed purus sodales faucibus. Curabitur nisi lacus, luctus sit amet aliquet eu,
                                    </div>
                                </div>
                                <div className="row align-items-center d-none">
                                    <div className="col-12 mt-2">
                                        <div>
                                            <TextField
                                                // label="Edit"
                                                id="outlined-size-small"
                                                defaultValue="Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?"
                                                size="small"
                                                fullWidth
                                                className="input-bg-white"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 text-right my-2">
                                        <Button variant="outlined" size="small" className="mr-2 btn-outline-white">Cancel</Button>
                                        <Button variant="contained" size="small">Save</Button>
                                    </div>
                                </div>
                            </div>
                            {/* start add form */}
                            <div className="col-12">
                                <div className="row align-items-center accrdion-bottom-form pb-0">
                                    <div className="d-inline-flex col">
                                        <div className="d-inline-flex mr-2 c-light-gray text-medium f-14">Assign to:</div> <UserDropdown />
                                    </div>
                                    <div className="col-auto">
                                        <p className="m-0 f-14 c-light-gray">2:59 PM</p>
                                    </div>
                                </div>
                            </div>
                            {/* start add form */}

                        </div>
                    </AccordionDetails>
                </Accordion>

            </div>
        </div>
    );
}
