import { configureStore } from '@reduxjs/toolkit'
import newsSlice from './slices/newsSlice';
import MeetingSlice from './slices/MeetingSlice';

const store = configureStore({
    reducer: {
        news: newsSlice,
        meeting: MeetingSlice
    }
})

export default store;