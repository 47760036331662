import React, { useEffect, useRef, useState } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import { mdiPlus } from '@mdi/js';
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';

import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from '../.././pages/Home/Tabs/LikeOptionMenu';
import UserDropdown from '../.././pages/StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from '../.././pages/Home/Tabs/Filters';
import Tooltip from '@mui/material/Tooltip';
import './DecisionsTabPrivew.css';
import { mdiDotsVertical } from '@mdi/js';
import DecisionsDropdown from './DecisionsDropdown';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';
import { useLocation } from 'react-router-dom';
import { Modal, ModalDialog, ModalDialogProps } from '@mui/joy';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import { updateDecision, updateDecisionFinalMessage, updateDecisionReactions } from '../../store/slices/MeetingSlice';


const DecisionBar = (props: any) => {
    const { decision, topic, section, index } = props
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const [newDecisionTitle, setNewDecisionTitle] = useState<string>(decision.title);
    const [layout, setLayout] = useState<ModalDialogProps['layout'] | undefined>(undefined);
    const [decisionTitleInputErr, setDecisionTitleInputErr] = React.useState<boolean>(false);
    const [finalMessageInputErr, setfinalMessageInputErr] = React.useState<boolean>(false);
    const [newFinalMessage, setNewFinalMessage] = useState<{ isSaved: boolean, message: string }>({ isSaved: decision?.final_decision ? true : false, message: decision?.final_decision });
    const [reactionPopUpData, setReactionPopUpData] = useState<{ path: string, reactionName: string, title: string }>({ path: '', reactionName: '', title: '' });

    const [reactionType, setReactionType] = useState<string>('');
    const dispatch = useDispatch();


    const user = useSelector((s: any) => s.meeting.user);
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, reactionType: any, path: any, title: string) => {
        setReactionPopUpData(reaction => {
            return {
                ...reaction,
                path: path,
                reactionName: reactionType,
                title: title
            }
        })
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = (event: React.MouseEvent<HTMLElement>, reactionType: any, path: any) => {
        setAnchorEl(null);
    };


    // FUNCTION
    const handleSetFinalMessageClick = async () => {
        if (!newFinalMessage?.message || !(newFinalMessage?.message.trim())) {
            setDecisionTitleInputErr(true);
            setTimeout(() => {
                setDecisionTitleInputErr(false);
            }, 3000);
            return;
        }
        try {
            await dispatch(updateDecisionFinalMessage({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, finalMessage: newFinalMessage.message, meetingId: meeting.id, dataId: decision.id, decisionId: decision.decisionId }));
            setNewFinalMessage(s => {
                return {
                    ...s, isSaved: true
                }
            })
        } catch (err) {
            console.log(err, 'error')
        }
    }

    const handleReactionsClick = async (e: any, reactionType: any) => {
        if (decision?.status != 'COMPLETED') {
            await dispatch(updateDecisionReactions({
                sectionId: section.section_id,
                topicId: topic?.topic_id,
                reactionType: reactionType,
                userId: user.id,
                meetingId: meeting?.id,
                decisionId: decision?.decisionId,
                decisionMessageId: decision?.id
            }));
        }
    }

    const handleUpdateDecisionTitleClick = async () => {
        if (!newDecisionTitle || !(newDecisionTitle.trim())) {
            setDecisionTitleInputErr(true);
            setTimeout(() => {
                setDecisionTitleInputErr(false);
            }, 3000);
            return;
        }
        try {
            await dispatch(updateDecision({
                agendaId: agenda?.id,
                topicId: topic?.topic_id,
                value: newDecisionTitle,
                sectionId: section.section_id,
                meetingId: meeting?.id,
                dataId: decision.id,
                decisionId: decision?.decisionId
            }));
            setInEditMode(false)
        } catch (err) {
            console.log(err, 'error')
            setInEditMode(false)
        }
    }

    useEffect(() => {
        setLayout(inEditMode ? 'center' : undefined)
    }, [inEditMode])


    return (
        <Fade in={true} timeout={1500}>
        <div>
            <div className="row cust-feedback-main">
                <div className="col-12 feedback-header">
                    <div className="row align-items-center">
                        <div className="col-auto px-0 display-inherit">
                            <span className="drag-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></span>
                        </div>
                        <div className="col avtar-with-name">
                            <div className="d-inline-flex mr-2 c-light-gray text-medium f-14">Decision Owner:</div> <UserDropdown showName={false} section={section} topic={topic} type={'decision'} decision={decision} />
                        </div>

                        <div className="col-auto  d-inline-flex align-items-center">
                            <div className="pl-1 mr-2 pr-2 border-right">
                                <DecisionsDropdown decision={decision} topic={topic} section={section} />
                            </div>
                            {
                                topic?.status != 'Completed' && decision?.status != 'COMPLETED' && <OptionsMenu setInEditMode={setInEditMode}
                                    decision={decision}
                                    section={section} topic={topic}
                                    type={'DECISION'} />
                            }

                        </div>
                    </div>
                </div>
                <div className="col-12 feedback-body">
                    <div className="row">
                        <div className="col-12">
                            <p className="m-0 f-14">
                                {decision?.title}
                            </p>
                        </div>
                        <Modal open={!!layout} onClose={() => { setInEditMode(false); setLayout(undefined); }}>
                            <ModalDialog
                                aria-labelledby="layout-modal-title"
                                aria-describedby="layout-modal-description"
                                layout={layout}
                            >
                                <Typography id="layout-modal-title" component="h2">
                                    Edit Action
                                </Typography>
                                <div className="row align-items-center">
                                    <div className="col-12 mt-2">
                                        <div>
                                            <TextField
                                                error={decisionTitleInputErr}
                                                id="outlined-size-small"
                                                size="small"
                                                required={true}
                                                value={newDecisionTitle}
                                                onChange={(e) => setNewDecisionTitle(e.target.value)}
                                                fullWidth
                                                className="input-bg-white"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleUpdateDecisionTitleClick();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 text-right my-2">
                                        <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button>
                                        <Button variant="contained" size="small" onClick={handleUpdateDecisionTitleClick}>Save</Button>
                                    </div>
                                </div>
                            </ModalDialog>
                        </Modal>

                        <div aria-disabled='true' className="col-12 mt-2">
                            <div className="row like-dislike-counts">
                                <div className={topic?.status == 'Completed' ? 'col-auto d-inline-flex align-items-center disabled' : 'col-auto d-inline-flex align-items-center'} onMouseEnter={(e) => handlePopoverOpen(e, 'THUMS_UP', mdiThumbUpOutline, 'Agree')}
                                    onMouseLeave={(e) => handlePopoverClose(e, 'THUMS_UP', mdiThumbUpOutline)} onClick={(e) => handleReactionsClick(e, 'THUMS_UP')}>
                                    <Icon path={mdiThumbUpOutline} size={0.7} className="flip-rotate" /><span className="text-medium ml-1 f-14">{decision?.reactions?.find((react: any) => react.reaction_type == 'THUMS_UP')?.created_by_user_id?.length ?? '0'}</span>
                                </div>
                                <div className={topic?.status == 'Completed' ? 'col-auto d-inline-flex align-items-center ml-2 mx-2 border-left-right disabled' : 'col-auto d-inline-flex align-items-center ml-2 mx-2 border-left-right'} onMouseEnter={(e) => handlePopoverOpen(e, 'HIGH_FIVE', mdiThumbUpOutline, 'Neutral')}
                                    onMouseLeave={(e) => handlePopoverClose(e, 'HIGH_FIVE', mdiThumbDownOutline)} onClick={(e) => handleReactionsClick(e, 'HIGH_FIVE')}>
                                    <Icon path={mdiThumbUpOutline} size={0.7} className="flip-rotate" rotate={270} /><span className="text-medium ml-1 f-14">{decision?.reactions?.find((react: any) => react.reaction_type == 'HIGH_FIVE')?.created_by_user_id?.length ?? '0'}</span>
                                </div>
                                <div className={topic?.status == 'Completed' ? 'col-auto d-inline-flex align-items-center disabled' : 'col-auto d-inline-flex align-items-center'} onMouseEnter={(e) => handlePopoverOpen(e, 'THUMS_DOWN', mdiThumbDownOutline, 'Disagree')}
                                    onMouseLeave={(e) => handlePopoverClose(e, 'THUMS_DOWN', mdiThumbDownOutline)} onClick={(e) => handleReactionsClick(e, 'THUMS_DOWN')}>
                                    <Icon path={mdiThumbDownOutline} size={0.7} className="flip-rotate" /><span className="text-medium ml-1 f-14">{decision?.reactions?.find((react: any) => react.reaction_type == 'THUMS_DOWN')?.created_by_user_id?.length ?? '0'}</span>
                                </div>
                            </div>
                        </div>

                        <Popover
                            id="mouse-over-popover"
                            sx={{
                                pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            // onClose={(e)=> handlePopoverClose(e, '','')}
                            disableRestoreFocus
                        >

                            <div className="row emoji-popover">
                                <div className="col-12 icon">
                                    <Icon path={reactionPopUpData?.path} size={0.7} rotate={reactionPopUpData?.title == 'Neutral'? 270: 0}  className="flip-rotate" />
                                    {/* <img src={reactionPopUpData?.path} /> */}
                                </div>
                                <div className="col-12">
                                    <p className="my-2"><strong>{reactionPopUpData?.title}</strong></p>
                                    <p className="m-0 names f-14">
                                        {decision?.reactions?.map((react: any) => {
                                            if (react.reaction_type == reactionPopUpData?.reactionName) {
                                                const userNames: string[] = [];

                                                react?.created_by_user_id?.forEach((id: any) => {
                                                    let userDetails = meeting?.participants?.find((pt: any) => pt.user_id === id);
                                                    if (userDetails) {
                                                        // const fullName = `${userDetails.first_name} ${userDetails.last_name}`;
                                                        // if (id !== currentUserID) { // Replace currentUserID with the ID of the logged-in user
                                                        //     userFullNames.push(fullName);
                                                        // }
                                                        userNames.push(`${userDetails.first_name} ${userDetails.last_name}`);
                                                    }
                                                });

                                                const isCurrentUserIncluded = react.created_by_user_id?.includes(user?.id);

                                                const allUserNames = userNames.join(', ');

                                                if (allUserNames) {
                                                    if (isCurrentUserIncluded) {
                                                        return (
                                                            <span key={react.reaction_id}>
                                                                {allUserNames}
                                                                {/* {' and '}
                                                    <strong>You</strong> */}
                                                            </span>
                                                        );
                                                    } else {
                                                        return <span key={react.reaction_id}>{allUserNames}</span>;
                                                    }
                                                }
                                            }

                                            return null; // Return null if the reaction_type doesn't match or there are no user names
                                        })}
                                    </p>
                                </div>
                            </div>

                        </Popover>
                    </div>
                </div>
                <div className="col-12 feedback-footer">
                    <div className="row align-items-center">
                        <div className="col-12">
                            {!newFinalMessage.isSaved ? (
                                <div className="row align-items-center">
                                    <div className="col">
                                        <div>
                                            <TextField
                                                error={finalMessageInputErr}
                                                label="Record final decision"
                                                id="outlined-size-small"
                                                size="small"
                                                fullWidth
                                                required={false}
                                                disabled={topic?.status == 'Completed' || decision?.status == 'COMPLETED' ? true : false}
                                                value={newFinalMessage?.message}
                                                onChange={(e) => setNewFinalMessage(s => {
                                                    return {
                                                        ...s, message: e.target.value
                                                    }
                                                })}
                                                className="input-bg-white"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        handleSetFinalMessageClick();
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <Button
                                            className="add-btn"
                                            variant="outlined"
                                            sx={{ mt: 2, boxShadow: 2 }}
                                            disabled={topic?.status == 'Completed' ? true : false}
                                        >
                                            <Icon path={mdiPlus} size={1} />
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <span className="f-14">{newFinalMessage?.message}</span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div></Fade>
    )
}

export default DecisionBar;