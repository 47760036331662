import React, { useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../Home/Home'
import FeedBack from '../Feedback/FeedBack'
import StartPageCreate from './StartPageCreate/StartPageCreate'
import StartPagePrivew from './StartPagePrivew/StartPagePrivew'
import LandingPage from './LandingPage/LandingPage'

export default function StartPage() {
    return (
        <>
            <Routes>
                <Route path="/" element={<StartPageCreate />} />
                <Route path="/success" element={<LandingPage />} />
                <Route path="/return" element={<StartPageCreate  />} />
                <Route path="/preview" element={<StartPagePrivew />} />
                <Route path="/home" element={<Home />} />
                <Route path="/feedback" element={<FeedBack />} />
            </Routes>
        </>
    )
}
