import React, { memo, useEffect } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import './StartPagePrivew.css'
import Icon from '@mdi/react';
import Container from '@mui/material/Container';
import StartPagePrivewAccordion from './StartPagePrivewAccordion';
import { mdiPlus } from '@mdi/js';
import TextField from '@mui/material/TextField';
import { Section, startMeeting, updateAgenda } from '../../../store/slices/MeetingSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import zoomSdk from '@zoom/appssdk';
import mixpanel from 'mixpanel-browser';

export default memo(function StartPagePrivew() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // STORE
    const meeting = useSelector((s: any) => s.meeting.meeting);
    const agenda = useSelector((s: any) => s.meeting.meeting.agenda);
    const user = useSelector((s: any) => s.meeting.user);

    // USE STATE
    const [sectionName, setSectionName] = React.useState<string>('');
    const [inProgress, setInprogress] = React.useState<boolean>(false);
    const [sectionNameInputError, setSectionNameInputError] = React.useState<boolean>(false);

    // USE EFFECT 
    useEffect(() => {
        if (meeting?.agenda?.status === 'Inprogress')
            navigate("/home");
    }, [meeting?.agenda])


    // FUNCTIONS
    const createSectionId = () => {
        const ids = agenda?.section?.map((et: Section) => et?.section_id).sort();
        if (!ids.length)
            return 1;
        if (ids) {
            let largest = ids.sort((a: number, b: number) => a - b).reverse()[0]
            return largest + 1;
        }
    }

    const createNewSection = (name: string): Section => {
        return {
            section_id: createSectionId(),
            name: name,
            speaker: {},
            status: "Active",
            display_order: "1",
            display_order_last_updated_by_user_id: user.id ?? '1',
            duration: 0,
            duration_updated_by_user_id: user.id ?? '1',
            created_by_user_id: user.id ?? '1',
            date_created: new Date().toISOString(),
            topics: []
        }
    }

    const handleAddNewSectionClick = async () => {
        if (!inProgress)
            setInprogress(true)
        if (sectionName?.trim()) {
            const newSection: Section = createNewSection(sectionName);
            const updatedAgenda = { ...agenda, section: [...(agenda?.section ?? []), newSection] };
            try {
                mixpanel.identify(user?.id);
                mixpanel.track('Create Section', {
                    'Created By': `${user?.first_name}`,
                    'Section': `${sectionName}`
                })
                await dispatch(updateAgenda(updatedAgenda));
            } catch (err) {
                console.log(err, 'error')
            }
            setSectionName('');
            setInprogress(false)
        } else {
            setSectionNameInputError(true);
            setTimeout(() => {
                setSectionNameInputError(false);
            }, 3000)
            setInprogress(false)
        }
    }

    const handleStartMeeting = async () => {
        const participants = await zoomSdk.getMeetingParticipants();
        const participantUUIDs = participants?.participants.map((participant) => participant.participantUUID);
        console.log(participantUUIDs);
        await zoomSdk.sendAppInvitation({ participantUUIDs: participantUUIDs })
            .then(async function (result) {
                // setLayout('center');
            })
            .catch(function (error) {
                console.log(error, '<< error sendAppInvitation')
            })

        mixpanel.identify(user?.id);
        mixpanel.track('Publish Agenda from Preview', {
            'Published By': `${user?.first_name}`,
        })

        dispatch(startMeeting({
            meetingId: meeting.id,
            agendaId: agenda.id,
            userId: user.id
        }))
    }

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <Container maxWidth="lg">
                    <div className="row align-items-center my-3 mt-64">
                        <div className="col-auto">
                        </div>

                        <div className="col text-right">
                            <Button variant="contained" onClick={() => { handleStartMeeting() }}>Publish Agenda</Button>
                        </div>
                    </div>
                    <div className="col-12">
                        <Alert severity="info"><strong className='f-14'><u>You are currently in the agenda builder.</u> Participants will not see the agenda until you hit publish.</strong></Alert>
                        <br></br>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <StartPagePrivewAccordion />
                        </div>
                    </div>
                    {
                        meeting?.agenda?.section?.length == 0 &&
                        <div className="no_dataDecisions">
                            <div><img src="../../../../images/addAgenda.svg" alt="" /></div>

                            <p className="f-16"><center>Add your first agenda section.</center></p>

                        </div>
                    }
                    <div className="add-form-fixed-bottom">
                        <Container maxWidth="lg">
                            <div className="row align-items-center">
                                <div className="col">
                                    <div>
                                        <TextField
                                            error={sectionNameInputError}
                                            label="Add Agenda Section"
                                            id="outlined-size-small"
                                            size="small"
                                            fullWidth
                                            value={sectionName}
                                            onChange={(e) => setSectionName(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                    handleAddNewSectionClick();
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Button className="add-btn" onClick={handleAddNewSectionClick} variant="contained" sx={{ mt: 2, boxShadow: 1 }} ><Icon path={mdiPlus} size={1} className="mr-1" /> Add</Button>
                                </div>
                            </div>
                        </Container>

                    </div>
                </Container>
            </Box>
        </div>
    )
})
