import React, { memo, useEffect, useMemo } from 'react'
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Icon from '@mdi/react';
import { mdiChevronLeftBox } from '@mdi/js';
import StartPage from '../pages/StartPage/StartPage';
import './Layout.css'
import Header from './Header/Header';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { snackBarReducer } from '../store/slices/MeetingSlice';
import LandingPage from '../pages/StartPage/LandingPage/LandingPage';
import mixpanel from 'mixpanel-browser';

export default memo(function Layout() {
  const location = useLocation();
  const topicId = location?.state?.topicId;
  const sectionId = location?.state?.sectionId;
  const { agenda } = useSelector((s: any) => s.meeting.meeting);
  const { loading, snackBar } = useSelector((s: any) => s.meeting);
  const dispatch = useDispatch();
  // const queryParams = new URLSearchParams(window.location.search);

  // if (queryParams.has('code')) {
  //   return <LandingPage />;
  // }


  const topicdata: any = useMemo(() => {
    const sectionFound: any = agenda?.section?.find((sec: any) => sec.section_id == sectionId);
    const topicFound: any = sectionFound?.topics?.find((tp: any) => tp?.topic_id == topicId);
    return topicFound;
}, [agenda?.section, topicId])


  useEffect(() => {
    mixpanel.init('166fe024d228864dce53688cd070bf3d', {debug: true, track_pageview: true, persistence: 'localStorage'});
  }, [])
  

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(snackBarReducer({ type: 'CLOSE_SNACK_BAR' }));
  };

  const action = (
    <React.Fragment>
      {/* <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button> */}
      <IconButton
        size="small"
        aria-label="close"
        color={snackBar?.type}
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div className="App">
      <Header showTimer={location?.pathname === '/feedback'} stickyHeader={location?.pathname === '/feedback'} headerText={topicdata?.name ?? ''} />
      <StartPage />
      {loading && <CircularProgress sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />}
      {snackBar?.isOpen &&
        <Snackbar
          anchorOrigin={{ vertical: 'top',
          horizontal: 'right' }}
          open={snackBar?.isOpen}
          autoHideDuration={3000}
          onClose={handleClose}
          message={snackBar?.message}
          action={action}
          style={{ marginTop: '35px' }} 
        />
      }
    </div>
  )
})
