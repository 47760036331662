import React, { useEffect, useMemo, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import '../StartPageCreate/StartPageCreate.css'
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from 'react-redux';


export default function LandingPage() {
    const dispatch = useDispatch();

    return (
        <Box sx={{ flexGrow: 1 }} className="main-bg">
            <Container maxWidth="lg">
                <Paper>
                    <Grid rowSpacing={1} direction="row" justifyContent="center" alignItems="center" >
                        <Grid item xs={12} >
                            <div className="row py-3">
                                <div className="col-12 text-center">
                                    <div className="meeting-text-main" >
                                        <Typography className="meeting-text" sx={{ my: 5 }}>Success! <br></br>You can now load the app in your Zoom account.</Typography>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Paper>
            </Container>
        </Box>
    )
}


