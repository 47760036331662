import React, { useEffect, useState } from 'react'
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiDotsHorizontal } from '@mdi/js';
import { Avatar, TextField, Tooltip, Fade } from '@mui/material';
import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { updateFeedback, updateFeedbackReactions } from '../../store/slices/MeetingSlice';
import ReactionButton from './ReactionButton';
import Typography from '@mui/joy/Typography';

const FeedbackBar = (props: any) => {
    const { userDetails, fd, topic, section } = props
    const dispatch = useDispatch()
    const [inEditMode, setInEditMode] = useState<boolean>(false);
    const user = useSelector((s: any) => s.meeting.user);
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));


    const [newFeedbackTitle, setNewFeedbackTitle] = useState<string>(fd.title);
    const [feedbackTitleInputErr, setFeedbackTitleInputErr] = React.useState<boolean>(false);

    // FUNCTION
    const handleUpdateFeedbackTitleClick = async () => {
        if (!newFeedbackTitle || !(newFeedbackTitle.trim())) {
            setFeedbackTitleInputErr(true);
            setTimeout(() => {
                setFeedbackTitleInputErr(false);
            }, 3000);
            return;
        }
        try {
            await dispatch(updateFeedback({
                agendaId: agenda?.id,
                topicId: topic?.topic_id,
                value: newFeedbackTitle,
                sectionId: section.section_id,
                meetingId: meeting?.id,
                dataId: fd.id,
                feedbackId: fd?.feedbackId
            }));
            setInEditMode(false)
        } catch (err) {
            console.log(err, 'error')
            setInEditMode(false)
        }
    }

    const handleReaction = async (reaction: string) => {
        console.log(reaction);
        await dispatch(updateFeedbackReactions({
            sectionId: section.section_id,
            topicId: topic?.topic_id,
            reactionType: reaction,
            userId: user.id,
            meetingId: meeting?.id,
            feedbackMessageId: fd.id,
            feedbackId: fd?.feedbackId
        }));

    }

    return (
        <Fade in={true} timeout={1500}>
        <div className="row cust-feedback-main">
            <div className="col-12 feedback-header">
                <div className="row">
                    <div className="col-auto px-0 d-none">
                        <span className="drag-icon"><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-first" /><Icon path={mdiDotsHorizontal} size={1} className="c-light-gray icon-second" /></span>
                    </div>

                    <div className="col-auto avtar-with-name">
                        <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 25, height: 25, fontSize: 12, bgcolor: userDetails?.backgroundColor }} />
                        {/* <span className="d-inline-flex ml-2 f-14 ">{userDetails?.first_name + ' ' + userDetails?.last_name}</span> */}
                    </div>
                    <div className="col-auto d-inline-flex pl-1">
                        <Typography className="time-status fid-time-status">
                            <Icon
                                path={mdiCircleSmall}
                                size={1}
                                className="c-dark"
                            />
                            <span>{new Date(topic?.date_created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) ?? ''}</span>{" "}
                            {/* <span>{new Date(topic?.date_created).toLocaleTimeString() ?? ''}</span>{" "} */}
                            <Icon
                                path={mdiCircleSmall}
                                size={1}
                                className="c-dark"
                            />
                        </Typography>
                    </div>
                    <div className="col text-right">
                        <ReactionButton handleReaction={handleReaction} fd={fd} reactionName="Celebrate" iconUrl="images/1.png" isDisable={topic?.status == 'Completed' ? true : false}></ReactionButton>
                        <ReactionButton handleReaction={handleReaction} fd={fd} reactionName="Love" iconUrl="images/3.png" isDisable={topic?.status == 'Completed' ? true : false}></ReactionButton>
                        <ReactionButton handleReaction={handleReaction} fd={fd} reactionName="Favorite" iconUrl="images/2.png" isDisable={topic?.status == 'Completed' ? true : false}></ReactionButton>
                        <ReactionButton handleReaction={handleReaction} fd={fd} reactionName="Agree" iconUrl="images/4.png" isDisable={topic?.status == 'Completed' ? true : false}></ReactionButton>
                        <ReactionButton handleReaction={handleReaction} fd={fd} reactionName="Question" iconUrl="images/5.png" isDisable={topic?.status == 'Completed' ? true : false}></ReactionButton>
                    </div>
                    {
                        fd.created_by_user_id === user.id && topic?.status != 'Completed' &&
                        <div className="col-auto">
                            {!inEditMode && <OptionsMenu setInEditMode={setInEditMode}
                                feedback={fd}
                                section={section} topic={topic}
                                type={'FEEDBACK'} />}
                        </div>
                    }
                
                </div>
            </div>
            {
                !inEditMode ?
                    <div className="col-12 feedback-body">
                        <div className="row">
                            <div className="col-12">
                                <p className="m-0 f-14">
                                    {fd.title}
                                </p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="col-12 feedback-body">
                        <div className="row">
                            <div className="col-12 mt-2">

                                <TextField
                                    error={feedbackTitleInputErr}
                                    id="outlined-size-small"
                                    defaultValue="Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?"
                                    size="small"
                                    value={newFeedbackTitle}
                                    onChange={(e) => setNewFeedbackTitle(e.target.value)}
                                    fullWidth
                                    className="input-bg-white"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                            handleUpdateFeedbackTitleClick();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 text-right my-2 px-0">
                            <Button variant="outlined" size="small" className="mr-2 btn-outline-white" onClick={() => setInEditMode(false)}>Cancel</Button>
                            <Button variant="contained" onClick={handleUpdateFeedbackTitleClick} size="small">Save</Button>
                        </div>

                    </div>}





            {/* <div className="col-12 feedback-footer">
                <div className="row align-items-center">
                    
                    <div className="col-auto">
                        <span className="f-14">{new Date(fd?.date_created).toLocaleTimeString() ?? ''}</span>
                    </div>
                </div>
            </div> */}
            
        </div></Fade>)
}

export default FeedbackBar;