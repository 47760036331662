import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './PrivateEveryoneBlueSelect.css'
import { auto } from '@popperjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateActionPrivacyType } from '../../../store/slices/MeetingSlice';

interface SelectSmallProps {
  onSelect: (value: string) => void; // Define a callback prop
}

export default function PrivateEveryoneBlueSelect(props: any, { onSelect }: SelectSmallProps) {
  const [privacy, setPrivacy] = React.useState('');
  const { action, topic, section } = props;
  const dispatch = useDispatch();
  const { agenda } = useSelector((s: any) => s.meeting.meeting);
  const { meeting } = useSelector((s: any) => (s.meeting));
  React.useEffect(() => {
    setPrivacy(action?.type)
    console.log(action?.type, 'useEffect')
  }, [action])

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setPrivacy(event.target.value);
    await dispatch(updateActionPrivacyType({
      agendaId: agenda?.id,
      topicId: topic?.topic_id,
      value: selectedValue,
      sectionId: section.section_id,
      meetingId: meeting?.id,
      dataId: action.id,
      actionId: action?.actionId
    }));
    // onSelect(selectedValue);
  };

  return (
    <div className="select-btn-options">
      <FormControl sx={{ minWidth: auto }} size="small" className="select-btn">
        <Select
          disabled={topic?.status == 'Completed' ? true : false}
          value={privacy}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value="EVERYONE">Everyone</MenuItem>
          <MenuItem value="ONLY_ME">Private</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}