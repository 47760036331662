import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './DecisionsDropdown.css'
import { auto } from '@popperjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateDecisionStatus } from '../../store/slices/MeetingSlice';

export default function DecisionsDropdown(props: any) {
  const [status, setStatus] = React.useState(' ');
  const { decision, topic, section } = props;
  const dispatch = useDispatch();
  const { agenda } = useSelector((s: any) => s.meeting.meeting);
  const { meeting } = useSelector((s: any) => (s.meeting));

  React.useEffect(() => {
    setStatus(decision?.status)
    console.log(decision?.status, 'useEffect')
  }, [decision])

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string;
    setStatus(event.target.value);
    await dispatch(updateDecisionStatus({
      agendaId: agenda?.id,
      topicId: topic?.topic_id,
      value: selectedValue,
      sectionId: section.section_id,
      meetingId: meeting?.id,
      dataId: decision.id,
      decisionId: decision?.decisionId
    }));
  };



  return (
    <div className="select-btn-options">
      <FormControl sx={{ minWidth: auto }} size="small" className="select-btn">
        <Select
          disabled={topic?.status == 'Completed' || decision?.status == 'COMPLETED' ? true : false}
          value={status}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {/* <MenuItem value=" ">
            Select
          </MenuItem> */}
          <MenuItem className="open" value='OPEN'>Open</MenuItem>
          <MenuItem className="completed" value='COMPLETED'>Completed</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}