import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Container from '@mui/material/Container';
import './FeedBack.css';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '@mui/material/Avatar';
import { mdiChevronDoubleRight, mdiHandBackRight, mdiHandBackRightOutline, mdiThumbUpOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { mdiMenuUp } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';
import Button from '@mui/material/Button';
import { mdiChevronDoubleLeft } from '@mdi/js';
import FeedbackTabs from './FeedbackTabs';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { updateRaiseHand, voteUp, updateTopicSpecificData, updateSnackBarState } from '../../store/slices/MeetingSlice';
import LikeOptionMenu from '../Home/Tabs/LikeOptionMenu';
import Tooltip from '@mui/material/Tooltip';
import { useEffect } from 'react';



const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const stringAvatar = (name: string) => {
  return {
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

export default function FeedBack() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorPopUp, setAnchorPopUp] = React.useState<null | HTMLElement>(null);
  const openModel = Boolean(anchorEl);

  const topicId = location.state?.topicId;
  const sectionId = location.state?.sectionId;

  // STORE
  const { agenda } = useSelector((s: any) => s.meeting.meeting);
  const user = useSelector((s: any) => s.meeting.user);
  const { meeting } = useSelector((s: any) => (s.meeting));
  const navigate = useNavigate();


  // USE EFFECT
  useEffect(() => {
    dispatch(updateSnackBarState(0));
  }, []);

  const { topicData, sectionData, hasActiveTopic } = React.useMemo(() => {
    const sec = agenda?.section?.find((sc: any) => sc.section_id == sectionId);
    const result = sec?.topics?.find((tp: any) => tp.topic_id == topicId);
    const hasActive = sec?.topics?.some((tp: any) => (tp?.status != 'Started' && tp?.status != 'Completed'));

    console.log('>>>>', { topicData: result, sectionData: sec })
    return { topicData: result, sectionData: sec, hasActiveTopic: hasActive };
  }, [agenda?.section])

  const handleClose = () => {
    setAnchorPopUp(null);
    setAnchorEl(null);
  };

  const handleClick = (event: any) => {
    if (event.target.outerText.includes('+')) {
      setAnchorEl(event.currentTarget);
    }
  };

  const createNewRaisedHandUser = (tp: any) => {
    if (tp?.raise_hand?.length > 0) {
      const raisedHand = tp?.raise_hand?.find((data: any) => data.user_id === user.id);
      if (raisedHand) {
        return {
          ...raisedHand,
          status: raisedHand.status === "Inactive" ? "Active" : "Inactive"
        };
      }
    }
    return {
      "user_id": user.id,
      "first_name": user.first_name,
      "last_name": user.last_name,
      "ParticipantScreenName": user.first_name,
      "pic": user?.zoom_pic_url,
      "date_created": "2018-10-31T04:32:37Z",
      "status": "Active"
    }
  }

  const createNewVoteUser = () => {
    return {
      "user_id": user.id,
      "first_name": user.first_name,
      "last_name": user.last_name,
      "ParticipantScreenName": user.first_name,
      "pic": user?.zoom_pic_url,
      "vote_type": "Up",
      "date_created": "2018-10-31T04:32:37Z"

    }
  }

  const handleVoteUp = async (tp: any) => {
    const newUser: any = createNewVoteUser();
    await dispatch(voteUp({ agendaId: agenda.id, sectionId: sectionId, topicId: tp.topic_id, user: newUser, meetingId: meeting.id }));
  }


  const handleRaiseHand = async (tp: any) => {
    const newUser: any = createNewRaisedHandUser(tp);
    await dispatch(updateRaiseHand({ agendaId: agenda.id, sectionId: sectionId, topicId: tp.topic_id, user: newUser, meetingId: meeting.id }));
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleEndTopic = async () => {
    await dispatch(updateTopicSpecificData({ agendaId: agenda.id, sectionId: sectionId, topicId: topicId, updateData: { key: 'UPDATE_ENDTIME', value: new Date().toISOString() }, meetingId: meeting.id }));
    navigate("/home");
  }

  const handleVoteCountClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPopUp(event.currentTarget);
  };

  return (
    <Container maxWidth="lg" className="feedback-main">
      <Box sx={{ display: 'flex' }} className={hasActiveTopic ? '' : 'pad-l-0'} >
        <CssBaseline />
        {hasActiveTopic && false &&
          <Drawer variant="permanent" open={open}>
            <div className="row drawer-btn-main">
              <div className="col text-right px-4">
                {open && <div className="next-up px-2"><h3 className="m-0 pt-1">Next Up</h3></div>}
                <Button onClick={open ? handleDrawerClose : handleDrawerOpen} className="drawer-btn" size="small" variant="contained" sx={{ boxShadow: 1 }} ><Icon path={open ? mdiChevronDoubleLeft : mdiChevronDoubleRight} size={0.8} className="mr-1" /></Button>
              </div>
            </div>
            {open && <div className="row left-drawer-open">
              {
                sectionData?.topics && sectionData?.topics.map((tp: any) => {
                  if (tp?.status != 'Started' && tp?.status != 'Completed') {
                    return (
                      <div className="col-auto left-card-main">
                        <div className="row align-items-center">
                          <div className="col-12 px-2">
                            <Tooltip title={tp.name}>
                              <p className="f-14">{tp.name}</p>
                            </Tooltip>
                          </div>
                          <div className="col-auto d-inline-flex py-2 py-md-3 align-items-center">
                            <a onClick={() => { handleRaiseHand(tp) }} className="hand-icon mr-2"><Icon path={mdiHandBackRightOutline} size={1} /></a>
                            {tp?.raise_hand &&
                              <AvatarGroup onClick={handleClick} max={4}>
                                {tp.raise_hand?.map((rh: any) => {
                                  if (rh.status === 'Active') {
                                    let userDetails = meeting.participants.find((pt: any) => pt.user_id === rh.user_id)
                                    return <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 30, height: 30, fontSize: 14, bgcolor: userDetails?.backgroundColor }} />
                                  }
                                })}
                              </AvatarGroup>
                            }

                          </div>
                          <div className="col-12 left-card-bottom">
                            <div onClick={() => { handleVoteUp(tp) }} style={{ cursor: 'pointer' }}> <Icon className="arrow-up-count-card" path={mdiMenuUp} size={1} />
                            </div>
                            <LikeOptionMenu topic={tp} />
                            {/* <span className="count" onClick={handleVoteCountClick}>{tp?.vote?.reduce((a: any, cv: any) => a + cv.count, 0) ?? 0}</span> */}
                          </div>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            }
            {!open && <div className="row left-drawer-close">
              <div className="col-12">
                {
                  sectionData?.topics && sectionData?.topics.map((tp: any) => {
                    if (tp?.status != 'Started' && tp?.status != 'Completed') {
                      return (
                        <div className="row">
                          <div className="col-auto left-card-small">
                            <p className="vote-count">{tp?.vote?.reduce((a: any, cv: any) => a + cv.count, 0) ?? 0}</p>
                            <p className="vote-text">Vote</p>
                          </div>
                        </div>
                      )
                    }
                  })
                }

              </div>
            </div>
            }
          </Drawer>
        }
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <div className="row align-items-center big-avtar-row-mob">
            <div className="col d-inline-flex align-items-center">
              <a onClick={() => { handleRaiseHand(topicData) }} className={topicData?.status == 'Completed' ? 'hand-icon hand-icon-big-mob align-items-center mr-2 disabled' : 'hand-icon hand-icon-big-mob align-items-center mr-2'}><Icon path={mdiHandBackRightOutline} size={1} /></a>
              {topicData?.raise_hand &&
                <AvatarGroup onClick={handleClick} max={4}>
                  {topicData?.raise_hand?.map((rh: any) => {
                    if (rh.status === 'Active') {
                      let userDetails = meeting.participants.find((pt: any) => pt.user_id === rh.user_id)
                      return <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 30, height: 30, fontSize: 14, bgcolor: userDetails?.backgroundColor }} />
                    }
                  })}
                </AvatarGroup>
              }
            </div>
            {topicData?.status == "Started" && <div className="col text-right">
              <Button variant="contained" className="btn-yellow" onClick={handleEndTopic}>End Topic</Button>
            </div>}
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openModel}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose} className="like-menu-main">
              <div className="row like-dislike-menu-header c">
                <div className="col d-inline-flex align-items-center like-main"><a href="#" className="hand-icon mr-3"><Icon path={true ? mdiHandBackRightOutline : mdiHandBackRight} size={1} /></a> <strong></strong></div>
              </div>
              <div className="row like-dislike-menu-body">
                <div className="col-12">
                  {/* {topic?.raise_hand?.map((vt: any, i: number) => {
                    if (vt.status === 'Active') {
                      let userDetails = meeting?.participants?.find((pt: any) => pt.user_id === vt.user_id)
                      return (<div className="row align-items-center mb-3" key={'menu_option_' + i}>
                        <div className="col d-inline-flex align-items-center">
                          <Avatar alt={userDetails?.first_name + ' ' + userDetails?.last_name} {...userDetails?.avatarText} src={userDetails?.pic_url} sx={{ width: 25, height: 25, fontSize: 12, bgcolor: userDetails?.backgroundColor }} /> <span className="d-inline-flex ml-2 f-14">{userDetails?.first_name + ' ' + userDetails?.last_name}</span>
                        </div>
                      </div>);
                    }
                  })} */}
                </div>
              </div>
            </MenuItem>
          </Menu>



          <div className="row mt-4">
            <div className="col-12">
              <FeedbackTabs open={open} topicData={topicData} hasActiveTopic={hasActiveTopic} />
            </div>
          </div>
        </Box>
      </Box>
    </Container>
  );
}