import React from 'react'
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import { useSelector } from 'react-redux';

const ReactionButton = (props: any) => {
    const { handleReaction, fd, reactionName, iconUrl, isDisable } = props;
    const { meeting } = useSelector((s: any) => (s.meeting));
    const user = useSelector((s: any) => s.meeting.user);
    console.log(user, 'fd')
    //popHover
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    return (
        <>
            <Button disabled={isDisable}  className="btn-emoji" onClick={() => { handleReaction(reactionName) }} aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                variant="contained"
                style={{cursor: 'pointer'}}
                size="small"
                startIcon={
                    <img src={iconUrl} width="18px" />
                } ><span className="px-1 px-md-1">+{fd?.reactions?.find((react: any) => react.reaction_type == reactionName)?.created_by_user_id?.length ?? '0'}</span>
            </Button>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >

                <div className="row emoji-popover">
                    <div className="col-12 icon">
                        <img src={iconUrl} />
                    </div>
                    <div className="col-12">
                        <p className="my-2"><strong>{reactionName}</strong></p>
                        <p className="m-0 names f-14">
                            {fd?.reactions?.map((react: any) => {
                                if (react.reaction_type === reactionName) {
                                    const userNames: string[] = [];

                                    react?.created_by_user_id?.forEach((id: any) => {
                                        let userDetails = meeting?.participants?.find((pt: any) => pt.user_id === id);
                                        if (userDetails) {
                                            // const fullName = `${userDetails.first_name} ${userDetails.last_name}`;
                                            // if (id !== currentUserID) { // Replace currentUserID with the ID of the logged-in user
                                            //     userFullNames.push(fullName);
                                            // }
                                            userNames.push(`${userDetails.first_name} ${userDetails.last_name}`);
                                        }
                                    });

                                    const isCurrentUserIncluded = react.created_by_user_id?.includes(user?.id);

                                    const allUserNames = userNames.join(', ');

                                    if (allUserNames) {
                                        if (isCurrentUserIncluded) {
                                            return (
                                                <span key={react.reaction_id}>
                                                    {allUserNames}
                                                    {/* {' and '}
                                                    <strong>You</strong> */}
                                                </span>
                                            );
                                        } else {
                                            return <span key={react.reaction_id}>{allUserNames}</span>;
                                        }
                                    }
                                }

                                return null; // Return null if the reaction_type doesn't match or there are no user names
                            })}
                        </p>
                    </div>
                </div>

            </Popover>
        </>
    )
}

export default ReactionButton