import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import './Home.css'
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import './TabPreview.css'
import TabPreview from './TabPreview';
import { useDispatch } from 'react-redux';
import { updateSnackBarState } from '../../store/slices/MeetingSlice';


export default function StartPagePrivew() {
    const dispatch = useDispatch();

    // USE EFFECT
    useEffect(() => {
        dispatch(updateSnackBarState(5));
    }, []);

    const Item = styled(Paper)(({ theme }) => ({
        // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        // ...theme.typography.body2,
        // padding: theme.spacing(1),
        // textAlign: 'center',
        // color: theme.palette.text.secondary,
    }));

    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <TabPreview />
                <Container maxWidth="lg">
                    <div className="row">
                        <div className="col-12">
                            {/* <StartPagePrivewAccordion /> */}
                        </div>
                    </div>
                </Container>

            </Box>
        </div>
    )
}
