import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from '../../config';
import { stringAvatar, stringToColor } from '../../service/utils';

// TYPES


export type initialState = {
    type: '',
    isOpen: false,
    message: '',
};

const meetingInitialState: any = {
    meeting: {
        meeting_topic: "",
        meeting_uuid: "",
        duration: "",
        start_time: "",
        zoom_meeting_id: 0,
        join_url: "",
        host_user_id: "",
        date_created: "",
        date_modified: "",
        status: '',
        agenda: {
            meeting_id: '',
            section: [],
            status: '',
            created_by_user_id: '',
            last_updated_by_user_id: '',
            date_created: '',
            date_modified: ''
        },
        participants: [],
        isAuthorised: true
    },
    filterBy: false,
    filterByActionType: false,
    filterByDecisionType: false,
    snackBarState: 0,
    user: {},
    loading: false,
    snackBar: {
        type: '',
        isOpen: false,
        message: ''
    },
    showEndMeetingButton: true,
    socketData: ''
};

export type User = {
    participant_id: Number,
    user_id: string,
    screen_name: string,
    first_name: string,
    last_name: string,
    pic_url: string,
    avatarText?: any,
    backgroundColor?: string
};

export type Topic = {
    created_by_user_id: string;
    date_created: string;
    display_order: string;
    display_order_last_updated_by_user_id: string;
    duration: number;
    duration_updated_by_user_id: string;
    name: string;
    raise_hand: any;
    speaker: string;
    status: string;
    topic_id: string;
    vote: any[];
    feedback: any[];
}

export type Speaker = {
    ParticipantScreenName: string;
    first_name: string;
    last_name: string;
    pic: string;
    user_id: string;
}

export type Section = {
    created_by_user_id: string;
    date_created?: string;
    display_order: string;
    display_order_last_updated_by_user_id: string;
    duration: number;
    duration_updated_by_user_id: string;
    name: string;
    section_id: number;
    speaker: User | {};
    status: string;
    topics: Topic[];
}

export type Agenda = {
    agenda_id?: number | undefined;
    created_by_user_id: string;
    date_created?: string;
    date_modified: string;
    last_updated_by_user_id: string;
    meeting_id: string;
    section: Section[];
    status: string;
}

export const addTopic: any = createAsyncThunk("meeting/addTopic", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topic: data.topic
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/add/topic`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const removeTopic: any = createAsyncThunk("meeting/removeTopic", async (data: any) => {
    let requestData = {
        meetingId: data.meetingId,
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        topicId: data.topicId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/delete/topic`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };

    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateTopic: any = createAsyncThunk("meeting/updateTopic", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        topicId: data.topicId,
        userId: data.userId,
        value: data.value,
        meetingId: data.meetingId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/topic`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };

    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const createAgenda: any = createAsyncThunk("meeting/createAgenda", async (data: any) => {
    let requestData = {
        agenda: data.agenda,
        meetingId: data.meetingId,
        userId: data.userId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/create`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateRaiseHand: any = createAsyncThunk("meeting/section/raisehand", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        topicId: data.topicId,
        meetingId: data.meetingId,
        user: { ...data.user }
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/raisehand`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response;
    return result;
});

export const voteUp: any = createAsyncThunk("meeting/section/vote", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        topicId: data.topicId,
        meetingId: data.meetingId,
        user: { ...data.user }
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/vote`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response;
    return result;
});

export const fetchAgenda: any = createAsyncThunk("meeting/fetchAgenda", async (data: any) => {
    var options = {
        method: 'GET',
        url: `${API_URL}agenda/get?id=${data.agendaId}`,
        headers: {}
    };
    const response = await axios(options);
    const agenda = await response.data;
    return agenda;
});

export const fetchFeedback: any = createAsyncThunk("meeting/topic/fetchFeedback", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        topicId: data.topicId,
        sectionId: data.sectionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}feedback/get`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const fetchAction: any = createAsyncThunk("meeting/topic/fetchAction", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        topicId: data.topicId,
        sectionId: data.sectionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}action/get`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const fetchDecision: any = createAsyncThunk("meeting/topic/fetchDecision", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        topicId: data.topicId,
        sectionId: data.sectionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/get`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateAgenda: any = createAsyncThunk("meeting/updateAgenda", async (agenda: any) => {
    let requestData = {
        agendaId: agenda.id,
        data: { ...agenda }
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const fetchMeeting: any = createAsyncThunk("meeting/fetchMeeting", async (data: any) => {
    var options = {
        method: 'GET',
        url: `${API_URL}meeting/get?id=${data.meetingId}`,
        headers: {}
    };
    const response = await axios(options);
    const agenda = await response.data;
    return agenda;
});

export const startMeeting: any = createAsyncThunk("meeting/startMeeting", async (data: any) => {
    var options = {
        method: 'GET',
        url: `${API_URL}meeting/startmeeting?meetingId=${data.meetingId}&&agendaId=${data.agendaId}&&userId=${data.userId}`,
        headers: {}
    };
    const response = await axios(options);
    const agenda = await response.data;
    return agenda;
});

export const updateSection: any = createAsyncThunk("meeting/updateSection", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        userId: data.userId,
        value: data.value
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/sectionname`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateSectionTime: any = createAsyncThunk("meeting/updateSectionTime", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        userId: data.userId,
        value: data.value
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/sectiontime`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const addAgendaSection: any = createAsyncThunk("meeting/AddSection", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        section: { ...data.section },
        meetingId: data.meetingId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/add/section`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const endMeetingAndSendEmail: any = createAsyncThunk("meeting/EndMeetingAndSendEmail", async (data: any) => {
    let requestData = { ...data }

    let options = {
        method: 'POST',
        url: `${API_URL}sendemail`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const deleteAgendaSection: any = createAsyncThunk("meeting/deleteSection", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/delete/section`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});
export const deleteFeedback: any = createAsyncThunk("meeting/deleteFeedback", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        feedback: data.feedback,
        topicId: data.topicId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}feedback/delete/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const deleteAction: any = createAsyncThunk("meeting/deleteAction", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        action: data.action,
        topicId: data.topicId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}action/delete/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const deleteDecision: any = createAsyncThunk("meeting/deleteDecision", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        decision: data.decision,
        topicId: data.topicId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/delete/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateSpeakerDetails: any = createAsyncThunk("meeting/deleteSection", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        userId: data.userId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/speaker`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateActionOwnerDetails: any = createAsyncThunk("meeting/updateActionOwnerDetails", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        userId: data.userId,
        dataId: data.dataId,
        actionId: data.actionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}action/update/decision/owner`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecisionOwnerDetails: any = createAsyncThunk("meeting/updateActionOwnerDetails", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        userId: data.userId,
        dataId: data.dataId,
        decisionId: data.decisionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/update/decision/owner`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecisionFinalMessage: any = createAsyncThunk("meeting/updateDecisionFinalMessage", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        finalMessage: data.finalMessage,
        dataId: data.dataId,
        decisionId: data.decisionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/update/final/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const createFeedback: any = createAsyncThunk("meeting/createFeedback", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        feedback: data.feedback
    }

    let options = {
        method: 'POST',
        url: `${API_URL}feedback/addnew/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const createAction: any = createAsyncThunk("meeting/createAction", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        action: data.action
    }
    let options = {
        method: 'POST',
        url: `${API_URL}action/create`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const createDecision: any = createAsyncThunk("meeting/createDecision", async (data: any) => {
    let requestData = {
        agendaId: data.agendaId,
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data?.topicId,
        decision: data.decision
    }
    let options = {
        method: 'POST',
        url: `${API_URL}decision/create`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateSectionOrder: any = createAsyncThunk("meeting/updateSectionOrder", async (data: any) => {
    let requestData = {
        meetingId: data.meetingId,
        userId: data.userId,
        sectionsArray: data.sectionsArray,
        agendaId: data.agendaId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/section/sort`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateActionsOrder: any = createAsyncThunk("meeting/updateActionsOrder", async (data: any) => {
    let requestData = {
        meetingId: data.meetingId,
        // userId: data.userId,
        actionsArray: data.actionsArray,
        agendaId: data.agendaId,
        topicId: data.topicId,
        sectionId: data.sectionId
    }


    let options = {
        method: 'POST',
        url: `${API_URL}action/sort`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecisionsOrder: any = createAsyncThunk("meeting/updateDecisionsOrder", async (data: any) => {
    let requestData = {
        meetingId: data.meetingId,
        // userId: data.userId,
        decisionsArray: data.decisionsArray,
        agendaId: data.agendaId,
        topicId: data.topicId,
        sectionId: data.sectionId
    }


    let options = {
        method: 'POST',
        url: `${API_URL}decision/sort`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateTopicSpecificData: any = createAsyncThunk("meeting/updateTopicSpecificData", async (data: any) => {
    let requestData = {
        meetingId: data.meetingId,
        userId: data.userId,
        sectionId: data.sectionId,
        agendaId: data.agendaId,
        topicId: data.topicId,
        data: {
            ...data.updateData
        }
    }

    // "data":{
    //     "key":"UPDATE_STARTTIME",
    //     "value":"2023-08-10T10:04:55Z"
    // },

    let options = {
        method: 'POST',
        url: `${API_URL}agenda/update/topic/details`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateFeedback: any = createAsyncThunk("meeting/updateFeedbackMessage", async (data: any) => {
    let requestData = {
        topicId: data.topicId,
        value: data.value,
        agendaId: data.agendaId,
        meetingId: data.meetingId,
        dataId: data.dataId,
        sectionId: data.sectionId,
        feedbackId: data.feedbackId
    }


    let options = {
        method: 'POST',
        url: `${API_URL}feedback/update/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateAction: any = createAsyncThunk("meeting/updateActionTitle", async (data: any) => {
    let requestData = {
        topicId: data.topicId,
        value: data.value,
        agendaId: data.agendaId,
        meetingId: data.meetingId,
        dataId: data.dataId,
        sectionId: data.sectionId,
        actionId: data.actionId
    }


    let options = {
        method: 'POST',
        url: `${API_URL}action/update/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecision: any = createAsyncThunk("meeting/updateActionTitle", async (data: any) => {
    let requestData = {
        topicId: data.topicId,
        value: data.value,
        agendaId: data.agendaId,
        meetingId: data.meetingId,
        dataId: data.dataId,
        sectionId: data.sectionId,
        decisionId: data.decisionId
    }


    let options = {
        method: 'POST',
        url: `${API_URL}decision/update/message`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateActionPrivacyType: any = createAsyncThunk("meeting/updateActionPrivacyType", async (data: any) => {
    let requestData = {
        topicId: data.topicId,
        value: data.value,
        agendaId: data.agendaId,
        meetingId: data.meetingId,
        dataId: data.dataId,
        sectionId: data.sectionId,
        actionId: data.actionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}action/update/privacy/type`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecisionStatus: any = createAsyncThunk("meeting/updateDecisionStatus", async (data: any) => {
    let requestData = {
        topicId: data.topicId,
        value: data.value,
        agendaId: data.agendaId,
        meetingId: data.meetingId,
        dataId: data.dataId,
        sectionId: data.sectionId,
        decisionId: data.decisionId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/update/type`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateFeedbackReactions: any = createAsyncThunk("meeting/updateFeedbackReactions", async (data: any) => {
    let requestData = {
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data.topicId,
        userId: data.userId,
        feedbackId: data.feedbackId,
        reactionType: data.reactionType,
        messageId: data.feedbackMessageId
    }

    let options = {
        method: 'POST',
        url: `${API_URL}feedback/update/reactions`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const updateDecisionReactions: any = createAsyncThunk("meeting/updateDecisionReactions", async (data: any) => {
    let requestData = {
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        topicId: data.topicId,
        userId: data.userId,
        decisionId: data.decisionId,
        messageId: data.decisionMessageId,
        reactionType: data.reactionType,
    }

    let options = {
        method: 'POST',
        url: `${API_URL}decision/update/reactions`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

export const CompleteSectionAndAllTopics: any = createAsyncThunk("meeting/completeSection", async (data: any) => {
    let requestData = {
        sectionId: data.sectionId,
        meetingId: data.meetingId,
        agendaId: data.agendaId,
        userId: data.userId,
    }
    let options = {
        method: 'POST',
        url: `${API_URL}agenda/complete/section`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: requestData
    };
    const response = await axios(options);
    const result = await response.data;
    return result;
});

const MeetingSlice = createSlice({
    name: 'meeting',
    initialState: meetingInitialState,
    reducers: {
        initialize(state, action) {
            return { ...state, meeting: { ...state.meeting, ...action.payload.meeting, participants: [...action.payload.meeting.participants.map((et: any) => ({ ...et, avatarText: stringAvatar(et ? et.first_name + ' ' + et.last_name : ''), backgroundColor: stringToColor(et.first_name ?? '') }))] }, user: { ...state.user, ...action.payload.user } }
        },
        snackBarReducer(state, action) {
            switch (action?.payload?.type) {
                case 'OPEN_SNACK_BAR':
                    return {
                        ...state,
                        snackBar: {
                            ...state.snackBar,
                            type: action.payload.type,
                            isOpen: true,
                            message: action.payload.message,
                        },
                    };
                case 'CLOSE_SNACK_BAR':
                    return {
                        ...state,
                        snackBar: {
                            ...state.snackBar,
                            isOpen: false,
                        },
                    };
                default:
                    return state;
            }
        },
        updateFilter(state, action) {
            return { ...state, filterBy: action.payload };
        },
        updateActionTypeFilter(state, action) {
            return { ...state, filterByActionType: action.payload };
        },
        isAuthorised(state, action) {
            return { ...state, meeting: { ...state.meeting, isAuthorised: false } };
        },
        updateSnackBarState(state, action) {
            return { ...state, snackBarState: action.payload };
        },
        updateDecisionTypeFilter(state, action) {
            return { ...state, filterByDecisionType: action.payload };
        },
        pubsubUpdateAgenda(state, action) {
            return { ...state, meeting: { ...state.meeting, agenda: { ...state.meeting.agenda, ...action.payload.agenda } } }
        },
        pubsubAddParticipant(state, action) {
            const exists = state.meeting.participants.find((et: any) => (et.user_id === action.payload.newParticipant.user_id));
            if (!exists) {
                const stringAvatarText = stringAvatar(action.payload.newParticipant ? action.payload.newParticipant.first_name + ' ' + action.payload.newParticipant.last_name : '');
                return { ...state, meeting: { ...state.meeting, participants: ([...state.meeting.participants, { ...action.payload.newParticipant, avatarText: stringAvatarText, backgroundColor: stringToColor(action.payload.newParticipant.first_name ?? '') }] as any) } };
            }
        },
        pubsubAddNewTopic(state, action) {
            const topic = action.payload.topicData.topic;
            const sectionId = action.payload.topicData.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return { ...s, topics: [...s.topics, { ...topic }] }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubRemoveTopic(state, action) {
            const topicId = action.payload.topicData.topicId;
            const sectionId = action.payload.topicData.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return { ...s, topics: [...s.topics?.filter((tp: any) => tp.topic_id !== topicId)] }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubUpdateTopic(state, action) {
            const topic = action.payload.topicData.topic;
            const sectionId = action.payload.topicData.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics.map((tp: any) => {
                                        if (tp.topic_id == topic.topic_id)
                                            return { ...tp, name: topic.name };
                                        else
                                            return { ...tp };
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubnewHandRaised(state, action) {
            const user = action.payload.newHandRaised.user;
            const sectionId = action.payload.newHandRaised.sectionId;
            const topicId = action.payload.newHandRaised.topicId;

            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                const newUser = tp.raise_hand ? tp.raise_hand?.find((v: any) => v.user_id == user.user_id) : false;
                                let newRaiseHand = tp.raise_hand?.map((rh: any) => {
                                    if (rh.user_id == user.user_id)
                                        return { ...user };
                                    else
                                        return { ...rh };
                                })
                                if (!newUser && newRaiseHand)
                                    newRaiseHand.push({ ...user });
                                return {
                                    ...tp,
                                    raise_hand: tp.raise_hand ? [...newRaiseHand] : [{ ...user }]
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubVoteUp(state, action) {
            const user = action.payload.voteUp.user;
            const sectionId = action.payload.voteUp.sectionId;
            const topicId = action.payload.voteUp.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et,
                        topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                const newUser = tp.vote ? tp.vote?.find((v: any) => v.user_id == user.user_id) : false;

                                let voteSection = tp.vote?.map((v: any) => {
                                    if (v.user_id == user.user_id)
                                        return { ...v, count: ((v?.count ?? 0) + 1) }
                                    else
                                        return { ...v }
                                });

                                if (!newUser && voteSection)
                                    voteSection.push({ ...user, count: 1 });

                                const updatedTp = {
                                    ...tp,
                                    vote: tp.vote ? [...voteSection] : [{ ...user, count: 1 }]
                                };

                                return updatedTp;
                            }
                            else
                                return tp;
                        })].sort((a: any, b: any) => {
                            const aTotalVotes = a.vote ? a.vote.reduce((acc: number, v: any) => acc + (v.count ?? 0), 0) : 0;
                            const bTotalVotes = b.vote ? b.vote.reduce((acc: number, v: any) => acc + (v.count ?? 0), 0) : 0;
                            return bTotalVotes - aTotalVotes;
                        })
                    };
                else
                    return et;
            })]
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateSection(state, action) {
            const value = action.payload.sectionData.value;
            const sectionId = action.payload.sectionData.sectionId;
            let section = state.meeting.agenda.section.map((sec: any) => {
                if (sec.section_id === sectionId) {
                    return {
                        ...sec,
                        name: value
                    }
                } else {
                    return sec
                }
            })

            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: section
                    }
                }
            }
        },
        pubsubAddNewSection(state, action) {
            const section = action.payload.sectionData;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section, { ...section, showNewTag: true }]
                    }
                }
            }
        },
        pubsubDeleteSection(state, action) {
            const sectionId = action.payload.sectionData.sectionId;
            const updatedSections = state.meeting.agenda.section.filter((sec: any) => sec.section_id !== sectionId);
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: updatedSections
                    }
                }
            };
        },
        pubsubUpdateSpeakerDetails(state, action) {
            const sectionId = action.payload.speakerData.sectionId;
            const topicId = action.payload.speakerData.topicId;
            const userId = action.payload.speakerData.userId;
            let section = state.meeting.agenda.section.map((sec: any) => {
                if (topicId) {
                    return {
                        ...sec, topics: [...sec.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                return {
                                    ...tp,
                                    speaker: userId
                                }
                            }
                            else
                                return tp;
                        })]
                    }
                } else {
                    if (sec.section_id === sectionId) {
                        return {
                            ...sec,
                            speaker: userId
                        }
                    } else {
                        return sec
                    }
                }
            })

            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: section
                    }
                }
            }
        },
        pubsubUpdateSectionTime(state, action) {
            const sectionId = action.payload.data.sectionId;
            const duration = action.payload.data.value;
            const userId = action.payload.data.userId;
            let section = state.meeting.agenda.section.map((sec: any) => {
                if (sectionId == sec.section_id)
                    return { ...sec, duration: duration, duration_updated_by_user_id: userId }
                else
                    return { ...sec }
            })

            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: section
                    }
                }
            }
        },
        pubsubUpdateSectionOrder(state, action) {
            const sectionsArray = action.payload.data.sectionsArray;
            let newSections = [...sectionsArray.map((sc: any, index: number) => {
                let order = index + 1;
                let section = state?.meeting?.agenda?.section?.find((s: any) => s.section_id == sc)
                return {
                    ...section,
                    display_order: order
                }
            })]

            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSections
                    }
                }
            };
        },
        pubsubUpdateActionOrder(state, action) {
            let actionsArray = action.payload.data.actionsArray;
            let sectionId = action.payload.data.sectionId;
            let topicId = action.payload.data.topicId;

            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let newActions = [...actionsArray.map((sc: any) => {
                                    return { ...tp?.action?.find((s: any) => s.id == sc) }
                                })]
                                return {
                                    ...tp,
                                    action: newActions
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }


        },
        pubsubUpdateDecisionOrder(state, action) {
            let decisionsArray = action.payload.data.decisionsArray;
            let sectionId = action.payload.data.sectionId;
            let topicId = action.payload.data.topicId;

            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let UpdatedDecision = [...decisionsArray.map((sc: any) => {
                                    return { ...tp?.decision?.find((s: any) => s.id == sc) }
                                })]
                                return {
                                    ...tp,
                                    decision: UpdatedDecision
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }


        },
        pubsubAddNewFeedback(state, action) {
            const feedback = action.payload.feedbackData.feedback;
            const sectionId = action.payload.feedbackData.sectionId;
            const topicId = action.payload.feedbackData.topicId;
            const newSectionData = state.meeting.agenda.section.map((et: any) => {
                if (sectionId === et.section_id) {
                    const newTopics = et.topics.map((tp: any) => {
                        if (tp.topic_id === topicId) {
                            const newFeedback = tp.feedback ? [...tp.feedback, feedback] : [feedback];
                            return {
                                ...tp,
                                feedback: newFeedback
                            };
                        } else {
                            return tp;
                        }
                    });

                    return {
                        ...et,
                        topics: newTopics
                    };
                } else {
                    return et;
                }
            });
            const participants = state.meeting.participants;
            console.log(JSON.stringify(participants))
            let user = participants.find((part: any) => part.user_id == feedback.created_by_user_id);
            let message = `New Feedback Added by ${user?.first_name}`;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                },
                snackBar: {
                    type: 'warning',
                    message: `${message}`,
                    isOpen: state.snackBarState == 0 ? false : true,
                }
            };

        },
        pubsubDeleteFeedback(state, action) {
            const feedback = action.payload.feedbackData.feedback;
            const topicId = action.payload.feedbackData.topicId;
            const sectionId = action.payload.feedbackData.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics?.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            let updatedFeedback = tp.feedback.filter((fb: any) => fb.title !== feedback.title);
                                            return {
                                                ...tp, feedback: [...updatedFeedback]
                                            }
                                        } else {
                                            return { ...tp }
                                        }
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubUpdateTopicDetails(state, action) {
            const navigate = action.payload.nav;
            const data = action.payload.data.value;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            if (data?.key === 'UPDATE_ENDTIME') {
                const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                    if (sectionId == et.section_id) {
                        let newSection = {
                            ...et, topics: [...et.topics.map((tp: any) => {
                                if (tp.topic_id == topicId)
                                    return { ...tp, status: 'Completed', end_time: data?.value };
                                else
                                    return { ...tp };
                            })]
                        }
                        let notCompleted = newSection.topics.find((tp: any) => tp.status != 'Completed');
                        if (!notCompleted) {
                            newSection.status = 'Completed';
                        }
                        return newSection;
                    }
                    else
                        return et;
                })];

                const updatedState: any = {
                    ...state,
                    meeting: {
                        ...state.meeting,
                        agenda: {
                            ...state.meeting.agenda,
                            section: newSectionData
                        }
                    }
                }
                navigate('/home');
                return { ...updatedState };
            }
            if (data?.key === 'UPDATE_STARTTIME') {
                const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                    if (sectionId == et.section_id)
                        return {
                            ...et, topics: [...et.topics.map((tp: any) => {
                                if (tp.topic_id == topicId)
                                    return { ...tp, status: 'Started', start_time: data?.value };
                                else
                                    return { ...tp };
                            })]
                        }
                    else
                        return et;
                })];

                const updatedState = {
                    ...state,
                    meeting: {
                        ...state.meeting,
                        agenda: {
                            ...state.meeting.agenda,
                            section: newSectionData
                        }
                    }
                };
                navigate("/feedback", {
                    state: {
                        sectionId: sectionId,
                        topicId: topicId
                    }
                });
                return {
                    ...state,
                    meeting: {
                        ...state.meeting,
                        agenda: {
                            ...state.meeting.agenda,
                            section: newSectionData
                        }
                    }
                };
            }

        },
        pubsubUpdateFeedbackTitle(state, action) {
            const feedback = action.payload.data.feedback;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedFeedback = tp.feedback?.map((fd: any) => {
                                    if (fd.id == feedback.messageId)
                                        return { ...fd, title: feedback.newValue };
                                    else
                                        return { ...fd };
                                })
                                return {
                                    ...tp,
                                    feedback: updatedFeedback
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateActionTitle(state, action) {
            const actionData = action.payload.data.action;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedFeedback = tp.action?.map((ac: any) => {
                                    if (ac.id == actionData.messageId)
                                        return { ...ac, title: actionData.newValue };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    action: updatedFeedback
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateDecisionTitle(state, action) {
            const decisionData = action.payload.data.decision;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedDecision = tp.decision?.map((ac: any) => {
                                    if (ac.id == decisionData.messageId)
                                        return { ...ac, title: decisionData.newValue };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    decision: updatedDecision
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateActionPrivacy(state, action) {
            const actionData = action.payload.data.action;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedFeedback = tp.action?.map((ac: any) => {
                                    if (ac.id == actionData.messageId)
                                        return { ...ac, type: actionData.newValue };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    action: updatedFeedback
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateActionDecisionOwner(state, action) {
            const actionId = action.payload.data.dataId;
            const userId = action.payload.data.userId;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedFeedback = tp.action?.map((ac: any) => {
                                    if (ac.id == actionId)
                                        return { ...ac, assigned_to_user_id: userId };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    action: updatedFeedback
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateDecisionDecisionOwner(state, action) {
            const decisionId = action.payload.data.dataId;
            const userId = action.payload.data.userId;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedDecision = tp.decision?.map((dc: any) => {
                                    if (dc.id == decisionId)
                                        return { ...dc, assigned_to_user_id: userId };
                                    else
                                        return { ...dc };
                                })
                                return {
                                    ...tp,
                                    decision: updatedDecision
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateFeedbackReations(state, action) {
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const userId = action.payload.data.userId;
            const messageId = action.payload.data.messageId;
            const reaction = action.payload.data.reaction;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                return {
                                    ...tp, feedback: [...tp.feedback.map((fd: any) => {
                                        if (fd.id == messageId) {
                                            if (fd.reactions) {
                                                let isExixts = false;
                                                let res = fd.reactions.map((react: any) => {
                                                    if (react.reaction_type == reaction.reaction_type) {
                                                        isExixts = true;
                                                        return { ...react, created_by_user_id: [...reaction.created_by_user_id] }
                                                    } else {
                                                        return { ...react }
                                                    }
                                                })
                                                return {
                                                    ...fd,
                                                    reactions: isExixts ? [...res] : [...res, { ...reaction }]
                                                }
                                            } else {
                                                return {
                                                    ...fd,
                                                    reactions: [reaction]
                                                }
                                            }
                                        } else {
                                            return fd;
                                        }
                                    })]
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }

        },
        pubsubUpdateDecisionReations(state, action) {
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const userId = action.payload.data.userId;
            const messageId = action.payload.data.messageId;
            const reaction = action.payload.data.reaction;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                return {
                                    ...tp, decision: [...tp.decision.map((dec: any) => {
                                        if (dec.id == messageId) {
                                            if (dec.reactions) {
                                                let isExixts = false;
                                                let res = dec.reactions.map((react: any) => {
                                                    if (react.reaction_type == reaction.reaction_type) {
                                                        isExixts = true;
                                                        return { ...react, created_by_user_id: [...reaction.created_by_user_id] }
                                                    } else {
                                                        return { ...react }
                                                    }
                                                })
                                                return {
                                                    ...dec,
                                                    reactions: isExixts ? [...res] : [...res, { ...reaction }]
                                                }
                                            } else {
                                                return {
                                                    ...dec,
                                                    reactions: [reaction]
                                                }
                                            }
                                        } else {
                                            return dec;
                                        }
                                    })]
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }

        },
        pubsubCompleteSection(state, action) {
            const section = action.payload.data.section;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (section.section_id == et.section_id)
                    return { ...section };
                else
                    return et;
            })];
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubAddNewAction(state, action) {
            const actions = action.payload.actionData.action;
            const sectionId = action.payload.actionData.sectionId;
            const topicId = action.payload.actionData.topicId;
            const newSectionData = state.meeting.agenda.section.map((et: any) => {
                if (sectionId === et.section_id) {
                    const newTopics = et.topics.map((tp: any) => {
                        if (tp.topic_id === topicId) {
                            const newAction = tp.action ? [...tp.action, actions] : [actions];
                            return {
                                ...tp,
                                action: newAction
                            };
                        } else {
                            return tp;
                        }
                    });
                    return {
                        ...et,
                        topics: newTopics
                    };
                } else {
                    return et;
                }
            });

            const participants = state.meeting.participants;
            console.log(JSON.stringify(participants))
            let user = participants.find((part: any) => part.user_id == actions.created_by_user_id);
            console.log(user)
            let message = `New Action Added by ${user?.first_name}`;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                },
                snackBar: {
                    type: 'warning',
                    message: `${message}`,
                    isOpen: state.snackBarState == 2 ? false : true,
                }
            };

        },
        pubsubAddNewDecision(state, action) {
            const decisions = action.payload.data.decision;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = state.meeting.agenda.section.map((et: any) => {
                if (sectionId === et.section_id) {
                    const newTopics = et.topics.map((tp: any) => {
                        if (tp.topic_id === topicId) {
                            const newDecision = tp.decision ? [...tp.decision, decisions] : [decisions];
                            return {
                                ...tp,
                                decision: newDecision
                            };
                        } else {
                            return tp;
                        }
                    });
                    return {
                        ...et,
                        topics: newTopics
                    };
                } else {
                    return et;
                }
            });

            const participants = state.meeting.participants;
            console.log(JSON.stringify(participants))
            let user = participants.find((part: any) => part.user_id == decisions.created_by_user_id);
            console.log(user)
            let message = `New Decision Added by ${user?.first_name}`;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                },
                snackBar: {
                    type: 'warning',
                    message: `${message}`,
                    isOpen: state.snackBarState == 1 ? false : true,
                }
            };

        },
        pubsubUpdateDecisionType(state, action) {
            const decisionData = action.payload.data.decision;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedDecision = tp.decision?.map((ac: any) => {
                                    if (ac.id == decisionData.messageId)
                                        return { ...ac, status: decisionData.newValue };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    decision: updatedDecision
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubUpdateFinalDecision(state, action) {
            const decisionData = action.payload.data.decision;
            const sectionId = action.payload.data.sectionId;
            const topicId = action.payload.data.topicId;
            const newSectionData = [...(state.meeting.agenda.section).map((et: any) => {
                if (sectionId == et.section_id)
                    return {
                        ...et, topics: [...et.topics.map((tp: any) => {
                            if (tp.topic_id == topicId) {
                                let updatedDecision = tp.decision?.map((ac: any) => {
                                    if (ac.id == decisionData.messageId)
                                        return { ...ac, final_decision: decisionData.newValue, status: "COMPLETED" };
                                    else
                                        return { ...ac };
                                })
                                return {
                                    ...tp,
                                    decision: updatedDecision
                                }
                            }
                            else
                                return tp;
                        })]
                    };
                else
                    return et;
            })];
            console.log(newSectionData, '<<< new section data');
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: newSectionData
                    }
                }
            }
        },
        pubsubDeleteDecision(state, action) {
            const decision = action.payload.data.decision;
            const topicId = action.payload.data.topicId;
            const sectionId = action.payload.data.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics?.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            let updatedDecision = tp.decision.filter((fb: any) => fb.title !== decision.title);
                                            return {
                                                ...tp, decision: [...updatedDecision]
                                            }
                                        } else {
                                            return { ...tp }
                                        }
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubDeleteAction(state, action) {
            const actionData = action.payload.data.action;
            const topicId = action.payload.data.topicId;
            const sectionId = action.payload.data.sectionId;
            return {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics?.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            let updatedAction = tp.action.filter((fb: any) => fb.title !== actionData.title);
                                            return {
                                                ...tp, action: [...updatedAction]
                                            }
                                        } else {
                                            return { ...tp }
                                        }
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        pubsubUpdateEndMeetingButton(state, action) {
            return {
                ...state,
                showEndMeetingButton: false,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        status: 'Completed'
                    }
                }
            }
        }

    },
    extraReducers: {
        // CompleteSection
        [CompleteSectionAndAllTopics.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [CompleteSectionAndAllTopics.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [CompleteSectionAndAllTopics.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Create Action
        [createAction.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [createAction.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [createAction.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Create Action
        [createDecision.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [createDecision.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [createDecision.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Sort Section
        [updateSectionOrder.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateSectionOrder.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateSectionOrder.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Sort Action
        [updateActionsOrder.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateActionsOrder.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateActionsOrder.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Sort Decision
        [updateDecisionsOrder.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecisionsOrder.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateDecisionsOrder.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Create feedback
        [createFeedback.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [createFeedback.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [createFeedback.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Add Topic Section
        [addTopic.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [addTopic.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [addTopic.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Remove Topic Topic Section
        [removeTopic.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [removeTopic.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [removeTopic.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //  update Topic Section
        [updateTopic.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateTopic.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateTopic.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Add Agenda Action Decision Owner
        [updateActionOwnerDetails.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateActionOwnerDetails.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateActionOwnerDetails.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Add Agenda Decision Decision Owner
        [updateDecisionOwnerDetails.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecisionOwnerDetails.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateDecisionOwnerDetails.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Add Agenda Decision Decision Owner
        [updateDecisionFinalMessage.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecisionFinalMessage.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateDecisionFinalMessage.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // Add Agenda Section
        [updateSpeakerDetails.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateSpeakerDetails.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateSpeakerDetails.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        [deleteFeedback.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [deleteFeedback.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [deleteFeedback.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        [deleteAction.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [deleteAction.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [deleteAction.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        [deleteDecision.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [deleteDecision.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [deleteDecision.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Add Agenda Section
        [deleteAgendaSection.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [deleteAgendaSection.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [deleteAgendaSection.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // endMeetingAndSendEmail
        [endMeetingAndSendEmail.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [endMeetingAndSendEmail.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [endMeetingAndSendEmail.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // Add Agenda Section
        [addAgendaSection.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [addAgendaSection.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [addAgendaSection.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // update section name
        [updateSection.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateSection.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateSection.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // create agenda
        [createAgenda.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [createAgenda.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [createAgenda.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            if (action.payload?.response?.status === 400) {
                // Handle the specific error status code (e.g., 400 Bad Request)
                state.snackBar = {
                    type: 'warning',
                    message: action.payload?.response?.data?.error,
                    isOpen: true,
                };
            } else {
                // Handle other errors
                state.snackBar = {
                    type: 'error',
                    message: 'An unexpected error occurred. Please enter text in the form of bullets and sub-bullets to create an agenda.',
                    isOpen: true,
                };
            }
        },

        // raise hand
        [updateRaiseHand.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateRaiseHand.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [updateRaiseHand.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // vote up
        [voteUp.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [voteUp.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            // state.meeting.agenda = { ...state.agenda, ...(action.payload.data ?? state.agenda) };
        },
        [voteUp.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // fetch meeting
        [fetchMeeting.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [fetchMeeting.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            state.meeting = { ...state.meeting, ...(action.payload ?? state.meeting) };
        },
        [fetchMeeting.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // fetch agenda
        [fetchAgenda.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [fetchAgenda.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            state.meeting.agenda = { ...state.meeting.agenda, ...(action.payload ?? state.meeting.agenda) };
        },
        [fetchAgenda.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // fetch feedback
        [fetchFeedback.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [fetchFeedback.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            const topicId = action.payload?.topic_id;
            const sectionId = action.payload?.section_id;
            state = {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            tp.feedback = action?.payload.feedback;
                                            console.log('Initial State:', JSON.stringify(tp));
                                            return { ...tp };
                                        }
                                        else
                                            return { ...tp };
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        [fetchFeedback.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // fetch feedback
        [fetchDecision.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [fetchDecision.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            const topicId = action.payload?.topic_id;
            const sectionId = action.payload?.section_id;
            state = {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            tp.decision = action?.payload.decision;
                                            // console.log('Initial State:', JSON.stringify(tp));
                                            return { ...tp };
                                        }
                                        else
                                            return { ...tp };
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        [fetchDecision.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // fetch Action
        [fetchAction.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [fetchAction.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            const topicId = action.payload?.topic_id;
            const sectionId = action.payload?.section_id;
            state = {
                ...state,
                meeting: {
                    ...state.meeting,
                    agenda: {
                        ...state.meeting.agenda,
                        section: [...state?.meeting?.agenda?.section.map((s: any) => {
                            if (s.section_id == sectionId)
                                return {
                                    ...s, topics: [...s.topics.map((tp: any) => {
                                        if (tp.topic_id == topicId) {
                                            tp.action = action?.payload.action;
                                            console.log('Initial State:', JSON.stringify(tp));
                                            return { ...tp };
                                        }
                                        else
                                            return { ...tp };
                                    })]
                                }
                            else
                                return { ...s }
                        })]
                    }
                }
            }
        },
        [fetchAction.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },


        // update agenda
        [updateAgenda.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateAgenda.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
            state.meeting.agenda = { ...state.agenda, ...(action.payload ?? state.agenda) };
        },
        [updateAgenda.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // startMeeting
        [startMeeting.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [startMeeting.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [startMeeting.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // updateSectionTime
        [updateSectionTime.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateSectionTime.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateSectionTime.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        // updateTopicSpecificData
        [updateTopicSpecificData.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateTopicSpecificData.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateTopicSpecificData.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //updateFeedback
        [updateFeedback.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateFeedback.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateFeedback.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update Action
        [updateAction.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateAction.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateAction.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update Action
        [updateDecision.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecision.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateDecision.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update Action Type
        [updateActionPrivacyType.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateActionPrivacyType.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateActionPrivacyType.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update Action Type
        [updateDecisionStatus.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecisionStatus.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateDecisionStatus.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update decision reaction
        [updateDecisionReactions.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateDecisionReactions.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateDecisionReactions.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

        //update feedback reaction
        [updateFeedbackReactions.pending as any]: (state: any, action: any) => {
            state.loading = true;
        },
        [updateFeedbackReactions.fulfilled as any]: (state: any, action: any) => {
            state.loading = false;
        },
        [updateFeedbackReactions.rejected as any]: (state: any, action: any) => {
            state.loading = false;
            state.snackBar = {
                type: 'warning',
                message: action?.payload?.response?.data?.error,
                isOpen: true,
            }
        },

    }
});

export default MeetingSlice.reducer;
export const { isAuthorised, snackBarReducer, initialize, pubsubUpdateEndMeetingButton, pubsubUpdateDecisionType, pubsubUpdateFinalDecision, pubsubDeleteDecision, pubsubDeleteAction, pubsubCompleteSection, pubsubAddNewFeedback, pubsubDeleteFeedback, pubsubUpdateTopicDetails, pubsubRemoveTopic, pubsubUpdateSectionOrder, pubsubUpdateActionOrder, pubsubUpdateDecisionOrder, updateFilter, updateActionTypeFilter, updateSnackBarState, updateDecisionTypeFilter, pubsubUpdateSectionTime, pubsubDeleteSection, pubsubUpdateSpeakerDetails, pubsubUpdateTopic, pubsubAddNewTopic, pubsubUpdateAgenda, pubsubAddParticipant, pubsubnewHandRaised, pubsubVoteUp, pubsubUpdateSection, pubsubAddNewSection, pubsubUpdateFeedbackTitle, pubsubUpdateFeedbackReations, pubsubUpdateDecisionReations, pubsubAddNewAction, pubsubAddNewDecision, pubsubUpdateActionTitle, pubsubUpdateDecisionTitle, pubsubUpdateActionPrivacy, pubsubUpdateActionDecisionOwner, pubsubUpdateDecisionDecisionOwner } = MeetingSlice.actions;
