import React, { useEffect, useMemo, useRef } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import './HomeTab.css'
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import OptionsMenu from '../../StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from './LikeOptionMenu';
import { mdiMenuDown } from '@mdi/js';
import UserDropdown from '../../StartPage/StartPagePrivew/UserDropdown';
import SectionSummary from '../../StartPage/StartPagePrivew/SectionSummary';
import { useDispatch, useSelector } from 'react-redux';
import TopicBar from '../../StartPage/StartPagePrivew/TopicBar';
import NewTopicForm from '../../StartPage/StartPagePrivew/NewTopicForm';
import { updateAgenda, updateSectionOrder } from '../../../store/slices/MeetingSlice';
import Sortable from 'sortablejs';
// import { Sortable } from 'sortablejs/modular/sortable.core.esm';


const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled(
    ({ iconClickHandler, ...props }: AccordionSummaryProps & { iconClickHandler?: any }) => (
        <MuiAccordionSummary
            expandIcon={
                <span onClick={iconClickHandler}>
                    <Icon className="accordion-icon" path={mdiPlayBox} size={1} />
                </span>
            }
            {...props}
        />
    )
)(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === "dark"
            ? "rgba(255, 255, 255, .05)"
            : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const stringAvatar = (name: string) => {
    return {
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function HomeTab() {
    const dispatch = useDispatch();
    // STORE
    const { agenda, id } = useSelector((s: any) => s.meeting.meeting);
    const { filterBy } = useSelector((s: any) => s.meeting);

    // USE REF
    const dragItem: any = useRef();
    const dragOverItem: any = useRef();

    // STATE
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const filteredSections = useMemo(() => {
        // Check if filterBy is defined
        if (filterBy) {
            // Filter sections by status
            const filteredSectionsData = agenda?.section?.filter((section: any) => section.status === filterBy) ?? [];

            // Sort sections by display_order if available
            const hasDisplayOrder = filteredSectionsData.some((section: any) => section.display_order !== undefined);

            if (hasDisplayOrder) {
                filteredSectionsData.sort((a: any, b: any) => a.display_order - b.display_order);
            }

            return filteredSectionsData;
        } else {
            // Return all sections and sort by display_order if available
            const sections = [...agenda?.section ?? []];

            const hasDisplayOrder = sections.some((section: any) => section.display_order !== undefined);

            if (hasDisplayOrder) {
                sections.sort((a: any, b: any) => a.display_order - b.display_order);
            }

            return sections;
        }
    }, [filterBy, agenda?.section]);

    // DRAG N DROP FUNCTIONS START 
    const dragStart: any = (e: any, position: any) => {
        e.currentTarget.classList.add('drag-move');
        dragItem.current = position;
    };

    const dragEnter: any = (e: any, position: any) => {
        dragOverItem.current = position;
    };

    const drop = async (e: any) => {
        e.currentTarget.classList.remove('drag-move');
        const copyListItems: any[] = [...(agenda?.section ?? [])];
        const dragItemContent = copyListItems[dragItem.current];
        copyListItems.splice(dragItem.current, 1);
        copyListItems.splice(dragOverItem.current, 0, dragItemContent);
        dragItem.current = null;
        dragOverItem.current = null;
        const sortedSectionIds = [...copyListItems.map((sec: any) => sec.section_id)];
        await dispatch(updateSectionOrder({ sectionsArray: sortedSectionIds, meetingId: id, agendaId: agenda.id }))
        // await dispatch(updateAgenda({ ...agenda, section: [...copyListItems] }));
    };
    // DRAG N DROP FUNCTIONS END 

    // FUNCTIONS 
    const handleChange = (panel: string) => {
        if (expanded.includes(panel)) {
            // If the panel is already open, close it
            setExpanded(expanded.filter((p) => p !== panel));
        } else {
            // If the panel is closed, open it
            setExpanded([...expanded, panel]);
        }
    };

    const handleCollapseAll = () => {
        // Close all accordion panels by setting the expanded state to an empty array
        setExpanded([]);
    };

    useEffect(() => {
        // Initialize the `expanded` state with all panel identifiers
        const allPanelIds = filteredSections?.map((et:any, ind:any) => "panel" + ind) || [];
        setExpanded(allPanelIds);
    }, [filteredSections]);

    const listRef = useRef(null);
    const agendaSectionRef = useRef(agenda); // Use a ref to store agenda.section
    const meetingIdRef = useRef(id);

    useEffect(() => {
        console.log('agenda.section:', agenda?.section);
        console.log('listRef.current:', listRef.current);

        if (listRef.current && agendaSectionRef.current) {
            new Sortable(listRef.current, {
                animation: 350,
                ghostClass: 'blue-background-class',
                // filter: '.no-sort',
                // handle: '.handle',
                // sort: 
                // Add your sortable configuration options here
                // For example, to handle sorting events:
                onEnd: async (evt: any) => {
                    console.log('Item moved from index', evt.oldIndex, 'to index', evt.newIndex);
                
                    const oldIndex = evt.oldIndex;
                    const newIndex = evt.newIndex;
                    // Access agenda.section through the ref
                    const currentAgendaSection = agendaSectionRef.current?.section;
                    const copyListItems = [...currentAgendaSection];
                    const dragItemContent = copyListItems[oldIndex];
                    copyListItems.splice(oldIndex, 1);
                    copyListItems.splice(newIndex, 0, dragItemContent);
                
                    // Sort the sectionIds after the drag-and-drop operation
                    const sortedSectionIds = copyListItems.map((sec) => sec.section_id);
                    
                    // Now you have the correctly sorted sectionIds
                    console.log(sortedSectionIds);
                    // Dispatch the update functions after sorting
                    await dispatch(updateSectionOrder({ sectionsArray: sortedSectionIds, meetingId: meetingIdRef.current, agendaId: agendaSectionRef.current?.id }));
                    await dispatch(updateAgenda({ ...agendaSectionRef.current, section: [...copyListItems] }));
                },
            });
        }
    }, [agendaSectionRef.current, meetingIdRef.current]);

    useEffect(() => {
        agendaSectionRef.current = agenda;
    }, [agenda?.section]);

    useEffect(() => {
        meetingIdRef.current = id;
    }, [id]);


    return (
        <div>
            {/* <div className="col text-right"> */}
            <Button variant="outlined" className="btn-outline-white collapse-all-btn" onClick={handleCollapseAll}>Collapse All</Button>
            {/* </div> */}

            {
                        agenda?.section?.length == 0 && 
                        <div className="no_dataDecisions">
                            <div><img src="../../../../images/addAgenda.svg" alt="" /></div>

                            <p className="f-16"><center>Add your first agenda section.</center></p>

                        </div>
                    }

            <div className="cust-accordion-main" ref={listRef}>
                {filteredSections?.slice() // Create a copy of the array to avoid mutating the original
                    .sort((a: any, b: any) => {
                        if (a?.status === 'Completed' && b?.status !== 'Completed') {
                            return 1;
                        }
                        if (a?.status !== 'Completed' && b?.status === 'Completed') {
                            return -1;
                        }
                        return 0;
                    })?.map((et: any, ind: number) => {
                        // const isDraggable = et.status !== 'Completed';
                        return (
                            // disabled={et.status === 'Completed'}
                            <Accordion
                                key={"key" + ind}
                                // className="accodion-bg"
                                className={et.status == 'Completed' ? 'accodion-bg no-sort' : 'accodion-bg'}
                                expanded={expanded.includes("panel" + ind)}
                                // onDragStart={(e) => dragStart(e, ind)}
                                // onDragEnter={(e) => dragEnter(e, ind)}
                                // onDragEnd={drop}
                                // onDragOver={(e) => e.preventDefault()}
                                onChange={() => handleChange("panel" + ind)}
                                style={{
                                    opacity: et.status === 'Completed' ? 0.6 : 1, // Set opacity based on status
                                }}
                            // draggable={isDraggable}
                            >
                                <AccordionSummary
                                    // onClick={() => handleChange("panel" + ind)}
                                    // iconClickHandler={() => handleChange("panel" + ind)}
                                    className="accordion-header"
                                    aria-controls={"panel" + ind + "-content"}
                                    id={"panel" + ind + "-header"}
                                >
                                    <SectionSummary isShowCheckBox={true} isTimeEditable={false} mdiClockTimeFourOutline={mdiClockTimeFourOutline} section={et} />
                                </AccordionSummary>
                                <AccordionDetails>

                                    <div className="row ">
                                        {
                                            et && et?.topics
                                                ?.slice() // Create a copy of the array to avoid mutating the original
                                                .sort((a: any, b: any) => {
                                                    if (a?.status === 'Completed' && b?.status !== 'Completed') {
                                                        return 1;
                                                    }
                                                    if (a?.status !== 'Completed' && b?.status === 'Completed') {
                                                        return -1;
                                                    }
                                                    const aTotalVotes = a?.vote ? a?.vote.reduce((acc: any, v: any) => acc + (v?.count ?? 0), 0) : 0;
                                                    const bTotalVotes = b?.vote ? b?.vote.reduce((acc: any, v: any) => acc + (v?.count ?? 0), 0) : 0;
                                                    return bTotalVotes - aTotalVotes;
                                                })
                                                .map((topic: any, topicIndex: number) => (
                                                    <TopicBar
                                                        key={'topic_bar_' + topicIndex}
                                                        topicIndex={topicIndex}
                                                        section={et}
                                                        topic={topic}
                                                        showLiveTimeBadge={topic?.status === 'Started'}
                                                        showVoteCount={true}
                                                        showRaiseHand={true}
                                                    />
                                                ))
                                        }
                                        <div className="col-12">
                                            <NewTopicForm section={et} agenda={agenda} />
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                    <div className="col-12">
                                        <div className="row accrdion-body-main main-bg-green">
                                            <div className="col-auto like-left-bg-main">
                                                <div className="p-relative">
                                                    <Icon className="arrow-up-count" path={mdiMenuUp} size={1} />
                                                    <LikeOptionMenu />
                                                    <Icon className="arrow-down-count" path={mdiMenuDown} size={1} />

                                                </div>
                                            </div>
                                            <div className="col mt-1">
                                                <div className="row align-items-center">
                                                    <div className="col-auto d-inline-flex pr-0">
                                                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
                                                    </div>
                                                    <div className="col-auto d-inline-flex pl-0">
                                                        <Typography className="time-status"><Icon path={mdiCircleSmall} size={1} className="c-dark" /><span>2:59 PM</span> <Icon path={mdiCircleSmall} size={1} className="c-dark" /></Typography>
                                                    </div>
                                                    <div className="col text-right">
                                                        <OptionsMenu />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <p className="my-2">Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur?</p>
                                                    </div>
                                                    <div className="col-auto avtar-group-sec-main py-2 px-3 mr-2">
                                                        <AvatarGroup max={4}>
                                                            <Avatar alt="Remy Sharp" {...stringAvatar('Remy Sharp')} src="https://mui.com/static/images/avatar/3.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Travis Howard" {...stringAvatar('Travis Howard')} src="/static/images/avatar/2.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Cindy Baker" {...stringAvatar('Cindy Baker')} src="https://mui.com/static/images/avatar/1.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Agnes Walker" {...stringAvatar('Agnes Walker')} src="/static/images/avatar/4.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Trevor Henderson" {...stringAvatar('Trevor Henderson')} src="/static/images/avatar/5.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                        </AvatarGroup>
                                                        <a href="#" className="hand-icon ml-2"><Icon path={mdiHandBackRightOutline} size={1} /></a>
                                                    </div>
                                                    <div className="col-auto join-play-its-over-sec px-2 py-2">
                                                        <Button variant="contained" size="small" className="btn-yellow">Join</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row accrdion-body-main main-bg-blue">
                                            <div className="col-auto like-left-bg-main">
                                                <div className="p-relative">
                                                    <Icon className="arrow-up-count" path={mdiMenuUp} size={1} />
                                                    <LikeOptionMenu />
                                                    <Icon className="arrow-down-count" path={mdiMenuDown} size={1} />
                                                </div>
                                            </div>
                                            <div className="col mt-1">
                                                <div className="row align-items-center">
                                                    <div className="col-auto d-inline-flex pr-0">
                                                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
                                                    </div>
                                                    <div className="col-auto d-inline-flex pl-0">
                                                        <Typography className="time-status"><Icon path={mdiCircleSmall} size={1} className="c-dark" /><span>2:59 PM</span> <Icon path={mdiCircleSmall} size={1} className="c-dark" /></Typography>
                                                    </div>
                                                    <div className="col-auto d-none">
                                                        <div className="badge-inner badge-red"><span className="icon-dot"></span>Live 01:58</div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <OptionsMenu />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <p className="my-2">Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur?</p>
                                                    </div>
                                                    <div className="col-auto avtar-group-sec-main py-2 px-3 mr-2">
                                                        <AvatarGroup max={4}>
                                                            <Avatar alt="Remy Sharp" {...stringAvatar('Remy Sharp')} src="https://mui.com/static/images/avatar/1.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Travis Howard" {...stringAvatar('Travis Howard')} src="/static/images/avatar/2.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Cindy Baker" {...stringAvatar('Cindy Baker')} src="https://mui.com/static/images/avatar/3.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Agnes Walker" {...stringAvatar('Agnes Walker')} src="/static/images/avatar/4.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                            <Avatar alt="Trevor Henderson" {...stringAvatar('Trevor Henderson')} src="/static/images/avatar/5.jpg" sx={{ width: 30, height: 30, fontSize: 14 }} />
                                                        </AvatarGroup>
                                                        <a href="#" className="hand-icon ml-2"><Icon path={mdiHandBackRightOutline} size={1} /></a>
                                                    </div>
                                                    <div className="col-auto join-play-its-over-sec px-2 py-2">
                                                        <IconButton className="play-btn disable" aria-label="Play">
                                                            <Icon path={mdiPlayOutline} size={1.5} />
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row accrdion-body-main main-bg-blue-disable">
                                            <div className="col-auto like-left-bg-main">
                                                <div className="p-relative">
                                                    <LikeOptionMenu />
                                                </div>
                                            </div>
                                            <div className="col mt-1">
                                                <div className="row align-items-center">
                                                    <div className="col-auto d-inline-flex pr-0">
                                                        <Avatar sx={{ width: 24, height: 24 }} alt="Remy Sharp" src="https://mui.com/static/images/avatar/1.jpg" />
                                                    </div>
                                                    <div className="col-auto d-inline-flex pl-0">
                                                        <Typography className="time-status"><Icon path={mdiCircleSmall} size={1} className="c-dark" /><span>2:59 PM</span> <Icon path={mdiCircleSmall} size={1} className="c-dark" /></Typography>
                                                    </div>
                                                    <div className="col-auto d-none">
                                                        <div className="badge-inner badge-red"><span className="icon-dot"></span>Live 01:58</div>
                                                    </div>
                                                    <div className="col text-right">
                                                        <OptionsMenu />
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col">
                                                        <p className="my-2">Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?Lorem ipsum dolor sit amet consectetur?</p>
                                                    </div>
                                                    <div className="col-auto join-play-its-over-sec px-2 py-2">
                                                        <div className="badge-inner badge-blue-dark">It's Over</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row accrdion-body-main main-bg-blue">
                                            <div className="col-auto like-left-bg-main">
                                                <div className="p-relative">
                                                    <Icon className="arrow-up-count" path={mdiMenuUp} size={1} />
                                                    <LikeOptionMenu />
                                                    <Icon className="arrow-down-count" path={mdiMenuDown} size={1} />
                                                </div>
                                            </div>
                                            <div className="col mt-1">
                                                <div className="row align-items-center">
                                                    <div className="col-auto d-inline-flex">
                                                        <UserDropdown />
                                                    </div>
                                                    <div className="col-auto d-none">
                                                        <div className="badge-inner badge-red"><span className="icon-dot"></span>Live 01:58</div>
                                                    </div>
                                                </div>
                                                <div className="row align-items-center">
                                                    <div className="col-12 mt-2">
                                                        <div>
                                                            <TextField
                                                                id="outlined-size-small"
                                                                defaultValue="Lorem ipsum dolor sit amet consectetur? Lorem ipsum dolor sit amet consectetur?"
                                                                size="small"
                                                                fullWidth
                                                                className="input-bg-white"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-right my-2">
                                                        <Button variant="outlined" size="small" className="mr-2 btn-outline-white">Cancel</Button>
                                                        <Button variant="contained" size="small">Save</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="row align-items-center accrdion-bottom-form">
                                            <div className="col">
                                                <div>
                                                    <TextField
                                                        label="Add Discussion Topic"
                                                        id="outlined-size-small"
                                                        size="small"
                                                        fullWidth
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <Button className="add-btn" variant="outlined" sx={{ mt: 2, boxShadow: 2 }} ><Icon path={mdiPlus} size={1} /></Button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}


            </div>
        </div>
    );
}
