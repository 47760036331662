import React, { useEffect, useMemo, useRef } from 'react'
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Icon from '@mdi/react';
import { mdiCircleSmall, mdiPlayBox } from '@mdi/js';
import { mdiDotsHorizontal } from '@mdi/js';
import { mdiClockTimeFourOutline } from '@mdi/js';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { mdiPlus } from '@mdi/js';
import { mdiMenuUp } from '@mdi/js';
import { mdiHandBackRightOutline } from '@mdi/js';
import { mdiPlayCircle } from '@mdi/js';
import { mdiPlayOutline } from '@mdi/js';
import IconButton from '@mui/material/IconButton';
import { mdiCheckCircleOutline } from '@mdi/js';
import { mdiMenuDown } from '@mdi/js';

import OptionsMenu from '../.././pages/StartPage/StartPagePrivew/OptionsMenu';
import LikeOptionMenu from '../.././pages/Home/Tabs/LikeOptionMenu';
import UserDropdown from '../.././pages/StartPage/StartPagePrivew/UserDropdown';
import Chip from '@mui/material/Chip';
import Filters from '../.././pages/Home/Tabs/Filters';
import PrivateEveryoneBlueSelect from '../.././pages/Home/Tabs/PrivateEveryoneBlueSelect';
import Tooltip from '@mui/material/Tooltip';
import './ActionsTabPrivew.css';
import { mdiDotsVertical } from '@mdi/js';
import DecisionsDropdown from './DecisionsDropdown';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAction, updateActionsOrder, updateActionTypeFilter, updateFilter } from '../../store/slices/MeetingSlice';
import ActionBar from './ActionBar';
import Sortable from 'sortablejs';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<Icon className="accordion-icon" path={mdiPlayBox} size={1} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1.3),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}


export default function ActionsTabPrivew() {
    const [expanded, setExpanded] = React.useState<string | true>('panel1');
    const location = useLocation();
    const dispatch = useDispatch();

    const topicId = location?.state?.topicId;
    const sectionId = location?.state?.sectionId;
    const { filterByActionType } = useSelector((s: any) => s.meeting);

    // STORE
    const { agenda } = useSelector((s: any) => s.meeting.meeting);
    const { meeting } = useSelector((s: any) => (s.meeting));
    const user = useSelector((s: any) => s.meeting.user);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : true);
        };
    function stringAvatar(name: string) {
        return {
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                await dispatch(fetchAction({ agendaId: agenda?.id, topicId: topicId, sectionId: sectionId }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (agenda?.id && topicId) {
            fetchData();
        }
        // Call the async function
    }, [agenda?.id, topicId]); // Empty dependency array means this effect runs only once

    const { topicData, sectionData } = React.useMemo(() => {
        const sec = agenda?.section?.find((sc: any) => sc.section_id == sectionId);
        const result = sec?.topics?.find((tp: any) => tp.topic_id == topicId);
        return { topicData: result, sectionData: sec };
    }, [agenda?.section])

    //USE MEMO
    const filteredSections: any = useMemo(() => {
        const sectionFound: any = agenda?.section?.find((sec: any) => sec.section_id == sectionId);
        const topicFound: any = sectionFound?.topics?.find((tp: any) => tp?.topic_id == topicId);

        // Check if there are actions for the topic
        if (topicFound && topicFound.action) {
            if (filterByActionType) {
                // Filter actions by type
                const filteredActionData = topicFound.action.filter((action: any) => action.type == filterByActionType);
                return filteredActionData ?? [];
            } else {
                // Return all actions and sort by display_order if available
                const actions = [...topicFound.action];

                // Check if at least one action has a display_order
                const hasDisplayOrder = actions.some((action: any) => action.display_order !== undefined);

                if (hasDisplayOrder) {
                    // Sort actions by display_order
                    actions.sort((a: any, b: any) => a.display_order - b.display_order);
                }

                return actions;
            }
        }

        return [];
    }, [agenda?.section, topicId, filterByActionType]);


    const handleSectionFilter = async (value: String | Boolean) => {
        dispatch(updateActionTypeFilter(value));
    }

    const listRef = useRef(null);
    const agendaSectionRef = useRef(filteredSections); // Use a ref to store agenda.section
    const meetingIdRef = useRef(meeting?.id);

    useEffect(() => {
        console.log('agenda.section:', agenda?.section?.action);
        console.log('listRef.current:', listRef.current);

        if (listRef.current && agendaSectionRef.current) {
            new Sortable(listRef.current, {
                animation: 350,
                // filter: '.no-sort',
                // handle: '.handle',
                // sort: 
                // Add your sortable configuration options here
                // For example, to handle sorting events:
                onEnd: async (evt: any) => {
                    console.log('Item moved from index', evt.oldIndex, 'to index', evt.newIndex);

                    const oldIndex = evt.oldIndex;
                    const newIndex = evt.newIndex;
                    console.log(agendaSectionRef.current, 'newIndex');
                    console.log(oldIndex, 'oldIndex');

                    // Access agenda.section through the ref
                    const currentAgendaAction = agendaSectionRef.current;
                    const copyListItems = [...currentAgendaAction];
                    const dragItemContent = copyListItems[oldIndex];
                    copyListItems.splice(oldIndex, 1);
                    copyListItems.splice(newIndex, 0, dragItemContent);
                    console.log(copyListItems, 'copyListItems');

                    const sortedActionIds = [...copyListItems.map((ac) => ac.id)];
                    console.log(sortedActionIds, 'sortedActionIds');
                    await dispatch(updateActionsOrder({ actionsArray: sortedActionIds, meetingId: meetingIdRef.current, agendaId: agenda?.id, topicId: topicId, sectionId: sectionId }));
                    // await dispatch(updateAgenda({ ...agendaSectionRef.current, section: [...copyListItems] }));
                },
            });
        }
    }, [agendaSectionRef.current, meetingIdRef.current]);

    useEffect(() => {
        agendaSectionRef.current = filteredSections;
    }, [filteredSections]);

    useEffect(() => {
        meetingIdRef.current = meeting?.id;
    }, [meeting?.id]);

    return (
        <div>
            <div ref={listRef}>
                { topicData?.action?.length > 0 &&
                    <div className="row align-items-center my-3">
                        <div className="col-auto">
                            <Chip className="mr-2" onClick={() => { handleSectionFilter(false) }} label={<span className={"filter-label"}><small className="filter-count">{topicData?.action?.length ?? 0}</small>All</span>} size="small" color={!filterByActionType ? "primary" : undefined} />
                            <Chip className="mr-2" onClick={() => { handleSectionFilter('ONLY_ME') }} label={<span className={"filter-label"}><small className="filter-count">{topicData?.action?.filter((et: any) => et?.type == 'ONLY_ME').length ?? 0}</small>Private</span>} size="small" color={filterByActionType === 'ONLY_ME' ? "primary" : undefined} />
                            <Chip onClick={() => { handleSectionFilter('EVERYONE') }} label={<span className={"filter-label"}><small className="filter-count">{topicData?.action?.filter((et: any) => et?.type == 'EVERYONE').length ?? 0}</small>Everyone</span>} size="small" color={filterByActionType === 'EVERYONE' ? "primary" : undefined} />
                        </div>
                    </div>
                }
                {
                    filteredSections.length === 0 ? (
                        <div className="no_datactions">
                            <div><img src="../../../../images/addActions.svg" alt="" /></div>

                            <p className="f-16"><center>Add the first action item to build transparency with your team.</center></p>
                        </div>

                    ) : (
                        <>
                            {filteredSections.map((action: any, index: number) => {
                                if ((action?.type == "ONLY_ME" && action?.created_by_user_id === user.id) || action.type === "EVERYONE") {
                                    return (
                                        <ActionBar topic={topicData} section={sectionData} action={action} index={index} />
                                    );
                                }
                                return null; // Return null for actions that don't meet the condition
                            })}
                        </>
                    )}
            </div>
        </div>
    )
}
