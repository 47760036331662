import React, { useEffect } from 'react';
import './App.css';
import Layout from './layout/Layout';
import useZoom from './hooks/useZoom';
import { useDispatch, useSelector } from 'react-redux';
import { pubsubAddNewFeedback, pubsubDeleteFeedback, pubsubDeleteSection, pubsubUpdateSpeakerDetails, pubsubAddNewSection, pubsubAddNewTopic, pubsubAddParticipant, pubsubnewHandRaised, pubsubRemoveTopic, pubsubUpdateAgenda, pubsubUpdateSection, pubsubUpdateTopic, pubsubVoteUp, pubsubUpdateSectionTime, pubsubUpdateSectionOrder, pubsubUpdateTopicDetails, pubsubUpdateFeedbackTitle, pubsubUpdateFeedbackReations, pubsubCompleteSection, pubsubAddNewAction, pubsubUpdateActionTitle, pubsubUpdateActionPrivacy, pubsubUpdateActionDecisionOwner, pubsubUpdateDecisionTitle, pubsubAddNewDecision, pubsubUpdateDecisionType, pubsubDeleteDecision, pubsubUpdateDecisionDecisionOwner, pubsubUpdateDecisionReations, pubsubUpdateFinalDecision, pubsubUpdateActionOrder, pubsubUpdateDecisionOrder, pubsubUpdateEndMeetingButton, pubsubDeleteAction } from './store/slices/MeetingSlice';
import { useLocation, useNavigate } from 'react-router-dom';

// Imports the client libray
const { WebPubSubClient } = require("@azure/web-pubsub-client");
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { meeting, user } = useSelector((s: any) => (s.meeting));
  const { agenda } = useSelector((s: any) => s.meeting.meeting);

  useZoom();
  useEffect(() => {
    if (user.id && meeting.id && dispatch) {    //Instantiates the client object
      const client = new WebPubSubClient({
        getClientAccessUrl: async () => {
          let value = await (await fetch(`${process.env.REACT_APP_API_DOMAIN}negotiate?userId=${user.id}&&groupName=${meeting.id}`)).json();
          return value.url;
        }
      });
      // const client = new WebPubSubClient("wss://meetiq-webpubsub.webpubsub.azure.com/client/hubs/Hub?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ3c3M6Ly9tZWV0aXEtd2VicHVic3ViLndlYnB1YnN1Yi5henVyZS5jb20vY2xpZW50L2h1YnMvSHViIiwiaWF0IjoxNjkwODAwMTE1LCJleHAiOjE2OTA4MDM3MTUsInJvbGUiOlsid2VicHVic3ViLnNlbmRUb0dyb3VwIiwid2VicHVic3ViLmpvaW5MZWF2ZUdyb3VwIl19.-wFhbpbzywfGCjFhzcY3MWs9HQRJuEt3pmYcWcGnDXY");
      // wss://meetiq-webpubsub.webpubsub.azure.com/client/hubs/Hub?access_token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJ3c3M6Ly9tZWV0aXEtd2VicHVic3ViLndlYnB1YnN1Yi5henVyZS5jb20vY2xpZW50L2h1YnMvSHViIiwiaWF0IjoxNjkwODAwMTE1LCJleHAiOjE2OTA4MDM3MTUsInJvbGUiOlsid2VicHVic3ViLnNlbmRUb0dyb3VwIiwid2VicHVic3ViLmpvaW5MZWF2ZUdyb3VwIl19.-wFhbpbzywfGCjFhzcY3MWs9HQRJuEt3pmYcWcGnDXY
      // const client = new WebPubSubClient("<client-access-url>");
      (async () => {
        try {
          await client.start();
        } catch (Err) {
          console.log(JSON.stringify(Err), '<< errr')
        }
      })()

      client.on("connected", (e: any) => {
        console.log(`Connection ${e.connectionId} is connected.`);
      });

      client.on("server-message", (e: any) => {
        console.log(`Received message ${e.message.data}`);
      });

      // Registers a listener for the "group-message". The callback will be invoked when the client receives a message from the groups it has joined.
      client.on("group-message", (e: any) => {
        // switch case 
        switch (e.message.data.type) {
          case 'AGENDA_CREATED':
            dispatch(pubsubUpdateAgenda({ agenda: e.message.data.data }))
            break;
          case 'AGENDA_UPDATED':
            dispatch(pubsubUpdateAgenda({ agenda: e.message.data.data }))
            break;
          case 'PARTICIPANT_JOINED':
            dispatch(pubsubAddParticipant({ newParticipant: e.message.data.data }))
            break;
          case 'HAND_RAISED':
            dispatch(pubsubnewHandRaised({ newHandRaised: e.message.data.data }))
            break;
          case 'VOTE_UP':
            dispatch(pubsubVoteUp({ voteUp: e.message.data.data }))
            break;
          case 'UPDATED_SECTION_NAME':
            dispatch(pubsubUpdateSection({ sectionData: e.message.data.data }))
            break;
          case 'ADDED_NEW_SECTION':
            dispatch(pubsubAddNewSection({ sectionData: e.message.data.data }))
            break;
          case 'DELETED_SECTION':
            dispatch(pubsubDeleteSection({ sectionData: e.message.data.data }))
            break;
          case 'UPDATED_SPEAKER':
            dispatch(pubsubUpdateSpeakerDetails({ speakerData: e.message.data.data }))
            break;
          case 'ADD_NEW_TOPIC':
            dispatch(pubsubAddNewTopic({ topicData: e.message.data.data }))
            break;
          case 'DELETE_TOPIC':
            dispatch(pubsubRemoveTopic({ topicData: e.message.data.data }))
            break;
          case 'UPDATED_TOPIC_NAME':
            dispatch(pubsubUpdateTopic({ topicData: e.message.data.data }))
            break;
          case 'UPDATED_SECTION_TIME':
            dispatch(pubsubUpdateSectionTime({ data: e.message.data.data }))
            break;
          case 'SECTION_SORTED':
            dispatch(pubsubUpdateSectionOrder({ data: e.message.data.data }))
            break;
          case 'SORTED_ACTIONS':
            dispatch(pubsubUpdateActionOrder({ data: e.message.data.data }))
            break;
          case 'SORTED_DECISIONS':
            dispatch(pubsubUpdateDecisionOrder({ data: e.message.data.data }))
            break;
          case 'ADDED_NEW_FEEDBACK':
            dispatch(pubsubAddNewFeedback({ feedbackData: e.message.data.data }))
            break;
          case 'DELETE_FEEDBACK':
            dispatch(pubsubDeleteFeedback({ feedbackData: e.message.data.data }))
            break;
          case 'TOPIC_UPDATED':
            dispatch(pubsubUpdateTopicDetails({ data: e.message.data.data, nav: navigate }));
            // if (e.message.data.data.value.key == 'UPDATE_STARTTIME') {
            //   const sec = agenda?.section?.find((sc: any) => sc.section_id == e.message.data.data.sectionId);
            //   const result = sec?.topics?.find((tp: any) => tp.topic_id == e.message.data.data.topicId);
            //   navigate("/feedback", { state: { topic: { ...result }, section: sec, sectionId: e.message.data.data.sectionId, topicId: e.message.data.data.topicId } });
            // }
            // if (e.message.data.data.value.key == 'UPDATE_ENDTIME') {
            //   navigate('/home')
            // }
            break;
          case 'UPDATED_FEEDBACK_TITLE':
            dispatch(pubsubUpdateFeedbackTitle({ data: e.message.data.data }))
            break;
          case 'REACTED_TO_FEEDBACK':
            dispatch(pubsubUpdateFeedbackReations({ data: e.message.data.data }))
            break;
          case 'COMPLETED_SECTION':
            dispatch(pubsubCompleteSection({ data: e.message.data.data }))
            break;
          case 'ADDED_NEW_ACTION':
            dispatch(pubsubAddNewAction({ actionData: e.message.data.data }))
            break;
          case 'UPDATED_ACTION_TITLE':
            dispatch(pubsubUpdateActionTitle({ data: e.message.data.data }))
            break;
          case 'UPDATED_ACTION_TYPE':
            dispatch(pubsubUpdateActionPrivacy({ data: e.message.data.data }))
            break;
          case 'UPDATED_ACTION_DECISION_OWNER':
            dispatch(pubsubUpdateActionDecisionOwner({ data: e.message.data.data }))
            break;
          case 'UPDATED_DECISION_TITLE':
            dispatch(pubsubUpdateDecisionTitle({ data: e.message.data.data }))
            break;
          case 'DELETED_DECISION':
            dispatch(pubsubDeleteDecision({ data: e.message.data.data }))
            break;
          case 'DELETED_ACTION':
            dispatch(pubsubDeleteAction({ data: e.message.data.data }))
            break;
          case 'ADDED_NEW_DECISION':
            dispatch(pubsubAddNewDecision({ data: e.message.data.data }))
            break;
          case 'UPDATED_DECISION_TYPE':
            dispatch(pubsubUpdateDecisionType({ data: e.message.data.data }))
            break;
          case 'UPDATED_DECISION_DECISION_OWNER':
            dispatch(pubsubUpdateDecisionDecisionOwner({ data: e.message.data.data }))
            break;
          case 'REACTED_TO_DECISION':
            dispatch(pubsubUpdateDecisionReations({ data: e.message.data.data }))
            break;
          case 'UPDATED_FINAL_DECISION':
            dispatch(pubsubUpdateFinalDecision({ data: e.message.data.data }))
            break;
          case 'EMAIL_SENT':
            dispatch(pubsubUpdateEndMeetingButton({ data: e.message.data.data }))
            break;
          // case 'NAVIGATE_TO_FEEDBACK':
          //   const sec = agenda?.section?.find((sc: any) => sc.section_id == e.message.data.data.sectionId);
          //   const result = sec?.topics?.find((tp: any) => tp.topic_id == e.message.data.data.topicId);
          //   navigate("/feedback", { state: { topic: { sectionId: e.message.data.data.sectionId, topicId: e.message.data.data.topicId } });
          //   break;
          default:
        }
        console.log(`Received message from ${e.message.group}: ${e.message.data}`);
      });

      client.on("disconnected", (e: any) => {
        console.log(`Connection disconnected: ${e.message}`);
      });
    }
  }, [user?.id, meeting?.id, dispatch])

  return (
    <Layout />
  );
}

export default App;
