import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './UserDropdown.css';
import Icon from '@mdi/react';
import Avatar from '@mui/material/Avatar';
import { mdiMenuDown } from '@mdi/js';
import { useDispatch, useSelector } from 'react-redux';
import { Topic, User, updateAgenda, updateSpeakerDetails, updateActionOwnerDetails, updateDecisionOwnerDetails } from '../../../store/slices/MeetingSlice';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Tooltip } from '@mui/material';

export default function UserDropdown(props: any) {
    const dispatch = useDispatch();

    // PROPS
    const { showName, type, topic, section, action, decision } = props;

    // STORE 
    const { participants } = useSelector((s: any) => s.meeting.meeting)
    const { meeting } = useSelector((s: any) => (s.meeting));

    // USE STATE
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { agenda } = useSelector((s: any) => s.meeting.meeting);

    // USE MEMO 
    const selectedUser = React.useMemo(() => {
        if (type == 'topic') {
            const user = participants.find((pt: User) => pt.user_id == topic.speaker);
            return user;
        } else if (type == "section") {
            const user = participants.find((pt: User) => pt.user_id == section.speaker);
            return user;
        }
        else if (type == "action") {
            const user = participants.find((pt: User) => pt.user_id == action?.assigned_to_user_id);
            return user;
        } else if (type == "decision") {
            const user = participants.find((pt: User) => pt.user_id == decision?.assigned_to_user_id);
            return user;
        }
    }, [topic, section, action, decision, participants]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        if (type == 'section') {
            if (section?.status != 'Completed') {
                setAnchorEl(event.currentTarget);
            }
        } else if (type == 'topic') {
            if (topic?.status != 'Completed') {
                setAnchorEl(event.currentTarget);
            }
        } else if (type == 'decision') {
            if (decision?.status != 'COMPLETED') {
                setAnchorEl(event.currentTarget);
            }
        } else if (type == 'action') {
            if (topic?.status != 'Completed') {
                setAnchorEl(event.currentTarget);
            }
        }
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    // const handleDropdownClick = (e:any) => {
    //     e.stopPropagation(); // Prevent the click event from propagating to the AccordionSummary
    //     // Add your dropdown logic here
    //   };


    const handleUserChange = async (usr: User, event: React.MouseEvent<HTMLElement>) => {
        if (type == 'section') {
            await dispatch(updateSpeakerDetails({ agendaId: agenda.id, sectionId: section.section_id, userId: usr.user_id, meetingId: meeting.id }));
        }
        else if (type == 'topic') {
            await dispatch(updateSpeakerDetails({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, userId: usr.user_id, meetingId: meeting.id }));
        }
        else if (type == 'action') {
            await dispatch(updateActionOwnerDetails({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, userId: usr.user_id, meetingId: meeting.id, dataId: action.id, actionId: action.actionId }));
        }
        else if (type == 'decision') {
            await dispatch(updateDecisionOwnerDetails({ agendaId: agenda.id, sectionId: section.section_id, topicId: topic.topic_id, userId: usr.user_id, meetingId: meeting.id, dataId: decision.id, decisionId: decision.decisionId }));
        }
        handleClose(event);
    }

    return (
        <div className="display-inherit">
            <a id="basic-button" className="user-dropdown-click" onClick={handleClick}>
                {
                    !selectedUser ?
                        <>
                            <Tooltip title="Unassigned">
                                <Avatar sx={{ width: 24, height: 24 }} alt="Unassigned">
                                    <PersonOutlineIcon sx={{ width: 20, height: 20 }} />
                                </Avatar>
                            </Tooltip>
                        </>
                        :
                        <>
                            <Tooltip title={selectedUser.first_name + ' ' + selectedUser.last_name}><Avatar sx={{ width: 24, height: 24, fontSize: 12, bgcolor: selectedUser.backgroundColor }} alt={selectedUser.first_name + ' ' + selectedUser.last_name} {...selectedUser?.avatarText} src={selectedUser?.pic_url} /></Tooltip>{showName && <span className="d-inline-flex ml-2 f-14">{selectedUser.first_name + ' ' + selectedUser.last_name}  <Icon path={mdiMenuDown} size={0.8} /></span>}
                        </>
                }
            </a>
            <Menu
                id="user-dropdown"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {
                    participants && participants.map((usr: any, ind: number) => (
                        <MenuItem onClick={(e) => { handleUserChange(usr, e) }} key={'user_list' + ind} className="user-dropdown-main">
                            <div className="col-auto d-inline-flex align-items-center">
                                <Avatar alt={usr.first_name + ' ' + usr.last_name} {...usr.avatarText} src={usr?.pic_url} sx={{ width: 25, height: 25, fontSize: 12, bgcolor: usr?.backgroundColor }} /> <span className="d-inline-flex ml-2 f-14">{usr.first_name + ' ' + usr.last_name}</span>
                            </div>
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}