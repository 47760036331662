import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Icon from '@mdi/react';
import { mdiThumbUpOutline } from '@mdi/js';
import { mdiThumbDownOutline } from '@mdi/js';
import Avatar from '@mui/material/Avatar';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { mdiFilterOutline } from '@mdi/js';
import './Filter.css';


export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'id' : undefined;

    return (
        <div>
            <div>
                <Button aria-describedby={id} className="btn-outline-white" variant="outlined" onClick={handleClick}><Icon className="mr-1" path={mdiFilterOutline} size={0.8} /> Filter</Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <div className="row filter-option-bg">
                        <div className="col-12">
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Section1" />
                            </FormGroup>
                            <FormGroup className="col-12 pl-4">
                                <FormControlLabel control={<Checkbox />} label="Section1" />
                                <FormControlLabel control={<Checkbox />} label="Section2" />
                                <FormControlLabel control={<Checkbox />} label="Section3" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Section2" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Section3" />
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox />} label="Section4" />
                            </FormGroup>
                        </div>                        
                    </div>
                </Popover>
            </div>
        </div>

    );
}